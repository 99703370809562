// ConfirmOrderDialog.tsx

import React from 'react';
import { Button } from '../components/Button/Button';
import { handleCreateOrder } from '../solidity/CreateOrder';
import { ModalHeader } from '../components/Modal/ModalHeader';

interface ConfirmOrderDialogProps {
    orderDetails: any;
    onConfirm: () => void;
    onClose: () => void;
}

const ConfirmOrderDialog: React.FC<ConfirmOrderDialogProps> = ({
    onClose,
    orderDetails,
    onConfirm,
}) => {
    const initiateCreateOrder = async () => {
        console.log(`[initiateCreateOrder] orderDetails=`, orderDetails);

        try {
            const amount = orderDetails.amount;
            const price = orderDetails.price;
            const side = orderDetails.side == 'Buy' ? true : false;
            const leverage = (orderDetails.leverage * 1e8).toString();
            const closing_order = orderDetails.closing_order;
            const stop = orderDetails.type == 'Stop Limit' ? true : false;
            const stop_price = orderDetails.stop_price;
            const base_token = orderDetails.base_token;
            const is_market = orderDetails.is_market;
            const replace_hash = '';

            await handleCreateOrder({
                amount,
                price,
                side,
                leverage,
                closing_order,
                stop,
                stop_price,
                base_token,
                is_market,
                replace_hash,
            });

            onConfirm();
        } catch (error) {
            console.error('Create order failed:', error);
        }
    };


    return (
        <div>
            <div>
                <div className="-mt-4">
                    <ModalHeader onClose={onClose}>Confirm Order</ModalHeader>
                </div>

                <div className="text-xs">
                    <div className="mt-6 mb-2">Order Details</div>
                    <div className="flex justify-between">
                        <div className="text-gray-450">Type:</div> {orderDetails.type}
                    </div>
                    <div className="flex justify-between">
                        <div className="text-gray-450">Side:</div>{' '}
                        <div
                            className={
                                orderDetails.side.toLowerCase() === 'buy'
                                    ? 'text-green-550'
                                    : 'text-red-550'
                            }
                        >
                            {orderDetails.side}
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <div className="text-gray-450 ">Reduce Only:</div>{' '}
                        <div>{orderDetails.closing_order ? 'YES' : 'NO'}</div>
                    </div>
                    <div className="flex justify-between">
                        <div className="text-gray-450">Amount:</div> {orderDetails.formatted_amount}
                    </div>
                    <div className="flex justify-between">
                        <div className="text-gray-450">Price: </div>
                        {orderDetails.price} USD
                    </div>
                    {orderDetails.type == 'Stop Limit' && (
                        <div className="flex justify-between">
                            <div className="text-gray-450">Stop Price:</div>{' '}
                            {orderDetails.formatted_stop_price} USD
                        </div>
                    )}

                    <div className="flex justify-between">
                        <div className="text-gray-450">Order Value:</div>{' '}
                        {orderDetails.closing_order ? '--' : orderDetails.order_value + ' USD'}
                    </div>
                    <div className="flex justify-between">
                        <div className="text-gray-450">Margin:</div>
                        {orderDetails.closing_order
                            ? '--'
                            : orderDetails.leverage == 0
                            ? 'CROSS'
                            : orderDetails.margin + ' ' + orderDetails.margin_symbol}
                    </div>
                    <div className="flex justify-between">
                        <div className="text-gray-450">Leverage:</div>{' '}
                        <div className="text-yellow-550">
                            {orderDetails.closing_order
                                ? '--'
                                : orderDetails.leverage == 0
                                ? 'CROSS'
                                : orderDetails.leverage + 'x'}
                        </div>
                    </div>

                    <div className="mt-6 mb-2">Contract Details</div>
                    <div className="flex justify-between">
                        <div className="text-gray-450">Liquidation Price:</div>{' '}
                        {orderDetails.closing_order
                            ? '--'
                            : orderDetails.liquidation_price + ' USD'}
                    </div>
                    <div className="flex justify-between">
                        <div className="text-gray-450">Current Funding Rate:</div>{' '}
                        {orderDetails.closing_order ? '--' : orderDetails.funding_rate + '%/1H'}
                    </div>
                </div>

                <div className="mt-8">
                    <Button
                        variant="yellow"
                        className="w-full"
                        color="primary"
                        onClick={initiateCreateOrder}
                    >
                        Confirm Order
                    </Button>
                    {/*<Button
                        variant="outlined"
                        color="secondary"
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>*/}
                </div>
            </div>
        </div>
    );
};

export default ConfirmOrderDialog;
