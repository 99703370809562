// fee.ts

import { eventCloud } from '../EventCloud';
import { Params } from '../interfaces/params.interface';
import { MarginCurrency } from '../interfaces/marginCurrency.interface';
import { Fee } from '../interfaces/fee.interface';

export function calculateTxFee(
    marginCurrency: MarginCurrency,
    params: Params,
    direction: string
): Fee {
    let GAS_DEPOSIT_ETH = 25000;
    let GAS_WITHDRAW_ETH = 60000;
    let GAS_APPROVE = 60000;
    let GAS_DEPOSIT_TOKEN = 85000;
    let GAS_TRANSFER_TOKEN = 75000;

    switch (marginCurrency.network) {
        case 'Avalanche':
            GAS_DEPOSIT_TOKEN = 90000;
            break;

        case 'Arbitrum':
            GAS_DEPOSIT_ETH = 500000;
            GAS_APPROVE = 2000000;
            GAS_DEPOSIT_TOKEN = 2000000;
            GAS_TRANSFER_TOKEN = 2200000;
            GAS_WITHDRAW_ETH = 1000000;
            break;
    }

    let fee = <Fee>{
        direction,
        gas: GAS_DEPOSIT_ETH,
        usd_fee: 0,
        token_fee: 0,
        native_fee: 0,
        native_token: marginCurrency.native_token,
    };

    // console.log("[calculateTxFee] ",marginCurrency, params, direction);

    let gas = GAS_DEPOSIT_ETH;
    let token_address = marginCurrency.token_address;

    if (direction == 'deposit' && token_address != marginCurrency.native_token) {
        gas = GAS_APPROVE + GAS_DEPOSIT_TOKEN;
    }

    if (direction == 'withdraw') {
        if (token_address == '0x0000000000000000000000000000000000000000') {
            gas = GAS_WITHDRAW_ETH;
        } else {
            gas = GAS_TRANSFER_TOKEN;
        }
    }

    fee.gas = gas;

    //console.log(`[calculateTxFee] gas=${gas} direction=${direction} symbol=${marginCurrency.symbol} token_address=${token_address}`)

    // let usd_gas_fee = 0;
    // let token_gas_fee;

    switch (marginCurrency.network) {
        case 'Bitcoin':
            fee.native_fee = params?.btc_transaction_fee / 1e8;
            fee.usd_fee = fee.native_fee * Number(params?.btc_mark_price);
            break;

        case 'Tron':
            fee.native_fee = Number(params?.tron_transaction_fee);
            fee.usd_fee =
                direction == 'deposit' ? Number(fee.native_fee) : Number(fee.native_fee) * 2;
            break;

        case 'Solana':
            fee.native_fee = Number(params?.solana_transaction_fee);
            fee.usd_fee =
                direction == 'deposit' ? Number(fee.native_fee) : Number(fee.native_fee) * 2;
            break;

        case 'Ton':
            fee.native_fee = Number(params?.ton_transaction_fee) / 1e9;
            fee.usd_fee = Number(fee.native_fee) * Number(params?.ton_mark_price);
            break;

        case 'Ethereum':
        case 'Optimism':
        case 'Arbitrum':
        case 'Base':
            fee.native_fee = (gas * getGasPrice(marginCurrency, params)) / 1e9;
            fee.usd_fee = fee.native_fee * Number(params?.eth_mark_price);
            break;

        case 'Polygon':
            fee.native_fee = (gas * getGasPrice(marginCurrency, params)) / 1e9;
            fee.usd_fee = fee.native_fee * Number(params?.matic_mark_price);
            break;

        case 'Avalanche':
            fee.native_fee = (gas * getGasPrice(marginCurrency, params)) / 1e9;
            fee.usd_fee = fee.native_fee * Number(params?.avax_mark_price);
            break;

        case 'BSC':
            fee.native_fee = (gas * getGasPrice(marginCurrency, params)) / 1e9;
            fee.usd_fee = fee.native_fee * Number(params?.bnb_mark_price);
            break;
    }

    if (token_address == marginCurrency.native_token) {
        fee.token_fee = fee.native_fee;
    } else if (marginCurrency.ticker == 'btcusd') {
        fee.token_fee = fee.usd_fee / params?.btc_mark_price;
    } else {
        fee.token_fee = fee.usd_fee;
    }

    return fee;
}

export function getGasPrice(marginCurrency: MarginCurrency, params: Params): number {
    switch (marginCurrency.network) {
        case 'Ethereum':
            return Number(params?.eth_gas_price);
            break;

        case 'Polygon':
            //console.log("Polygon")
            return Number(params?.polygon_gas_price);
            break;

        case 'Avalanche':
            //console.log("Avalanche")
            return Number(params?.avac_gas_price);
            break;

        case 'Optimism':
            //console.log("Optimism")
            return Number(params?.optimism_gas_price);
            break;

        case 'Arbitrum':
            //console.log("Arbitrum")
            return Number(params?.arbitrum_gas_price);
            break;

        case 'Base':
            //console.log("Arbitrum")
            return Number(params?.base_gas_price);
            break;

        case 'BSC':
            //console.log("Arbitrum")
            return Number(params?.bsc_gas_price);
            break;
    }

    return 0;
}
