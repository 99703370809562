import { Position } from '../interfaces/position.interface';
import { MarginCurrency } from '../interfaces/marginCurrency.interface';
import BigNumber from 'bignumber.js';

export const calcUPnL = (position: Position, marginCurrencies: Record<string, MarginCurrency>): number => {
    var upnl = 0;

    if (position.side) {
        upnl = (Number(position.last_price) - Number(position.entry_price)) * Number(position.size);
    } else {
        upnl = (Number(position.entry_price) - Number(position.last_price)) * Number(position.size);
    }

    return (
        upnl / 1e8 / Number(marginCurrencies[position.base_token]?.mark_price)
    );
};

export const calcRPnL = (position: Position): number => {
    var rpnl =
        Number(position.profit) -
        Number(position.loss) -
        Number(position.funding_cost) -
        Number(position.ur_funding_cost);

    return rpnl;
};



export const calcLeverage = (
    position: Position,
    marginCurrencies: Record<string, MarginCurrency>,
    cross_profit: number
): string => {
    return Math.abs(
        Number(position.value) /
            (Number(position.margin) +
                (!position.is_cross
                    ? Number(calcUPnL(position, marginCurrencies)) -
                      Number(position.ur_funding_cost)
                    : cross_profit)) /
            Number(marginCurrencies[position.base_token]?.mark_price)
    ).toFixed(2);
};

export const calcOpenInterest = (positions: Position[]): string => {
    const total = positions.reduce((sum, position) => sum + Number(position.value), 0);
    return (total / 1e8).toFixed(2);
};


export const calcMargin = (
    price: number,
    amount: number,
    leverage: number,
    mark_price: number,
    decimals: number,
): string => {
    const multiplier = Number(calcMultiplier(mark_price));
    return ((((price * amount) / leverage) * multiplier) / 1e8).toFixed(decimals);
};

export const calcMultiplier = (mark_price: number): string => {
    // Convert markPrice to a BigNumber
    const lastPrice = new BigNumber(mark_price);

    // Divide 1 by lastPrice
    const one = new BigNumber(1);
    let result = one.dividedBy(lastPrice);

    // Multiply result by 1e8
    const tenMillion = new BigNumber(1e8);
    result = result.multipliedBy(tenMillion);

    // Convert result to an integer
    const multiplier = result.integerValue(BigNumber.ROUND_FLOOR);

    //console.log("Multiplier:", multiplier.toString());
    return multiplier.toString();
};

export const calcMarketPrice = (
    last_price: number,
    notional: number,
    slippage: number,
    decimals: number
): string => {
    if (slippage < 0) {
        return (
            Math.floor(
                ((last_price * Math.pow(10, decimals) * (1 + slippage / 100)) / notional) * notional
            ) / Math.pow(10, decimals)
        ).toFixed(decimals + 2);
    } else {
        return (
            Math.ceil(
                ((last_price * Math.pow(10, decimals) * (1 + slippage / 100)) / notional) * notional
            ) / Math.pow(10, decimals)
        ).toFixed(decimals + 2);
    }
};

