// Chart.tsx

import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import {
    widget,
    IChartingLibraryWidget,
    LanguageCode,
    ResolutionString,
} from '../../../charting_library';
import { Datafeed } from '../../../helpers/datafeed';
// import * as React from 'react';
import { eventCloud } from '../../../EventCloud';
import { Asset } from '../../../interfaces/asset.interface';
import { Order } from '../../../interfaces/order.interface';
import { Position } from '../../../interfaces/position.interface';
import './Chart.css';

const getLanguageFromURL = (): LanguageCode | null => {
    return 'en';
};

const overridesDark = {
    'paneProperties.background': '#000000',
    'paneProperties.crossHairProperties.color': '#D84439',
    'mainSeriesProperties.candleStyle.upColor': '#69AA4E',
    'mainSeriesProperties.candleStyle.borderUpColor': '#69AA4E',
    'mainSeriesProperties.candleStyle.downColor': '#D84439',
    'mainSeriesProperties.candleStyle.wickUpColor': '#69AA4E',
    'mainSeriesProperties.candleStyle.wickDownColor': '#D84439',
    'mainSeriesProperties.candleStyle.borderDownColor': '#D84439',
    'mainSeriesProperties.hollowCandleStyle.wickUpColor': '#69AA4E',
    'mainSeriesProperties.hollowCandleStyle.wickDownColor': '#D84439',
    'mainSeriesProperties.areaStyle.color1': '#92aec8',
    'mainSeriesProperties.areaStyle.color2': '#f8f9fc',
    'mainSeriesProperties.areaStyle.linewidth': 2,
    'mainSeriesProperties.areaStyle.linecolor': '#6191bb',
    'mainSeriesProperties.lineStyle.color': '#6191bb',
    'mainSeriesProperties.lineStyle.linewidth': 2,
    'mainSeriesProperties.baselineStyle.topLineWidth': 2,
    'mainSeriesProperties.baselineStyle.bottomLineWidth': 2,
    'mainSeriesProperties.baselineStyle.topFillColor2': 'rgba(111, 179, 111, .1)',
    'mainSeriesProperties.baselineStyle.bottomFillColor1': 'rgba(111, 179, 111, .1)',
    'mainSeriesProperties.baselineStyle.bottomFillColor2': '#D84439',
    'mainSeriesProperties.baselineStyle.topLineColor': '#69AA4E',
    'mainSeriesProperties.baselineStyle.bottomLineColor': '#D84439',
    'paneProperties.vertGridProperties.color': 'rgba(0,0,0,0)',
    'paneProperties.horzGridProperties.color': 'rgba(0,0,0,0)',
    'scalesProperties.backgroundColor': '#D84439',
    'scalesProperties.lineColor': 'rgba(0,0,0,1)',
    'scalesProperties.textColor': '#929397',
    'scalesProperties.fontSize': 10,
    'centerElement.background': '#121722',

    'linetoolorder.bodyFontFamily': "ProtoMono, 'IBM Plex Mono', monospace",
    'linetoolorder.quantityFontFamily': "ProtoMono, 'IBM Plex Mono', monospace",
    'linetoolorder.quantityBorderActiveBuyColor': '#69AA4E',
    'linetoolorder.quantityBorderActiveSellColor': '#D84439',
    'linetoolorder.quantityBackgroundInactiveBuyColor': '#69AA4E',
    'linetoolorder.quantityBackgroundInactiveSellColor': '#D84439',
    'linetoolorder.bodyBorderActiveBuyColor': '#69AA4E',
    'linetoolorder.bodyBorderActiveSellColor': '#D84439',
};

export const TVChartContainerLite = ({
    openChart,
    chartType,
}: {
    openChart?: boolean;
    chartType: number;
}) => {
    const chartContainerRef = useRef<HTMLDivElement>(null);
    let cleanUpWidget: (() => void) | null = null;
    const [resolution, setResolution] = useState<ResolutionString>(
        (localStorage.getItem('dmex.selectedChartResolution') as ResolutionString) ||
            ('1' as ResolutionString)
    );
    const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);
    const [orders, setOrders] = useState<Order[]>([]);
    const [positions, setPositions] = useState<Position[]>([]);
    //const [orderLines, setOrderLines] = useState(new Map());
    const orderLinesRef = useRef(new Map());
    const positionLinesRef = useRef(new Map());
    const entryLinesRef = useRef(new Map());

    const tvWidgetRef = useRef<IChartingLibraryWidget | null>(null);
    useEffect(() => {
        const assetSubscription = eventCloud.selectedAsset.subscribe(asset => {
            if (asset && asset.symbol != selectedAsset?.symbol) {
                console.log('[Chart] Asset symbol update', asset, selectedAsset);
                setSelectedAsset(asset);
            }
        });

        return () => {
            assetSubscription.unsubscribe();
        };
    }, [selectedAsset]);

    const addOrderLines = () => {
        //console.log("[addOrderLines]");
        removeAllOrderLines();
        orders.forEach(order => addOrderLine(order));
    };

    const addPositionLines = () => {
        //console.log("[addPositionLines]");
        removeAllPositionLines();
        positions.forEach(order => addPositionLine(order));
    };

    // Function to add an order line to the chart
    const addOrderLine = (order: Order) => {
        //console.log("[addOrderLine] Called for order: ", order);

        const tvWidget = tvWidgetRef.current;
        if (!tvWidget || !selectedAsset || !tvWidget.activeChart) {
            console.log('[addOrderLine] tvWidget is not defined');
            return;
        }

        if (order.asset != selectedAsset.symbol) return;

        //console.log("[addOrderLine] tvWidget is defined, adding order line");

        try {
            const orderLine = tvWidget
                .activeChart()
                .createOrderLine()
                .onCancel(() => {
                    eventCloud.emit('cancelOrder', order);
                })
                .onMove(() => {
                    console.log('order line moved', order, orderLine.getPrice());
                    eventCloud.emit('replaceOrder', { order, new_price: orderLine.getPrice() });
                })
                .setCancelButtonIconColor(order.side ? '#69AA4E' : '#D84439')
                .setCancelButtonBackgroundColor('#000')
                .setCancelButtonBorderColor(order.side ? '#69AA4E' : '#D84439')
                .setQuantityBorderColor(order.side ? '#69AA4E' : '#D84439')
                .setBodyBackgroundColor('#000')
                .setEditable(!order.stop ? true : false)
                .setBodyTextColor(order.side ? '#69AA4E' : '#D84439')
                //.setQuantityBackgroundColor(order.side ? "#69AA4E" : "#D84439")
                .setQuantityBackgroundColor('#000')
                .setBodyBorderColor(order.side ? '#69AA4E' : '#D84439')
                .setText(
                    !order.stop
                        ? order.side
                            ? 'BUY'
                            : 'SEL'
                        : order.stopped
                        ? order.side
                            ? 'BUY'
                            : 'SEL'
                        : 'TRIG'
                )
                .setLineColor(order.side ? '#69AA4E' : '#D84439')
                .setLineWidth(1)
                .setExtendLeft(false)
                .setLineLength(-125, 'pixel')
                .setPrice(
                    !order.stop
                        ? Number(order.price) / 1e8
                        : order.stopped
                        ? Number(order.price) / 1e8
                        : Number(order.stop_price) / 1e8
                )
                .setLineStyle(2) // Solid line
                .setQuantity(
                    formatAmount(Number(order.remaining_amount), Number(selectedAsset?.amount_dec))
                );

            const newOrderLines = new Map(orderLinesRef.current);
            newOrderLines.set(order.hash, orderLine);
            //setOrderLines(newOrderLines);
            orderLinesRef.current = newOrderLines;
        } catch (error) {
            //console.error("[addOrderLine] Error adding order line:", error);
        }
    };

    // Function to add an position line to the chart
    const addPositionLine = (position: Position) => {
        //console.log("[addPositionLine] Called for position: ", position);
        if (!selectedAsset || position.asset != selectedAsset.symbol) return;

        addEntrynLine(position);
        addLiquidationLine(position);
    };

    const addLiquidationLine = (position: Position) => {
        //console.log("[addLiquidationLine] Called for position: ", position);

        const tvWidget = tvWidgetRef.current;
        if (!tvWidget || !selectedAsset || !tvWidget.activeChart) {
            console.log('[addPositionLine] tvWidget is not defined');
            return;
        }

        try {
            const positionLine = tvWidget
                .activeChart()
                .createOrderLine()
                .setBodyBackgroundColor('#000')
                .setBodyTextColor('#7389F9')
                .setQuantityBackgroundColor('#000')
                .setQuantityBorderColor('#7389F9')
                .setBodyBorderColor('#7389F9')
                .setText('CALL')
                .setLineColor('#7389F9')
                .setLineWidth(1)
                .setLineLength(-250, 'pixel')
                .setExtendLeft(false)
                .setPrice(Number(position.liquidation_price) / 1e8)
                .setLineStyle(2) // Solid line
                .setQuantity(
                    formatAmount(Number(position.size), Number(selectedAsset?.amount_dec))
                );

            const newPositionLines = new Map(positionLinesRef.current);
            newPositionLines.set(position.hash, positionLine);
            //setOrderLines(newOrderLines);
            positionLinesRef.current = newPositionLines;
        } catch (error) {
            //console.error("[addLiquidationLine] Error adding order line:", error);
        }
    };

    const addEntrynLine = (position: Position) => {
        //console.log("[addEntrynLine] Called for position: ", position);

        const tvWidget = tvWidgetRef.current;
        if (!tvWidget || !selectedAsset || !tvWidget.activeChart) {
            console.log('[addPositionLine] tvWidget is not defined');
            return;
        }

        try {
            const positionLine = tvWidget
                .activeChart()
                .createOrderLine()
                .setBodyBackgroundColor('#000')
                .setBodyTextColor('#FFAC5A')
                .setQuantityBackgroundColor('#000')
                .setQuantityBorderColor('#FFAC5A')
                .setBodyBorderColor('#FFAC5A')
                .setText('ENT')
                .setLineColor('#FFAC5A')
                .setLineWidth(1)
                .setLineLength(-25, 'pixel')
                .setExtendLeft(false)
                .setQuantityTextColor(position.side ? '#69AA4E' : '#D84439')
                .setPrice(Number(position.entry_price) / 1e8)
                .setLineStyle(2) // Solid line
                .setQuantity(
                    formatAmount(Number(position.size), Number(selectedAsset?.amount_dec))
                );

            const newPositionLines = new Map(entryLinesRef.current);
            newPositionLines.set(position.hash, positionLine);
            //setOrderLines(newOrderLines);
            entryLinesRef.current = newPositionLines;
        } catch (error) {
            //console.error("[addEntrynLine] Error adding order line:", error);
        }
    };

    const setOrderLine = (setOrder: Order, orderLine: any) => {
        setOrders(currentOrders =>
            currentOrders.map(order =>
                order.hash === setOrder.hash ? { ...order, order_line: orderLine } : order
            )
        );
    };

    const removeAllOrderLines = () => {
        if (orderLinesRef.current.size === 0) return;
        const tvWidget = tvWidgetRef.current;
        if (!tvWidget || !tvWidget.activeChart) {
            console.log('[removeAllOrderLines] tvWidget or activeChart is not defined');
            return;
        }

        try {
            const chart = tvWidget.activeChart();
            orderLinesRef.current.forEach((orderLine, hash) => {
                //console.log("Remove order line ", orderLine, hash);
                orderLine.remove();
            });

            orderLinesRef.current = new Map(); // Reset the orderLines state
            //console.log("[removeAllOrderLines] All order lines removed successfully");
        } catch (error) {
            //console.error("[removeAllOrderLines] Error removing order lines:", error);
        }
    };

    const removeAllPositionLines = () => {
        if (positionLinesRef.current.size === 0) return;
        const tvWidget = tvWidgetRef.current;
        if (!tvWidget || !tvWidget.activeChart) {
            console.log('[removeAllPositionLines] tvWidget or activeChart is not defined');
            return;
        }

        try {
            const chart = tvWidget.activeChart();
            positionLinesRef.current.forEach((positionLine, hash) => {
                //console.log("Remove order line ", orderLine, hash);
                positionLine.remove();
            });

            positionLinesRef.current = new Map(); // Reset the orderLines state

            entryLinesRef.current.forEach((positionLine, hash) => {
                //console.log("Remove order line ", orderLine, hash);
                positionLine.remove();
            });

            entryLinesRef.current = new Map(); // Reset the orderLines state

            //console.log("[removeAllOrderLines] All order lines removed successfully");
        } catch (error) {
            //console.error("[removeAllOrderLines] Error removing order lines:", error);
        }
    };

    useEffect(() => {
        //console.log("[useEffect] Orders effect triggered");
        if (!selectedAsset || !tvWidgetRef.current) {
            //console.log("[useEffect] Selected asset or tvWidgetRef not ready");
            return;
        }
        //console.log("[useEffect] Adding order lines for each order");
        addOrderLines();
    }, [orders]);

    useEffect(() => {
        //console.log("[useEffect] positions/asset effect triggered");
        if (!selectedAsset || !tvWidgetRef.current) {
            //console.log("[useEffect] Selected asset or tvWidgetRef not ready");
            return;
        }
        //console.log("[useEffect] Adding order lines for each order");
        addPositionLines();
    }, [positions, selectedAsset]);

    useEffect(() => {
        // Subscribe to assetsStore
        const orderSubscription = eventCloud.ordersStore.subscribe(orders => {
            if (!orders || orders.length === 0) {
                setOrders([]); // Return early if newAssets is empty
                return;
            }
            //console.log("Subscribe new orders ", orders);
            setOrders(orders);
        });

        const positionSubscription = eventCloud.positionsStore.subscribe(pos => {
            if (!pos || pos.length === 0) {
                setPositions([]); // Return early if newAssets is empty
                return;
            }
            //console.log("Subscribe new positionsStore ", pos);
            setPositions(pos);
        });

        return () => {
            orderSubscription.unsubscribe();
            positionSubscription.unsubscribe();
        };
    }, []);

    // Function to initialize the widget
    const initWidget = () => {
        //console.log("[Chart] resoltuion", resolution);
        if (chartContainerRef.current && selectedAsset) {
            const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone as any; // Use Timezone type or keep 'as any' if Timezone type is not available
            const tvWidget = new widget({
                width: 100,
                height: 100,
                symbol: selectedAsset.symbol,
                interval: resolution,
                fullscreen: true,
                timezone: localTimeZone,
                container: chartContainerRef.current,
                datafeed: new Datafeed(),
                library_path: 'charting_library/charting_library/',
                locale: getLanguageFromURL() || 'en',
                disabled_features: [
                    'use_localstorage_for_settings',
                    'header_widget',
                    'left_toolbar',
                    'timeframes_toolbar',
                    'legend_widget',
                    //"control_bar",
                    'symbol_search_hot_key',
                    'context_menus',
                    'edit_buttons_in_legend',
                    //"axis_pressed_mouse_move_scale",
                    'popup_hints',
                    //"chart_zoom",
                ],
                enabled_features: [
                    //"chart_style_hilo",
                ],
                // theme: "dark",
                //custom_css_url: '../../css/Chart.css',
                custom_font_family: "ProtoMono, 'IBM Plex Mono', monospace",
                autosize: true,
                overrides: {
                    ...overridesDark,
                    'mainSeriesProperties.style': chartType,
                    'mainSeriesProperties.lineStyle.color': '#FFAC5A',
                },
                studies_overrides: {
                    'volume.volume.color.0': '#000',
                    'volume.volume.color.1': '#000',
                },
                loading_screen: {
                    backgroundColor: '#000',
                },
                custom_formatters: {
                    studyFormatterFactory: (format: any, symbolInfo: any) => {
                        if (format.type === 'volume') {
                            return {
                                format: (value: any) => '$' + (value / 1e6).toFixed(3) + 'M',
                            };
                        }
                        return null; // this is to use default formatter;
                    },
                    priceFormatterFactory: (format: any, symbolInfo: any, third: any) => {
                        return {
                            format: (price: number) => {
                                return formatPriceLocale(price, selectedAsset.decimals);
                            },
                        };
                    },
                } as any,
            });

            tvWidget.onChartReady(() => {
                tvWidgetRef.current = tvWidget; // Assign the widget to the ref
                addOrderLines();
                addPositionLines();
                // Add order lines here if needed
            });

            // @ts-ignore
            window.tvWidget = tvWidget;

            // Cleanup function to remove the widget
            return () => tvWidget.remove();
        }

        return () => {};
    };

    useEffect(() => {
        const handleResolutionChange = (newResolution: string) => {
            setResolution(newResolution as ResolutionString);
        };

        const resolutionChangeSubscription =
            eventCloud.selectedChartResolution.subscribe(handleResolutionChange);

        return () => {
            resolutionChangeSubscription.unsubscribe();
        };
    }, []);

    useEffect(() => {
        const reloadChart = () => {
            console.log('[TVChartContainer] Reloading chart');
            if (chartContainerRef.current) {
                // Clean up the current widget before reinitializing
                if (cleanUpWidget) cleanUpWidget();
                cleanUpWidget = initWidget();
            }
        };

        console.log('[TVChartContainer] selectedAsset', selectedAsset);
        if (!selectedAsset) {
            return;
        }

        // Initialize the widget
        cleanUpWidget = initWidget();

        const unsubscribeReloadChart = eventCloud.on('reloadChart', reloadChart);

        return () => {
            cleanUpWidget?.();
            unsubscribeReloadChart();
        };
    }, [selectedAsset, resolution]);

    // useEffect(() => {
    //   console.log("[TVChartContainer] selectedAsset", selectedAsset);
    //   if (!selectedAsset) {
    //     return;
    //   }

    //   // Initialize the widget
    //   cleanUpWidget = initWidget();

    //   // Subscribe to resolution changes
    //   const resolutionChangeSubscription =
    //     eventCloud.selectedChartResolution.subscribe((newResolution) => {
    //       setResolution(newResolution as ResolutionString);
    //     });

    //   // Clean up
    //   return () => {
    //     cleanUpWidget?.();
    //     resolutionChangeSubscription.unsubscribe();
    //   };
    // }, [resolution, selectedAsset]);

    // useEffect(() => {
    //   // Event listener for "reloadChart"
    //   const reloadChart = () => {
    //     console.log("[TVChartContainer] Reloading chart");
    //     if (chartContainerRef.current) {
    //       // Clean up the current widget before reinitializing
    //       if (cleanUpWidget) cleanUpWidget();
    //       cleanUpWidget = initWidget();
    //     }
    //   };

    //   const unsubscribeReloadChart = eventCloud.on("reloadChart", reloadChart);

    //   return () => {
    //     unsubscribeReloadChart();
    //   };
    // }, [selectedAsset]);

    return (
        <div
            ref={chartContainerRef}
            className={classNames('TVChartContainer TVChartContainerLite', { openChart })}
        />
    );
};

const formatPriceLocale = (price: number, decimals: number): string => {
    if (!decimals) return '0';
    //console.log("[formatPriceLocale] price=", price, decimals)
    const userLocale = navigator.language;
    return new Intl.NumberFormat(userLocale, {
        style: 'decimal',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    }).format(price);
};

function widgetIsReady(widget: any) {
    // Implement logic to check if the widget is ready
    return widget && typeof widget.changeSymbol === 'function';
}

const formatAmount = (amount: number, amount_dec: number): string => {
    return Number(amount / 1e8).toFixed(amount_dec);
};
