// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

export class Trade {
    bb: flatbuffers.ByteBuffer | null = null;
    bb_pos = 0;
    __init(i: number, bb: flatbuffers.ByteBuffer): Trade {
        this.bb_pos = i;
        this.bb = bb;
        return this;
    }

    static getRootAsTrade(bb: flatbuffers.ByteBuffer, obj?: Trade): Trade {
        return (obj || new Trade()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    static getSizePrefixedRootAsTrade(bb: flatbuffers.ByteBuffer, obj?: Trade): Trade {
        bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
        return (obj || new Trade()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    price(): bigint {
        const offset = this.bb!.__offset(this.bb_pos, 4);
        return offset ? this.bb!.readInt64(this.bb_pos + offset) : BigInt('0');
    }

    amount(): bigint {
        const offset = this.bb!.__offset(this.bb_pos, 6);
        return offset ? this.bb!.readInt64(this.bb_pos + offset) : BigInt('0');
    }

    asset(): string | null;
    asset(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
    asset(optionalEncoding?: any): string | Uint8Array | null {
        const offset = this.bb!.__offset(this.bb_pos, 8);
        return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
    }

    priceUp(): boolean {
        const offset = this.bb!.__offset(this.bb_pos, 10);
        return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
    }

    timestamp(): bigint {
        const offset = this.bb!.__offset(this.bb_pos, 12);
        return offset ? this.bb!.readInt64(this.bb_pos + offset) : BigInt('0');
    }

    static startTrade(builder: flatbuffers.Builder) {
        builder.startObject(5);
    }

    static addPrice(builder: flatbuffers.Builder, price: bigint) {
        builder.addFieldInt64(0, price, BigInt('0'));
    }

    static addAmount(builder: flatbuffers.Builder, amount: bigint) {
        builder.addFieldInt64(1, amount, BigInt('0'));
    }

    static addAsset(builder: flatbuffers.Builder, assetOffset: flatbuffers.Offset) {
        builder.addFieldOffset(2, assetOffset, 0);
    }

    static addPriceUp(builder: flatbuffers.Builder, priceUp: boolean) {
        builder.addFieldInt8(3, +priceUp, +false);
    }

    static addTimestamp(builder: flatbuffers.Builder, timestamp: bigint) {
        builder.addFieldInt64(4, timestamp, BigInt('0'));
    }

    static endTrade(builder: flatbuffers.Builder): flatbuffers.Offset {
        const offset = builder.endObject();
        return offset;
    }

    static createTrade(
        builder: flatbuffers.Builder,
        price: bigint,
        amount: bigint,
        assetOffset: flatbuffers.Offset,
        priceUp: boolean,
        timestamp: bigint
    ): flatbuffers.Offset {
        Trade.startTrade(builder);
        Trade.addPrice(builder, price);
        Trade.addAmount(builder, amount);
        Trade.addAsset(builder, assetOffset);
        Trade.addPriceUp(builder, priceUp);
        Trade.addTimestamp(builder, timestamp);
        return Trade.endTrade(builder);
    }
}
