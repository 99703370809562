// CreateOrder.ts

import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import BN from 'bn.js';
import { eventCloud } from './../EventCloud';
import { ethers } from 'ethers';
import { keccak256, defaultAbiCoder } from 'ethers/lib/utils';
import { Wallet } from '../interfaces/wallet.interface';
import { Sign } from './Sign';
import { formatNotificationMessage } from '../helpers/notificationHelpers';

interface OrderParams {
    amount: string;
    price: string;
    side: boolean;
    closing_order: boolean;
    stop: boolean;
    stop_price: string;
    leverage: string;
    base_token: string;
    is_market: boolean;
    replace_hash: string;
}

export const handleCreateOrder = async ({
    amount,
    price,
    side,
    leverage,
    closing_order,
    stop,
    stop_price,
    base_token,
    is_market,
    replace_hash,
}: OrderParams) => {
    console.log('amount, price, stop_price ', amount, price, stop_price);

    const selectedWallet = eventCloud.selectedWallet.getValue();
    if (!selectedWallet) return;

    if (Number(amount) <= 0) {
        eventCloud.notify(
            formatNotificationMessage({
                title: 'AMOUNT MUST BE GREATER THAN ZERO',
                message: [],
            }),
            'error',
            '2500'
        );
        return;
    }

    console.log('[handleCreateOrder] 3');
    amount = (Number(amount) * 1e8).toFixed(0);
    price = (Number(price) * 1e8).toFixed(0);
    stop_price = (Number(stop_price) * 1e8).toFixed(0);

    if (!eventCloud.selectedAsset) {
        console.log('selectedAsset null');
        return;
    }

    const selectedAsset = eventCloud.selectedAsset.getValue();
    if (selectedAsset == null) {
        return; // Or some other placeholder value
    }

    const user_address = selectedWallet.address;
    const asset = selectedAsset.symbol;
    const nonce = Date.now();

    console.log(`[handleCreateOrder] 
        eventCloud.params=${eventCloud.params}
        asset=${asset}
        base_token=${base_token}
        user_address=${user_address}
        amount=${amount}
        price=${price}
        side=${side}
        nonce=${nonce}
        leverage=${leverage}
        closing_order=${closing_order}

    `);

    //                                                          amount          price           side            nonce           leverage          maintenance_margin
    //return keccak256(abi.encode(this, asset, baseToken, user,   tradeValues[4], tradeValues[6], !takerIsBuying, tradeValues[0], tradeValues[2],   contractValues[1]));

    const order_hash = ethers.utils.keccak256(
        ethers.utils.defaultAbiCoder.encode(
            ['string', 'address', 'address', 'uint256', 'uint256', 'bool', 'uint256', 'uint256'],
            [asset, base_token, user_address, amount, price, side, nonce, leverage]
        )
    );

    if (!order_hash) {
        throw new Error('Failed to generate hash');
    }

    if (!eventCloud.selectedWallet) {
        throw new Error('No wallet selected');
    }

    console.log('Create order hash', order_hash);
    console.log('Create order wallet', selectedWallet.address, selectedWallet.privateKey);

    const signatureResult = await Sign(order_hash, selectedWallet);

    // Check if the signatureResult is null
    if (!signatureResult) {
        console.error('Signature process was not completed.');
        return false;
    }

    replace_hash =
        replace_hash == ''
            ? '0x0000000000000000000000000000000000000000000000000000000000000000'
            : replace_hash;

    const { v, r, s } = signatureResult;

    eventCloud.sendOrder({
        hash: order_hash,

        user_address,
        base_token,
        asset,
        amount,
        price,
        side,
        nonce,
        leverage,
        closing_order,

        v,
        r,
        s,

        remaining_amount: amount,
        closed: false,
        stop,
        stop_price,
        stopped: false,
        is_market: is_market,
        replace_hash,
    });
};

const convertToBN = (value: any) => {
    const parts = value.split('.');
    let bnValue = new BN(parts[0]).mul(new BN('100000000'));

    if (parts.length > 1) {
        let decimalPart = parts[1];
        // Adjust the decimal part to represent an integer value correctly
        if (decimalPart.length > 8) {
            decimalPart = decimalPart.substr(0, 8); // Truncate to 8 decimal places
        }
        const decimalBN = new BN(decimalPart).mul(
            new BN('100000000').div(new BN('1' + '0'.repeat(decimalPart.length)))
        );
        bnValue = bnValue.add(decimalBN);
    }

    return bnValue;
};

export default handleCreateOrder;
