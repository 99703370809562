// Balances.tsx

import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
// eslint-disable-next-line no-restricted-imports
import { ReactComponent as IconArrow } from './assets/icons/svg/arrow.svg';
import { eventCloud } from './EventCloud';
import { Button } from './components/Button/Button';
import { Modal } from './components/Modal/Modal';
import { Balance } from './interfaces/balance.interface';
import { MarginCurrency } from './interfaces/marginCurrency.interface';
import { Wallet } from './interfaces/wallet.interface';
import { Tooltip } from './components/Tooltip';
import { getEthProvider } from './helpers/web3Provider';
import { formatNotificationMessage } from './helpers/notificationHelpers';

import DepositWithdrawDialog from './dialogs/DepositWithdrawDialog';
import DepositWithdrawBTC from './dialogs/DepositWithdrawBTC';

const Balances = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedMarginCurrency, setSelectedMarginCurrency] = useState<MarginCurrency | null>(
        eventCloud.getMarginCurrencyObject()
    );
    const [balances, setBalances] = useState<Record<string, Balance>>({});
    const [selectedWallet, setSelectedWallet] = useState<Wallet | null>(null);
    const [walletBalance, setWalletBalance] = useState('0');
    const [dialogAction, setDialogAction] = useState('deposit');

    useEffect(() => {
        const currencySubscription = eventCloud.marginCurrenciesStore.subscribe(() => {
            setSelectedMarginCurrency(eventCloud.getMarginCurrencyObject());
        });

        return () => {
            currencySubscription.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (eventCloud.selectedWallet) {
            const walletSub = eventCloud.selectedWallet.subscribe(wall => {
                if (wall) {
                    setSelectedWallet(wall);
                }
            });

            return () => {
                walletSub.unsubscribe();
            };
        }
    }, []);

    useEffect(() => {
        if (!selectedWallet) return;
        setWalletBalance('0');
        const fetchWalletBalance = async () => {
            if (!selectedMarginCurrency) return;
            if (
                selectedMarginCurrency.token_address ==
                    '0x0000000000000000000000000000000000000003' ||
                selectedMarginCurrency.token_address ==
                    '0x0000000000000000000000000000000000000002' ||
                selectedMarginCurrency.token_address ==
                    '0x0000000000000000000000000000000000000024' ||
                selectedMarginCurrency.token_address == '0x0000000000000000000000000000000000000028'
            )
                return;

            if (!selectedWallet.isMetaMask) return;
            try {
                //console.log("[fetchEthereumBalance] ", selectedMarginCurrency);
                // Set up the provider using Infura
                const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
                if (ENVIRONMENT === 'dev') return;
                const nodeUrl = getEthProvider(selectedMarginCurrency.network);

                const provider = new ethers.providers.JsonRpcProvider(nodeUrl);

                // Specify the address you want to check
                const address = selectedWallet.address; // Replace with the correct Ethereum address

                // Fetch the balance

                let balance;
                if (
                    selectedMarginCurrency?.token_contract_address ==
                    '0x0000000000000000000000000000000000000000'
                ) {
                    //console.log("[Balances] symbol=",selectedMarginCurrency?.symbol, address);
                    balance = await provider.getBalance(address);
                } else {
                    //console.log("[fetchWalletBalance] token_contract=", selectedMarginCurrency?.token_contract_address)
                    const tokenContract = new ethers.Contract(
                        selectedMarginCurrency?.token_contract_address,
                        [
                            // ABI for DAI Token (only the balanceOf function is needed)
                            'function balanceOf(address owner) view returns (uint256)',
                        ],
                        provider
                    );

                    balance = await tokenContract.balanceOf(address);
                    //console.log(`[fetchWalletBalance] token_contract=${selectedMarginCurrency?.token_contract_address} `)
                }

                //console.log("[fetchEthereumBalance] balance=", balance.toString());
                //Update state with the fetched balance
                setWalletBalance(balance.toString());
            } catch (error) {
                console.error('[fetchEthereumBalance] Error fetching Ethereum balance:', error);
            }
        };

        // Setting up the timer
        const intervalId = setInterval(fetchWalletBalance, 10000); // every 10 seconds

        fetchWalletBalance();

        // Clearing the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [selectedWallet, selectedMarginCurrency]);

    useEffect(() => {
        const subscription = eventCloud.balancesStore.subscribe(newBal => {
            //console.log("[Balances] newBal=", newBal);
            setBalances(newBal);
        });

        // Clean up the subscription
        return () => subscription.unsubscribe();
    }, []);

    const handleOpenDialog = (action: string) => {
        console.log('[handleOpenDialog] ', selectedMarginCurrency?.token_address);
        if (selectedMarginCurrency?.token_address == '0x0000000000000000000000000000000000000003') {
            eventCloud.notify(
                formatNotificationMessage({
                    title: 'Demo Margin Currency',
                    message: [
                        {
                            text: 'Please select a live margin currency',
                            bold: false,
                        },
                    ],
                }),
                'warn',
                '3000'
            );
            return;
        }

        //console.log("handleOpenDialog")
        setDialogAction(action);
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        //console.log("handleCloseDialog")
        setIsDialogOpen(false);
    };

    if (!selectedMarginCurrency) return null;
    if (!selectedWallet) return null;

    return (
        <div className="text-xxs flex items-center pt-0 pb-1 sm:py-1 lg:py-0">
            {/* Other balance-related UI components */}
            <div className="mr-4">
                <ul className="text-left">
                    <li className="flex -mb-0.5">
                        <div className="text-gray-450" style={{ width: '80px' }}>
                            <Tooltip
                                label="NET BALANCE"
                                text="HARD BALANCE ON DMEX"
                                position="right"
                            />
                        </div>{' '}
                        <div className="ml-auto">
                            {formatAmount(
                                !balances[selectedMarginCurrency?.token_address]
                                    ? 0
                                    : Number(
                                          balances[selectedMarginCurrency?.token_address]
                                              .total_balance
                                      ),
                                selectedMarginCurrency.decimals
                            )}{' '}
                            {selectedMarginCurrency.symbol}{' '}
                            <span className="hidden sm:inline">
                                [{selectedMarginCurrency.network_slug}]
                            </span>
                        </div>
                    </li>
                    <li className="flex">
                        <div className="text-gray-450" style={{ width: '80px' }}>
                            <Tooltip
                                label="AVAILABLE"
                                text="TRADEABLE BALANCE: NET BALANCE - RESERVED BALANCE + UNREALIZED CROSS PROFIT"
                                position="right"
                            />
                        </div>{' '}
                        <div className="ml-auto">
                            <Tooltip
                                label={
                                    <span>
                                        {formatAmount(
                                            !balances[selectedMarginCurrency?.token_address]
                                                ? 0
                                                : Math.max(0,  Number(
                                                      balances[
                                                          selectedMarginCurrency?.token_address
                                                      ].available_balance
                                                  ) -
                                                      Number(
                                                          balances[
                                                              selectedMarginCurrency?.token_address
                                                          ].crossmargin_reserve
                                                      ) +
                                                      Number(
                                                          balances[
                                                              selectedMarginCurrency?.token_address
                                                          ].crossmargin_profit
                                                      )
                                                ),
                                            selectedMarginCurrency.decimals
                                        ) +
                                            ' ' +
                                            selectedMarginCurrency.symbol}
                                        <span className="hidden sm:inline">
                                            {' '}
                                            [{selectedMarginCurrency.network_slug}]
                                        </span>
                                    </span>
                                }
                                text={
                                    'CROSS MARGIN RESERVE: ' +
                                    formatAmount(
                                        !balances[selectedMarginCurrency?.token_address]
                                            ? 0
                                            : Number(
                                                  balances[selectedMarginCurrency?.token_address]
                                                      .crossmargin_reserve
                                              ),
                                        selectedMarginCurrency.decimals
                                    ) +
                                    ' ' +
                                    selectedMarginCurrency.symbol +
                                    ' [' +
                                    selectedMarginCurrency.network_slug +
                                    '] ' +
                                    ' | CROSS MARGIN PROFIT: ' +
                                    formatAmount(
                                        !balances[selectedMarginCurrency?.token_address]
                                            ? 0
                                            : Number(
                                                  balances[selectedMarginCurrency?.token_address]
                                                      .crossmargin_profit
                                              ),
                                        selectedMarginCurrency.decimals
                                    ) +
                                    ' ' +
                                    selectedMarginCurrency.symbol +
                                    ' [' +
                                    selectedMarginCurrency.network_slug +
                                    ']'
                                }
                                position="right"
                            />
                        </div>
                    </li>
                </ul>
            </div>
            <Button
                variant="gray"
                size="medium"
                onClick={() => handleOpenDialog('deposit')}
                className="balances-deposit-button"
            >
                <IconArrow className="-ml-1 mr-1 w-3 " /> DEPOSIT
            </Button>
            <Button
                variant="gray"
                size="medium"
                className="ml-0.5 sm:ml-2"
                onClick={() => handleOpenDialog('withdraw')}
            >
                <IconArrow className="-ml-1 mr-1 w-3 rotate-180" /> Withdraw
            </Button>

            <Modal visible={isDialogOpen} onClose={handleCloseDialog} freeToClose>
                {selectedMarginCurrency.token_address ==
                    '0x0000000000000000000000000000000000000002' ||
                selectedMarginCurrency.token_address ==
                    '0x0000000000000000000000000000000000000024' ||
                selectedMarginCurrency.token_address ==
                    '0x0000000000000000000000000000000000000028' ||
                selectedMarginCurrency.token_address ==
                    '0x0000000000000000000000000000000000000029' ||
                selectedMarginCurrency.token_address ==
                    '0x0000000000000000000000000000000000000030' ? (
                    <DepositWithdrawBTC
                        action={dialogAction}
                        onClose={handleCloseDialog}
                        selectedWallet={selectedWallet}
                        selectedMarginCurrency={selectedMarginCurrency}
                        balances={balances}
                    />
                ) : (
                    <DepositWithdrawDialog
                        action={dialogAction}
                        onClose={handleCloseDialog}
                        walletBalance={walletBalance}
                        selectedWallet={selectedWallet}
                        selectedMarginCurrency={selectedMarginCurrency}
                        balances={balances}
                    />
                )}
            </Modal>
        </div>
    );
};

const formatAmount = (amount: number, amount_dec: number): string => {
    return (amount / 1e18).toFixed(amount_dec);
};

export default Balances;
