import React from 'react';

import MetamaskImage from '../../assets/icons/metamask.png';
import { Modal } from '../Modal/Modal';
import { ModalHeader } from '../Modal/ModalHeader';

interface MetamaskPopupProps {
    open: boolean;
    onClose: () => void;
}

export const MetamaskPopup: React.FC<MetamaskPopupProps> = ({ open, onClose }) => {
    return (
        <Modal visible={open} onClose={onClose}>
            <div className="sm:-mt-5 -mt-1">
                <ModalHeader onClose={onClose}>Sign Transaction in External Wallet</ModalHeader>
            </div>
            <div className="flex flex-col items-center justify-center text-center text-xxs text-gray-550">
                {/* Flex container for logos */}
                <div className="flex justify-center items-center my-4">
                    <img src={MetamaskImage} className="w-20 h-20 mx-2" alt="MetamaskLogo" />
                </div>
                Awaiting signature from your
                <br />
                external wallet.
                {/*<div className="mt-4 w-full">
          <Button
            variant="gray"
            size="large"
            onClick={onClose}
            className="w-full"
          >
            Cancel
          </Button>
        </div>*/}
            </div>
        </Modal>
    );
};
