// datafeed.ts
import { eventCloud } from './../EventCloud';
import { Candle } from './../interfaces/candle.interface';
import { Asset } from './../interfaces/asset.interface';

class Datafeed {
    private lastBar: Candle | null = null;

    onReady(callback: any) {
        console.log('[onReady]: Method call');
        setTimeout(() => callback(configurationData));
    }

    searchSymbols(userInput: any, exchange: any, symbolType: any, onResultReadyCallback: any) {
        console.log('[searchSymbols]: Method call');
    }

    resolveSymbol(
        symbolName: any,
        onSymbolResolvedCallback: any,
        onResolveErrorCallback: any,
        extension: any
    ) {
        console.log('[resolveSymbol]: Method call', symbolName);

        const asset = eventCloud.selectedAsset.getValue();

        if (!asset) return;

        const pricescale = 10 ** asset.decimals;
        const minmov = asset.notional * pricescale;
        console.log('[resolveSymbol]: pricescale ', pricescale, minmov);

        const symbolInfo = {
            ticker: asset.symbol,
            name: asset.symbol,
            //description: 'Bitcoin/USD',
            //type: symbolItem.type,
            session: '24x7',
            timezone: 'Etc/UTC',
            exchange: 'DMEX',
            minmov: minmov,
            pricescale: pricescale,
            has_intraday: true,
            visible_plots_set: 'ohlcv',
            has_weekly_and_monthly: false,
            has_seconds: true,
            supported_resolutions: ['1S', '1', '5', '15', '60', 'D'],
            volume_precision: 0,
            data_status: 'streaming',
        };

        this.subscribeBars(
            symbolInfo,
            eventCloud.selectedChartResolution.getValue(),
            () => {
                console.log('[onRealtimeCallback] ');
            },
            'dmex',
            () => {
                console.log('[onResetCacheNeededCallback] ');
            }
        );

        setTimeout(() => onSymbolResolvedCallback(symbolInfo));
    }

    getBars(
        symbolInfo: any,
        resolution: any,
        periodParams: any,
        onHistoryCallback: any,
        onErrorCallback: any
    ) {
        //console.log('[getBars]: Method call', symbolInfo, resolution, periodParams);

        const { from, to, countBack, firstDataRequest } = periodParams;
        const symbol = symbolInfo.ticker || symbolInfo.name;

        // Construct the query string
        const queryParams = new URLSearchParams({
            symbol: symbol,
            resolution: resolution,
            countBack: countBack,
            firstDataRequest: firstDataRequest,
            from: from.toString(),
            to: to.toString(),
        }).toString();

        const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
        if (ENVIRONMENT == 'dev') {
            var url = `http://${process.env.REACT_APP_DEV_BACKEND_ENDPOINT}/candles?${queryParams}`;
        } else {
            var url = `https://${process.env.REACT_APP_LIVE_BACKEND_ENDPOINT}/candles?${queryParams}`;
        }

        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                //console.log("[getBars] data", data);
                // Assuming 'data' is in the format expected by onHistoryCallback.
                // You might need to transform 'data' to match the expected format.

                if (data.length > 0) {
                    onHistoryCallback(data);
                    if (firstDataRequest) {
                        this.lastBar = data[data.length - 1];
                    }
                } else {
                    onHistoryCallback([], {
                        noData: true,
                    });
                }
            })
            .catch(error => {
                console.error('[getBars]: Fetch error', error);
                onErrorCallback(error.message);
            });
    }

    subscribeBars(
        symbolInfo: any,
        resolution: any,
        onRealtimeCallback: any,
        subscriberUID: any,
        onResetCacheNeededCallback: any
    ) {
        console.log('[subscribeBars]: Method call with subscriberUID:', subscriberUID);
        eventCloud.setChartStreamingCallback((lastTrade: any) => {
            const asset = eventCloud.selectedAsset.getValue();
            if (asset && this.lastBar != null) {
                var price = parseFloat(formatPrice(lastTrade.Price, asset.decimals));
                var tradeCandleTime = convertTimeToCandleTime(lastTrade.Timestamp, resolution);

                //console.log('[subscribeBars]: lastTrade:', lastTrade, price, tradeCandleTime);

                // Check if there's a missing bar
                var expectedNextBarTime = this.calculateNextBarTime(this.lastBar.time, resolution);
                if (tradeCandleTime > expectedNextBarTime && resolution != '1S') {
                    console.log(
                        `[subscribeBars] lastTrade.Timestamp=${lastTrade.Timestamp} tradeCandleTime=${tradeCandleTime} expectedNextBarTime=${expectedNextBarTime}`
                    );
                    //eventCloud.emit("reloadChart", {});
                }

                if (this.lastBar.time == tradeCandleTime) {
                    if (Number(price) > Number(this.lastBar.high)) this.lastBar.high = price;
                    if (Number(price) < Number(this.lastBar.low)) this.lastBar.low = price;
                    this.lastBar.close = price;
                    this.lastBar.volume =
                        this.lastBar.volume + formatAmount(lastTrade.Amount, lastTrade.Price);
                } else {
                    this.lastBar = {
                        time: tradeCandleTime,
                        open: price,
                        high: price,
                        low: price,
                        close: price,
                        volume: formatAmount(lastTrade.Amount, lastTrade.Price),
                    };
                }

                onRealtimeCallback(this.lastBar);
            }
        });
    }

    calculateNextBarTime(lastBarTime: number, resolution: any): number {
        // Add resolution to lastBarTime to find the expected next bar time
        // The implementation of this depends on how your time is formatted and how you define resolution
        // For example, if resolution is in minutes and time is in UNIX timestamp (milliseconds):

        if (resolution === '1S') {
            return lastBarTime + 1000; // Add 1000 milliseconds for 1 second
        }

        return lastBarTime + resolution * 60 * 1000;
    }

    unsubscribeBars(subscriberUID: any) {
        console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
    }
}

const convertTimeToCandleTime = (timeString: string, resolution: string): number => {
    const date = new Date(timeString);

    switch (resolution) {
        case '1S':
            // For 1-second resolution, reset to the start of the UTC second
            date.setUTCSeconds(date.getUTCSeconds(), 0); // Set seconds and reset milliseconds
            break;
        case '1':
        case '5':
        case '15':
        case '60':
            // For minute resolutions, round down to the nearest interval
            const minutes = parseInt(resolution, 10);
            const roundedMinutes = Math.floor(date.getUTCMinutes() / minutes) * minutes;
            date.setUTCMinutes(roundedMinutes, 0, 0); // Set minutes and reset seconds and milliseconds
            break;
        case '1D':
            // For daily resolution, reset to the start of the UTC day
            date.setUTCHours(0, 0, 0, 0); // Reset hours, minutes, seconds, and milliseconds to UTC
            break;
        default:
            throw new Error('Invalid resolution');
    }

    return date.getTime();
};

const formatPrice = (price: number, decimals: number): string => {
    return (Number(price) / 1e8).toFixed(decimals);
};

const formatAmount = (amount: number, price: number): number => {
    return ((Number(amount) / 1e8) * price) / 1e8;
};

const configurationData = {
    supports_search: false,
    supports_group_request: false,
    supports_marks: false,
    supports_timescale_marks: false,
    supports_time: true,
    supported_resolutions: ['1S', '1', '5', '15', '60', 'D'],
};

export { Datafeed };
