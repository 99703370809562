// ChangeRiskLimit.ts

import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { eventCloud } from './../EventCloud';
import { ethers } from 'ethers';
import { Wallet } from '../interfaces/wallet.interface';
import { Sign } from './Sign';

declare global {
    interface Window {
        ethereum: any;
    }
}

export const handleChangeRiskLimit = async (risk_limit: string): Promise<boolean> => {
    console.log('[handleChangeRiskLimit] risk_limit=', risk_limit);

    const selectedWallet = eventCloud.selectedWallet.getValue();
    if (!selectedWallet) return false;

    const hash = ethers.utils.keccak256(
        ethers.utils.defaultAbiCoder.encode(['string'], [risk_limit])
    );

    const signatureResult = await Sign(hash, selectedWallet);

    // Check if the signatureResult is null
    if (!signatureResult) {
        console.error('Signature process was not completed.');
        return false;
    }

    const { v, r, s } = signatureResult;

    eventCloud.changeRiskLimit({
        risk_limit,
        hash,
        v,
        r,
        s,
        user: selectedWallet.address,
    });

    return true;
};

export default handleChangeRiskLimit;
