import { PropsWithoutRef } from 'react';

export interface FieldErrorProps
    extends Omit<PropsWithoutRef<JSX.IntrinsicElements['p']>, 'className'> {}

export const FieldError = ({ children, ...props }: FieldErrorProps) => (
    <p {...props} className="text-xxs text-gray-150 bg-gray-650 px-3 py-0.5">
        {children}
    </p>
);
