// WithdrawalConfirmDialog.tsx

import React, { useState } from 'react';
import { Button } from '../components/Button/Button';
import { ModalHeader } from '../components/Modal/ModalHeader';
import { MarginCurrency } from '../interfaces/marginCurrency.interface';

interface WithdrawalConfirmProps {
    onClose: () => void;
    onConfirm: () => void;
    marginCurrency: MarginCurrency;
    amount: string;
    destinationAddress: string;
    feeWithdrawal: string;
    netAmount: string;
}

const WithdrawalConfirmDialog: React.FC<WithdrawalConfirmProps> = ({
    onClose,
    onConfirm,
    marginCurrency,
    amount,
    destinationAddress,
    feeWithdrawal,
    netAmount,
}) => {
    return (
        <>
            <div>
                <div>
                    <div className="-mt-4">
                        <ModalHeader onClose={onClose}>Confirm Withdrawal</ModalHeader>
                    </div>

                    <div className="text-xs">
                        <div className="mt-6 mb-2">Withdrawal Details</div>
                        <div className="flex justify-between">
                            <div className="text-gray-450">Currency:</div>
                            <div>
                                {marginCurrency.symbol} [{marginCurrency.network_slug}]
                            </div>
                        </div>
                        <div className="flex justify-between">
                            <div className="text-gray-450">Amount:</div>
                            <div>
                                {amount} {marginCurrency.symbol} [{marginCurrency.network_slug}]
                            </div>
                        </div>
                        <div className="flex justify-between">
                            <div className="text-gray-450">Withdrawal Fee:</div>
                            <div>
                                {feeWithdrawal} {marginCurrency.symbol} [
                                {marginCurrency.network_slug}]
                            </div>
                        </div>
                        <div className="flex justify-between mt-2">
                            <div className="text-yellow-550">You will receive:</div>
                            <div className="text-yellow-550">
                                {netAmount} {marginCurrency.symbol} [{marginCurrency.network_slug}]
                            </div>
                        </div>
                        <div className="justify-between mt-4">
                            <div className="text-grey-550 mb-2">Destination Address:</div>
                            <div
                                className="text-red-540 text-sm border border-gray-650 p-2 text-center"
                                style={{ fontFamily: 'monospace' }}
                            >
                                {destinationAddress}
                            </div>
                        </div>
                        <div className="text-xxs text-gray-450 pt-4 pb-2 mt-2">
                            <span className="text-red-550">*</span>Please ensure the destination
                            address and amount are correct. Once the withdrawal is initiated, it
                            cannot be cancelled or reversed.
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-8">
                <Button variant="yellow" className="w-full" color="primary" onClick={onConfirm}>
                    Sign Withdrawal
                </Button>
            </div>
        </>
    );
};

export default WithdrawalConfirmDialog;
