import { useEffect, useState } from 'react';

import { ReactComponent as IconSpinner } from '../../assets/icons/svg/spinner.svg';
import { eventCloud } from '../../EventCloud';
import { Modal } from '../Modal/Modal';
import { ModalHeader } from '../Modal/ModalHeader';
import { Button } from '../Button/Button';

export const TopConnectionComponent = () => {
    const [live, setLive] = useState<boolean>(true);

    useEffect(() => {
        let isLive = true;

        // Subscribe
        const subscription = eventCloud.isConnected.subscribe(live => {
            // isLive = live;
            // let timeout;
            // if (!live) {
            //   timeout = setTimeout(() => {
            //     setLive(isLive);
            //   }, 2000);
            // } else {
            //   clearTimeout(timeout);
            //   setLive(isLive);
            // }

            setLive(live);
            eventCloud.emit('reloadChart', {});
        });

        // Remember to unsubscribe
        return () => subscription.unsubscribe();
    }, []);

    return !live ? (
        <Modal
            visible={!live}
            onClose={() => {
                setLive(true);
            }}
        >
            <div className="sm:-mt-5 -mt-1">
                <ModalHeader
                    onClose={() => {
                        setLive(true);
                    }}
                >
                    Reconnecting...
                </ModalHeader>
            </div>
            <div className="flex flex-col items-center justify-center text-center text-xxs text-gray-550">
                <IconSpinner className="w-10 h-10 text-yellow-550 my-4" />
                Connection lost…
                <br />
                Reconnecting…
                <div className="mt-4 w-full">
                    <Button
                        variant="gray"
                        size="large"
                        onClick={() => {
                            setLive(true);
                        }}
                        className="w-full"
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </Modal>
    ) : null;
};
