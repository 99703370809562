// ChartResolutionSelector.tsx

import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { eventCloud } from './EventCloud';
import { Asset } from './interfaces/asset.interface';

type Resolution = '1S' | '1' | '5' | '15' | '60' | 'D';

const ChartResolutionSelector: React.FC = () => {
    const [selectedResolution, setSelectedResolution] = useState<Resolution>(
        (localStorage.getItem('dmex.selectedChartResolution') as Resolution) || '1'
    );

    const resolutions: Resolution[] = ['1S', '1', '5', '15', '60', 'D'];
    const resolutionsName = ['1S', '1M', '5M', '15M', '1H', '1D'];

    const [selectedAsset, setSelectedAsset] = useState<Asset | null>(
        eventCloud.selectedAsset.getValue()
    );

    useEffect(() => {
        localStorage.setItem('dmex.selectedChartResolution', selectedResolution);
    }, [selectedResolution]);

    useEffect(() => {
        if (eventCloud.selectedAsset) {
            const assetSubscription = eventCloud.selectedAsset.subscribe(asset => {
                if (asset) {
                    setSelectedAsset(asset);
                }
            });

            return () => {
                assetSubscription.unsubscribe();
            };
        }
    }, []);

    const handleResolutionChange = (resolution: Resolution) => {
        setSelectedResolution(resolution);
        eventCloud.setSelectedResolution(resolution);
        // You can also pass this selection to a parent component or perform other actions here
    };

    if (!selectedAsset) return null;

    return (
        <div className="bg-black bg-opacity-30 pt-0.5 select-none">
            <div className="flex flex-wrap px-1 sm:px-4 h-[33px] items-center border-b border-gray-650 border-r">
                <div className="mr-4 text-xs">{selectedAsset?.symbol.toUpperCase()}</div>
                <span className="text-xs">
                    {resolutions.map((resolution, i) => (
                        <label
                            key={resolution}
                            className={classNames('cursor-pointer px-2 pb-1 text-xs', {
                                'border-b-2 border-white': selectedResolution === resolution,
                                'text-gray-450': selectedResolution !== resolution,
                                'text-white': selectedResolution === resolution,
                            })}
                        >
                            <input
                                type="radio"
                                value={resolution}
                                checked={selectedResolution === resolution}
                                onChange={() => handleResolutionChange(resolution)}
                                className="invisible absolute"
                            />
                            {resolutionsName[i]}
                        </label>
                    ))}
                </span>
            </div>
        </div>
    );
};

const formatPrice = (price: number, decimals: number): string => {
    return (price / 1e8).toFixed(decimals);
};

const formatVolume = (volume: number): string => {
    return Intl.NumberFormat('en-US').format(+volume.toFixed(0));
};

export default ChartResolutionSelector;
