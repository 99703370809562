// Positions.tsx

import { useState, useEffect } from 'react';
import { eventCloud } from './EventCloud';
import { Position } from './interfaces/position.interface';
import { MarginCurrency } from './interfaces/marginCurrency.interface';
import { Asset } from './interfaces/asset.interface';
import { Balance } from './interfaces/balance.interface';
import classNames from 'classnames';
import { Button } from './components/Button/Button';
import { Tooltip } from './components/Tooltip';
import { PositionActions } from './dialogs/PositionActions';
import { formatNumLocale, formatAmount, formatPrice, formatFundingRate } from './helpers/format';
import { calcUPnL, calcRPnL, calcLeverage } from './helpers/formulas';

import UpdateMarginDialog from './dialogs/UpdateMarginDialog';
import TakeProfitStopLossDialog from './dialogs/TakeProfitStopLossDialog';
import './css/Positions.css';
import { Modal } from './components/Modal/Modal';
import { ModalHeader } from './components/Modal/ModalHeader';
import { AssetIcon } from './components/AssetIcon/AssetIcon';
import { ReactComponent as IconChevron } from './assets/icons/svg/chevron.svg';

const Positions = ({ isLite }: { isLite?: boolean }) => {
    const [positions, setPositions] = useState<Position[]>([]);
    const [isUpdateMarginDialogOpen, setIsUpdateMarginDialogOpen] = useState(false);
    const [isTakeProfitStopLossDialogOpen, setIsTakeProfitStopLossDialogOpen] = useState(false);
    const [isTakeProfit, setIsTakeProfit] = useState(false);
    const [selectedPosition, setSelectedPosition] = useState<Position | null>(null);
    const [selectedPositionActionHash, setSelectedPositionActionHash] = useState<string | null>(
        null
    );
    const [userFRMultiplier, setUserFRMultiplier] = useState(1);
    const [marginCurrencies, setMarginCurrencies] = useState<Record<string, MarginCurrency>>({});

    const [balances, setBalances] = useState<Record<string, Balance>>({});

    useEffect(() => {
        const handleNewMarkPrice = (markPrice: any) => {
            setPositions(currentPositions => {
                return currentPositions.map(position => {
                    if (position.asset === markPrice.symbol) {
                        return { ...position, mark_price: Number(markPrice.price) };
                    }
                    return position; 
                });
            });
        };

        const unsubscribeNewMarkPrice = eventCloud.on('newMarkPrice', handleNewMarkPrice);

        const handleNewTrade = (trade: any) => {
            setPositions(currentPositions => {
                return currentPositions.map(position => {
                    if (position.asset === trade.Asset) {
                        return { ...position, last_price: trade.Price };
                    }
                    return position; 
                });
            });
        };

        const unsubscribeNewTrade = eventCloud.on('newTrade', handleNewTrade);

        const handleAssetUpdate = (updatedAssets: Asset[]) => {
            setPositions(currentPositions => {
                return currentPositions.map(position => {
                    const matchingAsset = updatedAssets.find(
                        asset => asset.symbol === position.asset
                    );
                    if (matchingAsset) {
                        // Update funding_rate for the matched position
                        return { ...position, funding_rate: matchingAsset.funding_rate };
                    }
                    return position; // Return the position as is if no match
                });
            });
        };

        const assetUpdateSubscription = eventCloud.assetsStore.subscribe(handleAssetUpdate);

        const subscription = eventCloud.positionsStore.subscribe(positions => {
            if (!positions || positions.length === 0) {
                setPositions([]); // Return early if newAssets is empty
                return;
            }
            setPositions(positions);
        });

        const currencySubscription = eventCloud.marginCurrenciesStore.subscribe(currencies => {
            if (currencies) {
                setMarginCurrencies(currencies);
            }
        });

        const parmasSub = eventCloud.paramsUserStore.subscribe(params => {
            if (params) {
                setUserFRMultiplier(Number(params.fr_multiplier));
            }
        });

        const balanceSubscription = eventCloud.balancesStore.subscribe((newBal) => {
            setBalances(newBal);
        }); 

        return () => {
            unsubscribeNewMarkPrice();
            unsubscribeNewTrade();
            assetUpdateSubscription.unsubscribe();
            subscription.unsubscribe();
            currencySubscription.unsubscribe();
            parmasSub.unsubscribe();
            balanceSubscription.unsubscribe();
        };
    }, []);



    const handleMarketClose = (position: Position) => {
        console.log('[handleMarketClose] ', position);

        navigateToAsset(position.asset);
        eventCloud.emit('changeMarginCurrency', position.base_token);
        eventCloud.emit('marketClose', position);
    };

    const navigateToAsset = (symbol: string) => {
        const asset = eventCloud.findAsset(symbol);
        if (!asset) return;
        const selectedAsset = eventCloud.selectedAsset.getValue();
        if (selectedAsset?.symbol != asset.symbol) {
            eventCloud.setSelectedAsset(asset);
        }
    };

    const handleUpdateMargin = (position: Position) => {
        setSelectedPosition(position);
        setIsUpdateMarginDialogOpen(true);
    };

    const handleCancelUpdateMargin = () => {
        setIsUpdateMarginDialogOpen(false);
    };

    const handleConfirmUpdateMargin = () => {
        setIsUpdateMarginDialogOpen(false);
    };

    const handleTakeProfitStopLoss = (position: Position, isTakeProfit: boolean) => {
        console.log('[handleTakeProfitStopLoss] ', position);

        setIsTakeProfit(isTakeProfit);
        setSelectedPosition(position);
        setIsTakeProfitStopLossDialogOpen(true);
    };

    const handleCancelTakeProfitStopLoss = () => {
        setIsTakeProfitStopLossDialogOpen(false);
    };

    const handleConfirmTakeProfitStopLoss = (price: number, amount: number) => {
        setIsTakeProfitStopLossDialogOpen(false);

        console.log(
            `[handleConfirmTakeProfitStopLoss] isTakeProfit=${isTakeProfit} price=${price} amount=${amount}`
        );
        if (!selectedPosition) return;
        navigateToAsset(selectedPosition.asset);
        eventCloud.emit('changeMarginCurrency', selectedPosition.base_token);
        eventCloud.emit('takeProfitStopLoss', {
            position: selectedPosition,
            isTakeProfit,
            price,
            amount,
        });
    };

    const handleAssetSymbolClick = (position: Position) => {
        console.log('[handleAssetSymbolClick] ', position);

        navigateToAsset(position.asset);
    };

    const handlePositionSizeClick = (position: Position) => {
        console.log('[handlePositionSizeClick] ', position);

        navigateToAsset(position.asset);
        eventCloud.emit('setOrderboxAmount', Number(position.size) / 1e8);
    };

    return (
        <div className="text-xs positions-block-wrapper">
            <h2
                className={classNames(
                    'px-2 py-2 bg-black bg-opacity-30 border-gray-650 font-medium text-xs table-headline',
                    { '!hidden': isLite }
                )}
            >
                Positions
                <span className="text-yellow-550"> [{positions.length}]</span>
            </h2>
            <div
                className={classNames('order-table-wrapper container-scroll positions-table', {
                    'isLite hidden lg:!block': isLite,
                })}
            >
                <table
                    style={{
                        width: '100%',
                        borderCollapse: 'collapse',
                        minWidth: isLite ? '1390px' : '1600px',
                    }}
                    className={classNames('order-table border-gray-250', { isLite: isLite })}
                >
                    <thead>
                        <tr>
                            {!isLite && (
                                <th className="text-left" style={{ width: '50px' }}>
                                    #HASH
                                </th>
                            )}

                            <th className="text-left" style={{ width: '50px' }}>
                                <Tooltip label="Asset" text="Traded asset" />
                            </th>
                            <th className="text-right" style={{ width: '80px' }}>
                                <Tooltip label="Size" text="Size of the position in asset terms" />
                            </th>
                            <th className="text-right" style={{ width: '140px' }}>
                                <Tooltip label="Value" text="Size x Price" />
                            </th>
                            <th className="text-right" style={{ width: '120px' }}>
                                <Tooltip label="Entry Price" text="Average entry price" />
                            </th>
                            <th className="text-right" style={{ width: '120px' }}>
                                <Tooltip
                                    label="Last Price"
                                    text="Last DMEX traded price for asset"
                                />
                            </th>
                            <th className="text-right" style={{ width: '120px' }}>
                                <Tooltip
                                    label="Mark Price"
                                    text="Last Price-Source traded price for asset"
                                />
                            </th>
                            <th className="text-right" style={{ width: '120px' }}>
                                <Tooltip
                                    label="LIQ PRICE"
                                    text="At this price your position will be automatically market closed"
                                />
                            </th>
                            <th className="text-right width-leverage" style={{ width: '70px' }}>
                                <Tooltip label="Leverage" text="Leverage based on current margin" />
                            </th>
                            <th className="text-right" style={{ width: '240px' }}>
                                <Tooltip
                                    label="Margin"
                                    text="Position Margin - Funding Cost + Unrealized Profit/Loss"
                                />
                            </th>
                            <th className="text-right" style={{ width: '170px' }}>
                                <Tooltip label="Unrealized PNL" text="Unrealized Profit/Loss" />
                            </th>
                            <th className="text-right" style={{ width: '150px' }}>
                                <Tooltip
                                    label="Realized PNL"
                                    position="right"
                                    text="Realized Profit/Loss Including Funding Cost"
                                />
                            </th>
                            <th className="text-right" style={{ width: '100px' }}>
                                <Tooltip
                                    label="FUNDING %"
                                    position="right"
                                    text="Current Funding Rate for Asset / 1H"
                                />
                            </th>
                            <th className="text-right" style={{ width: isLite ? '70px' : '70px' }}>
                                ACTIONS
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {positions.map((position, index) => {
                            const upnl = calcUPnL(position, marginCurrencies);
                            const rpnl = calcRPnL(position);

                            position.unrealized_pnl = upnl;
                            position.realized_pnl = rpnl;

                            const network_slug = eventCloud.findMarginCurrency(
                                position.base_token
                            )?.network_slug;

                            return (
                                <tr key={index}>
                                    {!isLite && (
                                        <td className="text-left">
                                            <div>{position.hash.slice(0, 10)}</div>
                                        </td>
                                    )}

                                    <td
                                        className="cursor-pointer select-none "
                                        onClick={() => {
                                            handleAssetSymbolClick(position);
                                        }}
                                    >
                                        <div>
                                            {position.asset.replace(/usd/g, '').toUpperCase()}
                                        </div>
                                    </td>
                                    <td
                                        className={classNames('cursor-pointer', [
                                            position.side
                                                ? 'text-green-550  mr-4 text-xs text-right'
                                                : 'text-red-550  mr-4 text-xs text-right',
                                        ])}
                                        onClick={() => {
                                            handlePositionSizeClick(position);
                                        }}
                                    >
                                        <div>
                                            {formatNumLocale(
                                                Number(position.size) / 1e8,
                                                position.amount_dec
                                            )}
                                        </div>
                                    </td>
                                    <td className="text-right">
                                        <div>
                                            {isLite && '$'}
                                            {formatPrice(Number(position.value), 2)}
                                            {!isLite && ' USD'}
                                        </div>
                                    </td>
                                    <td className="text-right text-yellow-550">
                                        <div>
                                            {isLite && '$'}
                                            {formatPrice(
                                                Number(position.entry_price),
                                                position.decimals
                                            )}
                                            {!isLite && ' USD'}
                                        </div>
                                    </td>
                                    <td className="text-right">
                                        <div>
                                            {isLite && '$'}
                                            {formatPrice(
                                                Number(position.last_price),
                                                position.decimals
                                            )}
                                            {!isLite && ' USD'}
                                        </div>
                                    </td>
                                    <td className="text-gray-450 text-right">
                                        <div>
                                            {isLite && '$'}
                                            {formatPrice(
                                                Number(position.mark_price) * 1e8,
                                                position.decimals
                                            )}
                                            {!isLite && ' USD'}
                                        </div>
                                    </td>
                                    <td className="text-right text-blue-550">
                                        <div>
                                            {isLite && '$'}
                                            {formatPrice(
                                                Number(position.liquidation_price),
                                                position.decimals
                                            )}
                                            {!isLite && ' USD'}
                                        </div>
                                    </td>
                                    <td className="text-right">
                                        <div>
                                            {calcLeverage(
                                                position,
                                                marginCurrencies,
                                                !position.is_cross
                                                    ? 0
                                                    : Number(
                                                          balances[position.base_token]
                                                              ?.crossmargin_profit
                                                      ) / 1e10
                                            )}
                                            x
                                        </div>
                                    </td>
                                    <td className="text-right">
                                        <div>
                                            {formatNumLocale(
                                                !position.is_cross
                                                    ? Number(position.margin) / 1e8
                                                    : Number(position.margin) / 1e8,
                                                position.margin_dec
                                            )}
                                            {' '}
                                            {position.margin_symbol} [{network_slug}]{' '}
                                            {position.is_cross && (
                                                <span className="text-yellow-550"> [CROSS] </span>
                                            )}
                                            {!isLite && (
                                                <Button
                                                    type="button"
                                                    variant="gray"
                                                    size="tiny"
                                                    onClick={() => handleUpdateMargin(position)}
                                                >
                                                    +
                                                </Button>
                                            )}
                                        </div>
                                    </td>
                                    <td
                                        className={classNames([
                                            Number(upnl) >= 0
                                                ? 'text-green-550  mr-4 text-xs text-right'
                                                : 'text-red-550  mr-4 text-xs text-right',
                                        ])}
                                    >
                                        <div>
                                            {formatNumLocale(
                                                Number(formatAmount(upnl, position.margin_dec)),
                                                position.margin_dec
                                            )}
                                            {' '}
                                            {position.margin_symbol} [{network_slug}]
                                        </div>
                                    </td>
                                    <td
                                        className={classNames([
                                            Number(rpnl) >= 0
                                                ? 'text-green-550  mr-4 text-xs text-right'
                                                : 'text-red-550  mr-4 text-xs text-right',
                                        ])}
                                    >
                                        <div>
                                            {formatNumLocale(
                                                Number(formatAmount(rpnl, position.margin_dec)),
                                                position.margin_dec
                                            )}
                                            {' '}
                                            {position.margin_symbol} [{network_slug}]
                                        </div>
                                    </td>
                                  
                                    <td className="text-right">
                                        <div>
                                            {formatFundingRate(
                                                Number(position.funding_rate) * userFRMultiplier
                                            )}
                                            %
                                        </div>
                                    </td>
                                    <td className="text-right">
                                        <div>
                                            <Button
                                                type="button"
                                                variant="gray"
                                                size="small"
                                                onClick={() => {
                                                    setSelectedPositionActionHash(position.hash);
                                                }}
                                            >
                                                MORE
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {positions.length === 0 && (
                    <div
                        className="py-3 text-center text-xs text-gray-450 h-full pb-8 flex justify-center items-center"
                        style={{ height: '80%' }}
                    >
                        No data
                    </div>
                )}
            </div>

            <div className={classNames('hidden', { 'lg:!hidden lg:bg-red-550 block': isLite })}>
                {positions.map((position, index) => {
                    const upnl = calcUPnL(position, marginCurrencies);
                    const rpnl = calcRPnL(position);

                    position.unrealized_pnl = upnl;
                    position.realized_pnl = rpnl;

                    const network_slug = eventCloud.findMarginCurrency(
                        position.base_token
                    )?.network_slug;

                    return (
                        <div
                            key={index}
                            className="border border-gray-250 bg-gray-760 p-3 mb-1 mt-0.5"
                        >
                            <div className="flex justify-between w-full pt-1">
                                <div className="flex items-center">
                                    <div className="w-6">
                                        <AssetIcon symbol={position?.asset} />
                                    </div>
                                    <div className="ml-2">
                                        <div className="flex items-center text-base text-gray-150 font-medium leading-tight">
                                            {position?.asset.toUpperCase().replace(/USD/g, '')}

                                            <div
                                                className={classNames(
                                                    'text-xs px-1 bg-opacity-20 ml-1',
                                                    [
                                                        position.side
                                                            ? 'text-green-550 bg-green-550'
                                                            : 'text-red-550 bg-red-550',
                                                    ]
                                                )}
                                            >
                                                {position.side ? 'LONG' : 'SHORT'}
                                            </div>
                                        </div>

                                        <div className="text-xs text-gray-450 leading-tight">
                                            {calcLeverage(
                                                position,
                                                marginCurrencies,
                                                !position.is_cross
                                                    ? 0
                                                    : Number(
                                                          balances[position.base_token]
                                                              ?.crossmargin_profit
                                                      ) / 1e10
                                            )}
                                            x
                                        </div>
                                    </div>
                                </div>
                                <div className="flex text-xs text-right text-gray-450">
                                    <div>
                                        <Tooltip
                                            label="Unrealized PNL"
                                            text="Unrealized Profit/Loss"
                                        />
                                        <div
                                            className={classNames([
                                                Number(position.unrealized_pnl) >= 0
                                                    ? 'text-green-550 text-xs'
                                                    : 'text-red-550 text-xs',
                                            ])}
                                        >
                                            <div>
                                                {formatNumLocale(
                                                    Number(
                                                        formatAmount(
                                                            position.unrealized_pnl,
                                                            position.margin_dec
                                                        )
                                                    ),
                                                    position.margin_dec
                                                )}{' '}
                                                {position.margin_symbol} [{network_slug}]
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ml-8">
                                        <Tooltip
                                            label="Realized PNL"
                                            text="Realized Profit/Loss (Includes Funding Cost)"
                                        />
                                        <div
                                            className={classNames([
                                                Number(position.realized_pnl) >= 0
                                                    ? 'text-green-550 text-xs'
                                                    : 'text-red-550 text-xs',
                                            ])}
                                        >
                                            <div>
                                                {formatNumLocale(
                                                    Number(
                                                        formatAmount(
                                                            position.realized_pnl,
                                                            position.margin_dec
                                                        )
                                                    ),
                                                    position.margin_dec
                                                )}{' '}
                                                {position.margin_symbol} [{network_slug}]
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-2">
                                <div className="flex justify-between text-gray-450">
                                    <div>
                                        <Tooltip
                                            label="Size"
                                            text="Size of the position in asset terms"
                                        />

                                        <div
                                            className={classNames('text-xs', [
                                                position.side ? 'text-green-550' : 'text-red-550',
                                            ])}
                                        >
                                            <div>
                                                {formatNumLocale(
                                                    Number(position.size) / 1e8,
                                                    position.amount_dec
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex items-center text-right">
                                        <div>
                                            <Tooltip
                                                label="Entry Price"
                                                text="Average entry price"
                                            />
                                            <div className="text-yellow-550">
                                                $
                                                {formatPrice(
                                                    Number(position.entry_price),
                                                    position.decimals
                                                )}{' '}
                                            </div>
                                        </div>
                                        <div className="ml-10">
                                            <Tooltip
                                                label="Mark Price"
                                                text="Last Price-Source traded price for asset"
                                            />
                                            <div>
                                                $
                                                {formatPrice(
                                                    Number(position.mark_price) * 1e8,
                                                    position.decimals
                                                )}
                                            </div>
                                        </div>
                                        <div className="ml-10">
                                            <Tooltip
                                                label="Margin Call"
                                                text="At this price your position will be automatically market closed"
                                            />
                                            <div className="text-blue-550">
                                                $
                                                {formatPrice(
                                                    Number(position.liquidation_price),
                                                    position.decimals
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex mt-4 w-full">
                                <Button
                                    className="flex-1"
                                    type="button"
                                    variant="gray"
                                    size="small"
                                    onClick={() => {
                                        handleTakeProfitStopLoss(position, true);
                                    }}
                                >
                                    TAKE PROFIT
                                </Button>
                                <Button
                                    className="flex-1 ml-2"
                                    type="button"
                                    variant="gray"
                                    size="small"
                                    onClick={() => {
                                        handleTakeProfitStopLoss(position, false);
                                    }}
                                >
                                    STOP LOSS
                                </Button>
                                <Button
                                    className="flex-1 ml-2"
                                    type="button"
                                    variant="gray"
                                    size="small"
                                    onClick={() => {
                                        handleMarketClose(position);
                                    }}
                                >
                                    CLOSE
                                </Button>
                                <Button
                                    className="ml-2 w-6"
                                    type="button"
                                    variant="gray"
                                    size="small"
                                    onClick={() => {
                                        setSelectedPositionActionHash(position.hash);
                                    }}
                                >
                                    <IconChevron className="w-3 text-gray-150 -rotate-90" />
                                </Button>
                            </div>
                        </div>
                    );
                })}

                {positions.length === 0 && (
                    <div
                        className="py-3 text-center text-xs text-gray-450 h-full pb-8 flex justify-center items-center"
                        style={{ height: '80%' }}
                    >
                        No data
                    </div>
                )}
            </div>

            <Modal
                visible={!!selectedPositionActionHash}
                onClose={() => {
                    setSelectedPositionActionHash(null);
                }}
                className="sm:max-w-lg"
            >
                <div className="sm:-mt-5 -mt-1">
                    <ModalHeader
                        onClose={() => {
                            setSelectedPositionActionHash(null);
                        }}
                    >
                        POSITION
                    </ModalHeader>

                    {selectedPositionActionHash && (
                        <PositionActions
                            positions={positions}
                            selectedPositionActionHash={selectedPositionActionHash}
                            marginCurrencies={marginCurrencies}
                            handleTakeProfitStopLoss={handleTakeProfitStopLoss}
                            handleMarketClose={handleMarketClose}
                            handleUpdateMargin={handleUpdateMargin}
                            balances={balances}
                            userFRMultiplier={userFRMultiplier}
                            onClose={() => {
                                setSelectedPositionActionHash(null);
                            }}
                        />
                    )}
                </div>
            </Modal>

            {/* Cancel Order Dialog */}
            <Modal
                visible={isUpdateMarginDialogOpen}
                onClose={handleCancelUpdateMargin}
                freeToClose
            >
                {selectedPosition && (
                    <UpdateMarginDialog
                        onCancel={handleCancelUpdateMargin}
                        position={selectedPosition}
                        onConfirm={handleConfirmUpdateMargin}
                    />
                )}
            </Modal>

            <Modal
                visible={isTakeProfitStopLossDialogOpen}
                onClose={handleCancelTakeProfitStopLoss}
                freeToClose
            >
                {selectedPosition && (
                    <TakeProfitStopLossDialog
                        onCancel={handleCancelTakeProfitStopLoss}
                        position={selectedPosition}
                        onConfirm={handleConfirmTakeProfitStopLoss}
                        asset={selectedPosition.asset.replace(/usd/g, '').toUpperCase()}
                        isTakeProfit={isTakeProfit}
                    />
                )}
            </Modal>
        </div>
    );
};

export default Positions;
