// Positions.tsx

import { useState, useEffect } from 'react';
import { eventCloud } from './EventCloud';
import { Position } from './interfaces/position.interface';
import classNames from 'classnames';
import './css/Positions.css';
import { formatTimestamp } from './helpers/format';

const PositionsClosed = ({ isLite }: { isLite?: boolean }) => {
    const [positions, setPositions] = useState<Position[]>([]);

    useEffect(() => {
        // Subscribe
        const subscription = eventCloud.positionsClosedStore.subscribe(positions => {
            if (!positions || positions.length === 0) {
                setPositions([]); // Return early if newAssets is empty
                return;
            }
            setPositions(positions);
        });

        return () => subscription.unsubscribe();
    }, []);

    return (
        <div className="text-xs positions-block-wrapper">
            <h2
                className={classNames(
                    'px-2 py-2 bg-black bg-opacity-30 border-gray-650 font-medium text-xs table-headline',
                    { '!hidden': isLite }
                )}
            >
                Closed Positions
            </h2>
            <div
                className={classNames('order-table-wrapper container-scroll text-gray-150', {
                    isLite: isLite,
                })}
            >
                <table
                    style={{ width: '100%', borderCollapse: 'collapse' }}
                    className={classNames('order-table', { isLite: isLite })}
                >
                    <thead>
                        <tr>
                            <th className="text-left">Close Time</th>
                            <th className="text-left">Side</th>
                            <th className="text-left">Asset</th>

                            <th className="text-right">Profit/Loss</th>
                            <th className="text-right">Funding Cost</th>
                            <th className="text-right">Net PNL</th>
                        </tr>
                    </thead>
                    <tbody>
                        {positions.map((position, index) => {
                            const pnl = formatPnL(position);
                            const netpnl = formatAmount(
                                position.liquidated
                                    ? Number(pnl) * 1e8
                                    : Number(pnl) * 1e8 -
                                          Number(position.funding_cost) -
                                          Number(position.ur_funding_cost),
                                position.margin_dec
                            );
                            return (
                                <tr key={index}>
                                    <td className="text-left">
                                        <div>{formatTimestamp(position.created_at)}</div>
                                    </td>
                                    <td
                                        className={classNames([
                                            position.side
                                                ? 'text-green-550 text-left'
                                                : 'text-red-550 text-left',
                                        ])}
                                    >
                                        <div
                                            className={classNames(
                                                'inline-block text-xs px-1 bg-opacity-20 ml-1',
                                                [
                                                    position.side
                                                        ? 'text-green-550 bg-green-550'
                                                        : 'text-red-550 bg-red-550',
                                                ]
                                            )}
                                        >
                                            {position.side ? 'LONG' : 'SHORT'}
                                        </div>
                                    </td>
                                    <td className="text-left">
                                        <div>
                                            {position.asset.replace(/usd/g, '').toUpperCase()}
                                        </div>
                                    </td>
                                    <td
                                        className={classNames([
                                            Number(pnl) >= 0
                                                ? 'text-green-550 text-right'
                                                : 'text-red-550 text-right',
                                        ])}
                                    >
                                        <div>
                                            {pnl} {position.margin_symbol} [
                                            {
                                                eventCloud.findMarginCurrency(position.base_token)
                                                    ?.network_slug
                                            }
                                            ]
                                        </div>
                                    </td>
                                    <td className="text-right text-red-550">
                                        <div>
                                            -
                                            {formatAmount(
                                                Number(position.funding_cost) +
                                                    Number(position.ur_funding_cost),
                                                position.margin_dec
                                            )}{' '}
                                            {position.margin_symbol} [
                                            {
                                                eventCloud.findMarginCurrency(position.base_token)
                                                    ?.network_slug
                                            }
                                            ]
                                        </div>
                                    </td>
                                    <td
                                        className={classNames([
                                            Number(netpnl) > 0
                                                ? 'text-green-550 text-right'
                                                : 'text-red-550 text-right',
                                        ])}
                                    >
                                        <div>
                                            {netpnl} {position.margin_symbol} [
                                            {
                                                eventCloud.findMarginCurrency(position.base_token)
                                                    ?.network_slug
                                            }
                                            ]
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                {positions.length === 0 && (
                    <div
                        className="py-3 text-center text-xs text-gray-450 h-full pb-8 flex justify-center items-center"
                        style={{ height: '80%' }}
                    >
                        No data
                    </div>
                )}
            </div>

            {/* Cancel Order Dialog */}
            {/*<CancelOrderDialog
                open={isCancelDialogOpen}
                onClose={handleCancelClose}
                orderHash={selectedOrderHash}
                onConfirm={handleConfirmCancellation}
            />*/}
        </div>
    );
};


const formatAmount = (amount: number, amount_dec: number): string => {
    return (amount / 1e8).toFixed(amount_dec);
};

const formatPnL = (position: Position): string => {
    if (position.liquidated) {
        return (-Number(position.loss) / 1e8).toFixed(position.margin_dec);
    }

    var value = Number(position.profit) - Number(position.loss);

    return (Number(value) / 1e8).toFixed(position.margin_dec);
};



export default PositionsClosed;
