// CancelOrder.ts

import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { eventCloud } from './../EventCloud';
import { Wallet } from '../interfaces/wallet.interface';
import { Sign } from './Sign';

export const handleCancelOrder = async (order_hash: string) => {
    console.log('[handleCancelOrder] order_hash=', order_hash);

    const selectedWallet = eventCloud.selectedWallet.getValue();
    if (!selectedWallet) return;

    const signatureResult = await Sign(order_hash, selectedWallet);

    // Check if the signatureResult is null
    if (!signatureResult) {
        console.error('Signature process was not completed.');
        return false;
    }

    const { v, r, s } = signatureResult;

    eventCloud.cancelOrder({
        order_hash,
        v,
        r,
        s,
    });
};

export default handleCancelOrder;
