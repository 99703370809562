// OrderBox.tsx

import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

import { eventCloud } from '../../../EventCloud';
import ConfirmOrderDialog from '../../../dialogs/ConfirmOrderDialog'; // Import the dialog component
import RiskLimitConfirmDialog from '../../../dialogs/RiskLimitConfirmDialog'; // Import the dialog component
import '../../../css/OrderBox.css';

import { Asset } from '../../../interfaces/asset.interface';
import { MarginCurrency } from '../../../interfaces/marginCurrency.interface';
import { Position } from '../../../interfaces/position.interface';
import { Button } from '../../../components/Button/Button';
import { Modal } from '../../../components/Modal/Modal';
import { SwitchField } from '../../../components/SwitchField/SwitchField';
import { Balance } from '../../../interfaces/balance.interface';
import { formatNotificationMessage } from '../../../helpers/notificationHelpers';
import { Tooltip } from '../../../components/Tooltip';

import { handleCreateOrder } from '../../../solidity/CreateOrder';
import { handleChangeRiskLimit } from '../../../solidity/ChangeRiskLimit';
import { AssetIcon } from '../../../components/AssetIcon/AssetIcon';
import { ReactComponent as IconChevron } from '../../../assets/icons/svg/chevron.svg';

import { formatLiqPrice, formatOrderValue, formatFundingRateMultiplier, formatNumLocale, formatFee, formatPriceRaw, formatAmountRaw, formatPrice } from '../../../helpers/format';
import { calcMarketPrice, calcMargin, calcMultiplier } from '../../../helpers/formulas';

const OrderBoxLite = ({ setOpenAssetModal }: { setOpenAssetModal?: (open: boolean) => void }) => {
    const [isRiskLimitDialogOpen, setIsRiskLimitDialogOpen] = useState(false);
    const [oldRiskLimit, setOldRiskLimit] = useState(0);
    const [orderType, setOrderType] = useState('Market');
    const [orderSide, setOrderSide] = useState(true);
    const [amount, setAmount] = useState('');
    const [price, setPrice] = useState('');
    const [assetLastPrice, setAssetLastPrice] = useState('');
    const [stopPrice, setStopPrice] = useState('');
    const [riskLimit, setRiskLimit] = useState<number | undefined>(50);
    const [userMMMultiplier, setUserMMMultiplier] = useState(1);
    const [userFRMultiplier, setUserFRMultiplier] = useState(1);
    const [leverage, setLeverage] = useState(localStorage.getItem('dmex.leverageSelector') || '0');
    const [slippage, setSlippage] = useState(
        localStorage.getItem('dmex.maxSlippageSelector') || '0.05'
    );
    const [isReduceOnly, setIsReduceOnly] = useState(
        localStorage.getItem('dmex.isReduceOnlyCheckbox') == 'true' || false
    );
    const [isAutoConfirm, setIsAutoConfirm] = useState(
        localStorage.getItem('dmex.isAutoConfirmOrderCheckbox') == 'true' || false
    );
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [orderAction, setOrderAction] = useState('');
    const [fundingRate, setFundingRate] = useState('');
    const [nextFundingTime, setNextFundingTime] = useState('');

    const [selectedAsset, setSelectedAsset] = useState<Asset | null>(
        eventCloud.selectedAsset.getValue()
    );

    const [selectedMarginCurrency, setSelectedMarginCurrency] = useState<MarginCurrency | null>(
        eventCloud.getMarginCurrencyObject()
    );

    const [balances, setBalances] = useState<Record<string, Balance>>({});

    const prevSelectedAssetRef = useRef<Asset | null>(null);

    useEffect(() => {
        const subscription = eventCloud.balancesStore.subscribe(newBal => {
            setBalances(newBal);
        });

        // Clean up the subscription
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        localStorage.setItem('dmex.isAutoConfirmOrderCheckbox', isAutoConfirm.toString());
    }, [isAutoConfirm]);
    useEffect(() => {
        localStorage.setItem('dmex.isReduceOnlyCheckbox', isReduceOnly.toString());
    }, [isReduceOnly]);
    useEffect(() => {
        localStorage.setItem('dmex.maxSlippageSelector', slippage.toString());
    }, [slippage]);
    useEffect(() => {
        localStorage.setItem('dmex.leverageSelector', leverage.toString());
    }, [leverage]);

    useEffect(() => {
        const balanceSubscription = eventCloud.balancesStore.subscribe(newBal => {
            setBalances(newBal);
        });

        const assetSubscription = eventCloud.selectedAsset.subscribe(asset => {
            if (asset) {
                setSelectedAsset(asset);
                setFundingRate(asset.funding_rate);
            }
        });

        const currencySubscription = eventCloud.marginCurrenciesStore.subscribe(() => {
            setSelectedMarginCurrency(eventCloud.getMarginCurrencyObject());
        });

        const parmasSub = eventCloud.paramsUserStore.subscribe(params => {
            //console.log("[orderbox] paramsUserStore ", params);
            if (params) {
                setRiskLimit(Number(params.risk_limit));
                setOldRiskLimit(Number(params.risk_limit));
                setUserMMMultiplier(Number(params.mm_multiplier));
                setUserFRMultiplier(Number(params.fr_multiplier));
            }
        });

       
        const interval = setInterval(() => {
            const now = new Date();
            const nextHour = new Date(now);
            nextHour.setHours(now.getHours() + 1);
            nextHour.setMinutes(0);
            nextHour.setSeconds(0);

            const diff = nextHour.getTime() - now.getTime();
            const minutes = Math.floor(diff / 1000 / 60);
            const seconds = Math.floor((diff / 1000) % 60);

            setNextFundingTime(
                `${minutes.toString().padStart(2, '0')}M ${seconds.toString().padStart(2, '0')}S`
            );
        }, 1000);
        

        return () => {
            balanceSubscription.unsubscribe();
            assetSubscription.unsubscribe();
            currencySubscription.unsubscribe();
            parmasSub.unsubscribe();
            clearInterval(interval);
        }
    }, []);



    useEffect(() => {
        const handleNewTrade = (trade: any) => {
            if (!selectedAsset) return;
            if (trade.Asset == selectedAsset.symbol) {
                //console.log("[OrderBox: handleNewTrade] ", trade);
                setAssetLastPrice((Number(trade.Price) / 1e8).toString());
            }
        };

        const unsubscribeNewTrade = eventCloud.on('newTrade', handleNewTrade);

        const handleNewFundingRate = (fr: any) => {
            console.log('New funding rate', fr, selectedAsset);
            if (!selectedAsset) return;
            if (fr.symbol == selectedAsset.symbol) {
                //console.log("[OrderBox: handleNewFundingRate] ", fr);
                setFundingRate(fr.funding_rate);
            }
        };

        const unsubscribeNewFr = eventCloud.on('newFundingRate', handleNewFundingRate);

        const handleMarketClose = (position: Position) => {
            console.log('handleMarketClose', position, selectedAsset?.decimals);
            if (!selectedAsset?.notional) return;
            setOrderType('Market');
            setAmount((Number(position.size) / 1e8).toString());
            setIsReduceOnly(true);

            if (position.side) {
                setOrderAction('Sell');
                setPrice(
                    calcMarketPrice(
                        Number(assetLastPrice),
                        selectedAsset?.notional,
                        -Number(slippage),
                        selectedAsset?.decimals
                    )
                );
            } else {
                setOrderAction('Buy');
                setPrice(
                    calcMarketPrice(
                        Number(assetLastPrice),
                        selectedAsset?.notional,
                        Number(slippage),
                        selectedAsset?.decimals
                    )
                );
            }

            setIsDialogOpen(true);
        };

        const unsubscribeMarketClose = eventCloud.on('marketClose', handleMarketClose);

        interface TakeProfitStopLossDialogProps {
            position: Position;
            price: number;
            amount: number;
            isTakeProfit: boolean;
        }

        const handleTakeProfitStopLoss = (props: TakeProfitStopLossDialogProps) => {
            const { position, price, amount, isTakeProfit } = props;
            console.log('[handleTakeProfitStopLoss]', position, price, amount, isTakeProfit);
            if (!selectedAsset?.notional) return;

            setAmount((Number(position.size) / 1e8).toString());
            setIsReduceOnly(true);

            if (position.side) {
                setOrderAction('Sell');
                setStopPrice(formatPriceRaw(price, position.decimals) + '');
                setPrice(formatPriceRaw(price, position.decimals) + '');
                setAmount(Number(amount / 1e8) + '');
            } else {
                setOrderAction('Buy');
                setStopPrice(formatPriceRaw(price, position.decimals) + '');
                setPrice(formatPriceRaw(price, position.decimals) + '');
                setAmount(Number(amount / 1e8) + '');
            }

            setIsDialogOpen(true);
            setOrderType('Stop Limit');
        };

        const unsubscribeTakeProfitStopLoss = eventCloud.on(
            'takeProfitStopLoss',
            handleTakeProfitStopLoss
        );

        const handleReplaceOrder = (data: any) => {
            const { order, new_price } = data;
            console.log('handleReplaceOrder', order, new_price);
            handleCreateOrder({
                amount: (Number(order.remaining_amount) / 1e8).toString(),
                price: new_price,
                side: order.side,
                leverage: order.leverage.toFixed(0),
                closing_order: order.closing_order,
                stop: order.stop,
                stop_price: order.stop_price,
                base_token: order.base_token,
                is_market: false,
                replace_hash: order.hash,
            });
        };

        const unsubscribeReplaceOrder = eventCloud.on('replaceOrder', handleReplaceOrder);

        const handlePriceClicked = (price: string) => {
            //console.log(`handlePriceClicked price=${price} orderType=${orderType}`)
            if (orderType != 'Market') {
                setPrice((Number(price) / 1e8).toString());
            }
        };

        const unsubscribePriceClicked = eventCloud.on('priceClicked', handlePriceClicked);

        const handleSetOrderboxAmount = (amount: number) => {
            console.log('[handleSetOrderboxAmount]', amount);
            setAmount(amount.toString());
        };

        const unsubscribeSetorderboxAmount = eventCloud.on(
            'setOrderboxAmount',
            handleSetOrderboxAmount
        );

        return () => {
            unsubscribeNewTrade();
            unsubscribeNewFr();
            unsubscribeMarketClose();
            unsubscribeTakeProfitStopLoss();
            unsubscribeReplaceOrder();
            unsubscribePriceClicked();
            unsubscribeSetorderboxAmount();
        };
    }, [selectedAsset, orderType]);

    
    useEffect(() => {
        if (!selectedAsset) return;
        if (
            prevSelectedAssetRef.current &&
            selectedAsset.symbol !== prevSelectedAssetRef.current.symbol
        ) {
            //setAmount("");
            setPrice('');
        }

        prevSelectedAssetRef.current = selectedAsset;
    }, [selectedAsset]);
 


    if (!selectedAsset) return null;
    const handleBuySellClick = (action: any) => {
        // Convert string values to numbers for comparison
        const numericAmount = Number(amount);
        const stringPrice =
            orderType != 'Market'
                ? price
                : action == 'Buy'
                ? calcMarketPrice(
                      Number(assetLastPrice),
                      selectedAsset?.notional,
                      Number(slippage),
                      selectedAsset?.decimals
                  )
                : calcMarketPrice(
                      Number(assetLastPrice),
                      selectedAsset?.notional,
                      Number(-slippage),
                      selectedAsset?.decimals
                  );

        // Check if amount and price are greater than zero
        if (numericAmount > 0 && Number(stringPrice) > 0) {
            if (orderType == 'Stop Limit' && Number(stopPrice) <= 0) {
                eventCloud.notify(
                    formatNotificationMessage({
                        title: 'TRIGGER PRICE MUST BE GREATER THAN ZERO',
                        message: [
                        ],
                    }),
                    'error',
                    '2500'
                );

                return;
            }

            setOrderAction(action);

            if (!isAutoConfirm) {
                setIsDialogOpen(true);
            } else {
                try {
                    if (!selectedMarginCurrency) return;
                    handleCreateOrder({
                        amount,
                        price: stringPrice,
                        side: action == 'Buy' ? true : false,
                        leverage: (Number(leverage) * 1e8).toString(),
                        closing_order: isReduceOnly,
                        stop: orderType == 'Stop Limit' ? true : false,
                        stop_price:
                            orderType != 'Stop Limit' ? '0' : stopPrice == '' ? '0' : stopPrice,
                        base_token: selectedMarginCurrency?.token_address,
                        is_market: orderType == 'Market',
                        replace_hash: '',
                    });
                } catch (error) {
                    console.error('[handleBuySellClick] Create order failed:', error);
                }
            }
        } else {
            // Handle the error case, e.g., show an alert or set an error state
            if (numericAmount <= 0) {
                eventCloud.notify(
                    formatNotificationMessage({
                        title: 'AMOUNT MUST BE GREATER THAN ZERO',
                        message: [
                            // {
                            //   text: "AMOUNT AND PRICE MUST BE GREATER THAN ZERO",
                            //   bold: false,
                            // },
                        ],
                    }),
                    'error',
                    '2500'
                );
            }

            if (Number(stringPrice) <= 0) {
                eventCloud.notify(
                    formatNotificationMessage({
                        title: 'PRICE MUST BE GREATER THAN ZERO',
                        message: [
                            // {
                            //   text: "AMOUNT AND PRICE MUST BE GREATER THAN ZERO",
                            //   bold: false,
                            // },
                        ],
                    }),
                    'error',
                    '2500'
                );
            }
        }
    };

    const handleBalancePct = (pct: number) => {
        if (!selectedMarginCurrency) return;
        if (!balances[selectedMarginCurrency?.token_address]) return;
        const balance = getAvailableBalance();
        const balPct = balance * (pct / 100);

        const usdBal =
            balPct / Number(calcMultiplier(Number(selectedMarginCurrency?.mark_price))) / 1e10;

        const lev = Number(leverage) > 0 ? Number(leverage) : 1000;

        const p =
            orderType == 'Market'
                ? ((Number(selectedAsset?.last_price) / 1e8) * (100 + Number(slippage))) / 100
                : Number(price);
        let amount = (usdBal * lev) / p;

        // Round down to the nearest selectedAsset.amount_notional
        const notional = selectedAsset?.amount_notional;
        if (notional) {
            amount = Math.floor(amount / notional) * notional;
        }

        if (amount < 0) {
            amount = 0;
        }

        setAmount(amount.toFixed(selectedAsset?.amount_dec));
        console.log(
            `[handleBalancePct] pct=${pct} balance=${balance} balPct=${balPct} usdBal=${usdBal} amount=${amount} price=${price}`
        );
    };

    const handleConfirm = () => {
        // Handle order confirmation logic here
        setIsDialogOpen(false);
        setIsReduceOnly(false);
    };

    const handleCancel = () => {
        setIsDialogOpen(false);
        setIsReduceOnly(false);
    };

    const handleRiskLimitChange = (newRiskLimit?: number) => {
        if (!newRiskLimit) return;
        if (Number(newRiskLimit) == Number(setOldRiskLimit)) return;
        setOldRiskLimit(Number(riskLimit));
        setRiskLimit(newRiskLimit);
        setIsRiskLimitDialogOpen(true);
    };

    const handleRiskLimitDialogConfirm = async () => {
        var result = await handleChangeRiskLimit(String(riskLimit));
        if (result) {
            setRiskLimit(riskLimit);
            setIsRiskLimitDialogOpen(false);
        }
    };

    const handleRiskLimitDialogClose = () => {
        setRiskLimit(Number(oldRiskLimit));
        setIsRiskLimitDialogOpen(false);
    };

    const getAvailableBalance = (): number => {
        if (!selectedMarginCurrency || !balances[selectedMarginCurrency?.token_address]) return 0;
        return (
            Number(balances[selectedMarginCurrency?.token_address].available_balance) -
            Number(balances[selectedMarginCurrency?.token_address].crossmargin_reserve)
        );
    };

    const getLiquidationPrice = (): string => {
        return formatNumLocale(
            Number(
                formatLiqPrice(
                    Number(assetLastPrice),
                    Number(amount),
                    Number(leverage),
                    Number(selectedAsset?.maintenance_margin),
                    Number(selectedAsset?.decimals),
                    orderSide,
                    Number(userMMMultiplier),
                    eventCloud.params?.global_mm_multiplier,
                    getAvailableBalance()
                )
            ),
            2
        )
    };

    return (
        <div className="relative bg-gray-760 border border-gray-730">
            <div className="flex bg-opacity-30 text-base text-gray-450 sticky top-0 z-10 border-b border-gray-730">
                <input
                    type="radio"
                    id="buy"
                    name="buy"
                    value="Buy"
                    checked={orderSide}
                    onChange={() => setOrderSide(true)}
                    className="invisible absolute"
                />
                <label
                    htmlFor="buy"
                    className={classNames(
                        'px-1 pt-1 pb-1 md:px-2 md:pt-2 md:pb-2 box-border border-r flex-1 border-gray-650 cursor-pointer text-center hover:bg-opacity-20 hover:bg-green-550 text-green-550',
                        {
                            'text-gray-200 border-t-2 border-t-green-550 bg-green-550 bg-opacity-20 hover:bg-green-550 hover:bg-opacity-20':
                                orderSide,
                        }
                    )}
                >
                    <div className="pb-0.5">Buy</div>
                </label>

                <input
                    type="radio"
                    id="sell"
                    name="sell"
                    value="Sell"
                    checked={!orderSide}
                    onChange={() => setOrderSide(false)}
                    className="invisible absolute"
                />
                <label
                    htmlFor="sell"
                    className={classNames(
                        'px-1 pt-1 pb-1 md:px-2 md:pt-2 md:pb-2 box-border flex-1 border-gray-650 cursor-pointer text-center text-red-550 hover:bg-opacity-20 hover:bg-red-550',
                        {
                            'text-gray-200 border-t-2 border-t-red-550 bg-red-550 bg-opacity-20 hover:bg-red-550 hover:bg-opacity-20':
                                !orderSide,
                        }
                    )}
                >
                    <div className="pb-0.5">Sell</div>
                </label>
            </div>

            <div className="p-2 pb-0 md:p-7 md:pb-5 text-xs">
                <div className="bg-black border border-gray-650 p-2 md:px-5 md:py-4">
                    <label
                        htmlFor="amount"
                        className="text-xs text-gray-150 flex justify-between items-center mb-0.5"
                    >
                        <span className="mb-1 mt-1">Amount</span>
                        {Number(leverage) > 0 && (
                            <div className="flex justify-between items-center text-xxs text-gray-450">
                                <button
                                    type="button"
                                    className="border border-gray-650 bg-gray-730 hover:bg-gray-650 p-0 px-0.5 md:p-1 ml-1"
                                    onClick={() => {
                                        handleBalancePct(25);
                                    }}
                                >
                                    25%
                                </button>
                                <button
                                    type="button"
                                    className="border border-gray-650 bg-gray-730 hover:bg-gray-650 p-0 px-0.5 md:p-1 ml-1"
                                    onClick={() => {
                                        handleBalancePct(50);
                                    }}
                                >
                                    50%
                                </button>
                                <button
                                    type="button"
                                    className="border border-gray-650 bg-gray-730 hover:bg-gray-650 p-0 px-0.5 md:p-1 ml-1"
                                    onClick={() => {
                                        handleBalancePct(75);
                                    }}
                                >
                                    75%
                                </button>
                                <button
                                    type="button"
                                    className="border border-gray-650 bg-gray-730 hover:bg-gray-650 p-0 px-0.5 md:p-1 ml-1"
                                    onClick={() => {
                                        handleBalancePct(100);
                                    }}
                                >
                                    100%
                                </button>
                            </div>
                        )}

                        {Number(leverage) == 0 && (
                            <div className="flex justify-between items-center text-xxs text-gray-450">
                                <button
                                    type="button"
                                    className="border border-gray-650 bg-gray-730 hover:bg-gray-650 p-0 px-0.5 md:p-1 ml-1"
                                    onClick={() => {
                                        handleBalancePct(1);
                                    }}
                                >
                                    10X
                                </button>
                                <button
                                    type="button"
                                    className="border border-gray-650 bg-gray-730 hover:bg-gray-650 p-0 px-0.5 md:p-1 ml-1"
                                    onClick={() => {
                                        handleBalancePct(5);
                                    }}
                                >
                                    50X
                                </button>
                                <button
                                    type="button"
                                    className="border border-gray-650 bg-gray-730 hover:bg-gray-650 p-0 px-0.5 md:p-1 ml-1"
                                    onClick={() => {
                                        handleBalancePct(10);
                                    }}
                                >
                                    100X
                                </button>
                                <button
                                    type="button"
                                    className="border border-gray-650 bg-gray-730 hover:bg-gray-650 p-0 px-0.5 md:p-1 ml-1"
                                    onClick={() => {
                                        handleBalancePct(25);
                                    }}
                                >
                                    250X
                                </button>
                            </div>
                        )}
                    </label>
                    <div className="flex items-center justify-between relative ">
                        <input
                            type="number"
                            id="amount"
                            autoComplete="off"
                            value={amount}
                            onChange={e => setAmount(e.target.value)}
                            className="text-base mt-1 md:mt-px md:text-2xl w-full"
                            placeholder="0.00"
                            style={{ paddingRight: '92px' }}
                        />

                        <div className="flex absolute items-center right-px top-1 orderbox-amount-buttons">
                            <div className="text-sm font-semibold text-gray-150 h-3 flex items-center -mt-1 md:mt-0.5">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setOpenAssetModal?.(true);
                                    }}
                                    className="text-sm flex items-center text-gray-150 font-medium border border-gray-650 bg-gray-730 hover:bg-gray-650 p-0.5 md:p-1 "
                                >
                                    <AssetIcon symbol={selectedAsset?.symbol} />
                                    {selectedAsset?.symbol.toUpperCase().replace(/USD/g, '')}
                                    <IconChevron className="w-3 text-gray-150 ml-1" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    <div className="flex justify-between text-gray-150 text-xs">
                        <div className="text-gray-450">Order Value:</div>
                        {formatNumLocale(
                            Number(
                                formatOrderValue(
                                    orderType == 'Market' ? Number(assetLastPrice) : Number(price),
                                    Number(amount)
                                )
                            ),
                            2
                        )}{' '}
                        USD
                    </div>
                    <div className="flex justify-between text-gray-150 text-xs">
                        <div className="text-xs text-gray-450">Margin:</div>
                        {Number(leverage) > 0 &&
                            calcMargin(
                                orderType == 'Market' ? Number(assetLastPrice) : Number(price),
                                Number(amount),
                                Number(leverage),
                                Number(selectedMarginCurrency?.mark_price),
                                Number(selectedMarginCurrency?.decimals)
                            ) +
                                ' ' +
                                selectedMarginCurrency?.symbol}

                        {Number(leverage) == 0 && <span>[CROSS]</span>}
                    </div>
                </div>

                {orderType == 'Market' && !true && (
                    <div className="mt-4 mb-2">
                        <SwitchField
                            label="Max Slippage [%]"
                            value={+slippage}
                            onChange={v => {
                                setSlippage(`${v}`);
                            }}
                            options={[
                                { value: 0.01, label: '0.01' },
                                { value: 0.025, label: '0.025' },
                                { value: 0.05, label: '0.05' },
                                { value: 0.1, label: '0.1' },
                                { value: 0.5, label: '0.5' },
                                { value: 1, label: '1.0' },
                                { value: 2.5, label: '2.5' },
                            ]}
                            large
                            minValue={0.01}
                        />
                    </div>
                )}
                <div className="mt-3 mb-2">
                    <SwitchField
                        label="Leverage [X]"
                        value={+leverage}
                        onChange={v => {
                            setLeverage(`${v}`);
                        }}
                        options={[
                            { value: 0, label: 'CROSS' },
                            { value: 3, label: '3x' },
                            { value: 5, label: '5x' },
                            { value: 10, label: '10x' },
                            { value: 25, label: '25x' },
                            { value: 50, label: '50x' },
                            { value: 100, label: '100x' },
                            { value: 250, label: '250x' },
                            { value: 500, label: '500x' },
                        ]}
                        minValue={3}
                        large
                    />
                </div>

                <div className="flex mt-4">
                    {orderSide ? (
                        <Button
                            variant="green"
                            className="w-full !text-black"
                            size="xl"
                            onClick={() => handleBuySellClick('Buy')}
                        >
                            Buy/Long
                        </Button>
                    ) : (
                        <Button
                            variant="red"
                            className="w-full !text-black"
                            size="xl"
                            onClick={() => handleBuySellClick('Sell')}
                        >
                            Sell/Short
                        </Button>
                    )}
                </div>

                <div className="text-xs mt-6">
                    <div className="flex justify-between text-gray-150 pb-0.5">
                        <div className="text-xs text-gray-450">
                            <Tooltip
                                label="Liquidation Price"
                                text="At this price your position will be automatically market closed."
                            />
                        </div>{' '}
                        {amount == '' ? '--' : getLiquidationPrice()}
                    </div>

                    <div className="flex justify-between text-gray-150 pb-0.5">
                        <div className="text-xs text-gray-450">Fee [maker/taker]:</div>{' '}
                        {formatFee(selectedAsset?.maker_fee)}%/
                        {formatFee(selectedAsset?.taker_fee)}%
                    </div>

                    <div className="flex justify-between text-gray-150 pb-0.5">
                        <div className="text-xs text-gray-450">
                            <Tooltip label="Funding Rate" text="Funding Rate / Hour" />
                        </div>{' '}
                        {formatFundingRateMultiplier(Number(fundingRate), userFRMultiplier)}%/1h
                    </div>

                    <div className="flex justify-between text-gray-150 pb-0.5">
                        <div className="text-xs text-gray-450">
                            <Tooltip label="NEXT FUNDING IN" text="Next Funding Event" />
                        </div>{' '}
                        {nextFundingTime}
                    </div>
                </div>

                <div className="mt-4 text-xs">
                   
                </div>

                <Modal visible={isDialogOpen} onClose={handleCancel} freeToClose>
                    <ConfirmOrderDialog
                        onClose={handleCancel}
                        orderDetails={{
                            type: orderType,
                            amount,
                            price:
                                orderType != 'Market'
                                    ? price
                                    : orderAction == 'Buy'
                                    ? calcMarketPrice(
                                          Number(assetLastPrice),
                                          selectedAsset?.notional,
                                          Number(slippage),
                                          selectedAsset?.decimals
                                      )
                                    : calcMarketPrice(
                                          Number(assetLastPrice),
                                          selectedAsset?.notional,
                                          -Number(slippage),
                                          selectedAsset?.decimals
                                      ),
                            leverage,
                            side: orderAction,
                            is_market: orderType == 'Market',
                            base_token: selectedMarginCurrency?.token_address,
                            closing_order: isReduceOnly,
                            stop_price:
                                orderType != 'Stop Limit' ? '0' : stopPrice == '' ? '0' : stopPrice,
                            formatted_stop_price: formatPriceRaw(
                                Number(stopPrice == '' ? '0' : stopPrice) * 1e8,
                                selectedAsset?.decimals
                            ),
                            isAutoConfirm,
                            liquidation_price:
                                orderAction == 'Buy'
                                    ? formatNumLocale(
                                          Number(
                                              formatLiqPrice(
                                                  orderType == 'Market'
                                                      ? Number(assetLastPrice)
                                                      : Number(price),
                                                  Number(amount),
                                                  Number(leverage),
                                                  Number(selectedAsset?.maintenance_margin),
                                                  Number(selectedAsset?.decimals),
                                                  true,
                                                  Number(userMMMultiplier),
                                                  eventCloud.params?.global_mm_multiplier,
                                                  getAvailableBalance()
                                              )
                                          ),
                                          2
                                      )
                                    : formatNumLocale(
                                          Number(
                                              formatLiqPrice(
                                                  orderType == 'Market'
                                                      ? Number(assetLastPrice)
                                                      : Number(price),
                                                  Number(amount),
                                                  Number(leverage),
                                                  Number(selectedAsset?.maintenance_margin),
                                                  Number(selectedAsset?.decimals),
                                                  false,
                                                  Number(userMMMultiplier),
                                                  eventCloud.params?.global_mm_multiplier,
                                                  getAvailableBalance()
                                              )
                                          ),
                                          2
                                      ),
                            margin: calcMargin(
                                orderType == 'Market' ? Number(assetLastPrice) : Number(price),
                                Number(amount),
                                Number(leverage),
                                Number(selectedMarginCurrency?.mark_price),
                                Number(selectedMarginCurrency?.decimals)
                            ),
                            margin_symbol: selectedMarginCurrency?.symbol,
                            order_value: formatNumLocale(
                                Number(
                                    formatOrderValue(
                                        orderType == 'Market'
                                            ? Number(assetLastPrice)
                                            : Number(price),
                                        Number(amount)
                                    )
                                ),
                                2
                            ),
                            funding_rate: formatFundingRateMultiplier(
                                Number(selectedAsset?.funding_rate),
                                userFRMultiplier
                            ),
                            formatted_amount:
                                formatAmountRaw(Number(amount), selectedAsset?.amount_dec) +
                                ' ' +
                                selectedAsset?.symbol.replace(/usd/g, '').toUpperCase(),
                        }}
                        onConfirm={handleConfirm}
                    />
                </Modal>

                <Modal
                    visible={isRiskLimitDialogOpen}
                    onClose={handleRiskLimitDialogClose}
                    freeToClose
                >
                    <RiskLimitConfirmDialog
                        onClose={handleRiskLimitDialogClose}
                        onConfirm={handleRiskLimitDialogConfirm}
                        newRiskLimit={Number(riskLimit)}
                        oldRiskLimit={oldRiskLimit}
                    />
                </Modal>
            </div>
        </div>
    );
};

export default OrderBoxLite;
