// Assets.tsx

import React, { useEffect, useState, useReducer, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { eventCloud } from '../../../EventCloud';
import { Asset } from '../../../interfaces/asset.interface';
import { formatPriceLocale, formatPriceNum } from '../../../utils/price';
import { BehaviorSubject } from 'rxjs';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../../css/Assets.css';

// Reducer function for assets
export const assetsReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'UPDATE_ASSETS':
            return action.payload;
        case 'UPDATE_ASSET':
            return state.map((asset: Asset) => {
                if (asset.symbol === action.payload.symbol) {
                    const isPriceIncreased = action.payload.last_price >= asset.last_price;
                    // Add a new property to indicate price direction
                    return {
                        ...asset,
                        ...action.payload,
                        price_change_dir: isPriceIncreased ? 'up' : 'down',
                    };
                }
                return asset;
            });
        default:
            return state;
    }
};

interface AssetItemProps {
    asset: Asset;
    handleAssetChange: (asset: Asset) => void;
    onChangeAsset: () => void;
    selectedAsset: Asset | null;
}

const AssetItem = React.memo(
    React.forwardRef<HTMLDivElement, AssetItemProps>(
        ({ asset, handleAssetChange, selectedAsset, onChangeAsset }, ref) => (
            <div ref={ref} key={asset.symbol} className="my-0.5">
                <input
                    type="radio"
                    id={asset.symbol}
                    name="asset"
                    value={asset.symbol}
                    checked={asset.symbol.toUpperCase() === selectedAsset?.symbol.toUpperCase()}
                    onChange={() => {
                        onChangeAsset();
                        handleAssetChange(asset);
                    }}
                    className="invisible absolute"
                />
                <label
                    htmlFor={asset.symbol}
                    className={classNames(
                        'flex cursor-pointer text-xs hover:bg-gray-750 ease-out duration-100',
                        {
                            'bg-gray-750 box-border select-none':
                                asset.symbol.toUpperCase() === selectedAsset?.symbol.toUpperCase(),
                        }
                    )}
                >
                    <div
                        className={classNames(
                            'flex items-center w-3/12 pl-4 pl-2 py-0.5 pr-2 text-xs font-medium select-none'
                        )}
                    >
                        <img
                            src={
                                asset?.symbol === 'wifusd'
                                    ? 'https://s2.coinmarketcap.com/static/img/coins/64x64/28752.png'
                                    : `https://assets.coincap.io/assets/icons/${asset?.symbol
                                          .replace(/tonusd/g, 'toncoin')
                                          .replace(/usd/g, '')}@2x.png`
                            }
                            alt={asset?.symbol}
                            className="w-6 mr-2"
                        />

                        {asset.symbol.replace(/usd/g, '')}
                    </div>

                    <div className={classNames('w-4/12 px-2 py-1 text-right select-none')}>
                        {formatPriceLocale(Number(asset.last_price), asset.decimals)}
                        <br />
                        <span
                            className={classNames([
                                asset.change >= 0 ? 'text-green-550' : 'text-red-550',
                            ])}
                        >
                            {asset.change >= 0 ? '+' : '-'}
                            {Math.abs(asset.change).toFixed(2)}%
                        </span>
                    </div>

                    <div className="block w-5/12  px-2 py-1 text-right">
                        {formatPriceNum(asset.volume, 0)}
                    </div>
                </label>
            </div>
        )
    )
);

const Assets: React.FC<{ onChangeAsset: () => void }> = ({ onChangeAsset }) => {
    const [assets, dispatch] = useReducer(assetsReducer, []);
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);
    const assetRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
    const defaultAssetSymbol = 'btcusd';

    const assetSymbolFromUrl = location.pathname.substring(1).toUpperCase();

    useEffect(() => {
        const subscription = eventCloud.assetsStore.subscribe(newAssets => {
            if (newAssets.length === 0) return;

            dispatch({ type: 'UPDATE_ASSETS', payload: newAssets });
            //console.log("[Assets] Assets updated: ", newAssets);
        });

        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        const subscription = eventCloud.selectedAsset.subscribe(asset => {
            handleAssetChange(asset);
        });

        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        if (assets.length > 0 && !selectedAsset) {
            //console.log("[Assets] selectedAsset null", selectedAsset);
            handleUrlAsset(assets);
        }
    }, [assets]);

    useEffect(() => {
        if (assets.length === 0) return;
        const handleNewTrade = (trade: any) => {
            if (assets.length === 0) return;

            const updatedAssets = assets.map((asset: Asset) => {
                if (asset.symbol.toUpperCase() === trade.Asset.toUpperCase()) {
                    const newVolume = asset.volume + (trade.Price * trade.Amount) / 1e16;
                    let change = 0;
                    if (asset.open !== 0) {
                        change = ((trade.Price / 1e8 - asset.open) / asset.open) * 100;
                    }

                    const updatedAsset = {
                        ...asset,
                        last_price: trade.Price,
                        volume: newVolume,
                        change: change,
                    };
                    dispatch({ type: 'UPDATE_ASSET', payload: updatedAsset });
                }
                return asset;
            });
        };

        const unsubscribeNewTrade = eventCloud.on('newTrade', handleNewTrade);

        return () => {
            unsubscribeNewTrade();
        };
    }, [assets]);

    const handleAssetChange = (selAsset: any) => {
        if (!selAsset) return;
        if (selAsset.symbol.toLowerCase() !== selectedAsset?.symbol.toLowerCase()) {
            //console.log("handleAssetChange ", selAsset);
            setSelectedAsset(selAsset);
            eventCloud.setSelectedAsset(selAsset);
            navigate(`/${selAsset.symbol.toLowerCase()}`);
        }
    };

    // useEffect(() => {
    //     if (selectedAsset && assetRefs.current[selectedAsset.symbol]) {
    //         assetRefs.current[selectedAsset.symbol]?.scrollIntoView({
    //             behavior: 'smooth',
    //             block: 'nearest'
    //         });
    //     }
    // }, [selectedAsset]);

    useEffect(() => {
        if (selectedAsset && assetRefs.current[selectedAsset.symbol]) {
            const element = assetRefs.current[selectedAsset.symbol];
            const container = document.querySelector('.assets-scroll-container-desktop');

            if (element && container) {
                const elementRect = element.getBoundingClientRect();
                const containerRect = container.getBoundingClientRect();

                // Check if the element is within the visible bounds of the container
                const isVisible =
                    elementRect.top >= containerRect.top &&
                    elementRect.bottom <= containerRect.bottom;

                if (!isVisible) {
                    const scrollTop = container.scrollTop;
                    const elementTopWithinContainer =
                        elementRect.top - containerRect.top + scrollTop;
                    const scrollPosition = elementTopWithinContainer - 20; // Adjust '-20' as needed

                    container.scrollTop = scrollPosition;
                }
            }
        }
    }, [selectedAsset]);

    const renderedAssets = useMemo(() => {
        return assets.map((asset: Asset) => (
            <AssetItem
                key={asset.symbol}
                asset={asset}
                handleAssetChange={handleAssetChange}
                selectedAsset={selectedAsset}
                ref={(el: HTMLDivElement | null) => (assetRefs.current[asset.symbol] = el)}
                onChangeAsset={onChangeAsset}
            />
        ));
    }, [assets, handleAssetChange, selectedAsset, onChangeAsset]);

    const handleUrlAsset = (newAssets: Asset[]) => {
        console.log('[handleUrlAsset] newAssets ', newAssets);
        let assetSymbolFromUrl = location.pathname.substring(1).toUpperCase();
        console.log('assetSymbolFromUrl', assetSymbolFromUrl, selectedAsset?.symbol);
        if (assetSymbolFromUrl === '') {
            assetSymbolFromUrl = defaultAssetSymbol;
        }
        // console.log(
        //     "[handleUrlAsset] assetSymbolFromUrl ",
        //     location.pathname,
        //     assetSymbolFromUrl
        // );

        const assetExists = newAssets.find(
            asset => asset.symbol.toUpperCase() === assetSymbolFromUrl.toUpperCase()
        );

        if (assetExists) {
            //console.log("[handleUrlAsset] assetExists ", assetExists, selectedAsset);
            if (assetExists.symbol.toUpperCase() === selectedAsset?.symbol.toUpperCase()) return;

            if ((assetExists && !selectedAsset) || selectedAsset?.symbol !== assetExists.symbol) {
                //console.log("[Assets] setSelectedAsset assetExists", assetExists)
                setSelectedAsset(assetExists);

                eventCloud.setSelectedAsset(assetExists);
                //console.log("[Assets] setSelectedAsset setSelectedAsset", selectedAsset)
            } else {
                const defaultAsset = newAssets.find(
                    newAsset => newAsset.symbol.toLowerCase() === defaultAssetSymbol
                );

                if (!defaultAsset) {
                    console.log('[handleUrlAsset] No asset found');
                } else {
                    //console.log("[handleUrlAsset] set default asset ", defaultAsset, selectedAsset);
                    setSelectedAsset(defaultAsset);
                    eventCloud.setSelectedAsset(defaultAsset);
                }
            }
        } else {
            navigate('/' + defaultAssetSymbol);
        }
    };

    return (
        <div className="assets-container w-full text-xs self-stretch ">
            <form className="flex flex-col h-full">
                <div className="flex text-gray-450 text-xs">
                    <div className="w-3/12 pl-4 py-1 pr-2">SYMBOL</div>
                    <div className="w-4/12 py-1 p-2 text-right">PRICE/USD</div>
                    <div className="w-5/12 py-1 p-2 text-right">24 VOLUME/USD</div>
                </div>

                <div className="overflow-y-scroll">
                    <div className="block">{renderedAssets}</div>
                </div>
            </form>
        </div>
    );
};

export default Assets;
