import classNames from 'classnames';
import { forwardRef, PropsWithoutRef, ReactNode, useState } from 'react';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { LinkIcon } from '../LinkIcon/LinkIcon';
import { CopyToClipboard } from 'react-copy-to-clipboard';

type Size = 'tiny' | 'small' | 'smallx' | 'medium' | 'large' | 'xl';

type Variant = 'gray' | 'green' | 'red' | 'redblack' | 'yellow' | 'empty';

export interface ButtonProps
    extends Omit<PropsWithoutRef<JSX.IntrinsicElements['button']>, 'className'> {
    size?: Size;
    rounded?: string;
    copy?: string;
    variant?: Variant;
    loading?: boolean;
    spinner?: boolean;
    className?: string;
    iconLeft?: ReactNode;
    disabled?: boolean;
    link?: string;
}

const sizeClassNames: Record<Size, string> = {
    tiny: 'pl-1 pr-1 text-xxxs select-none',
    small: 'px-1 py-0.5 text-xxs leading-4 select-none',
    smallx: 'px-2 py-1 sm:px-4 sm:py-1.5 text-xs select-none',
    medium: 'px-2 py-1 sm:px-4 sm:py-2 text-xs select-none',
    large: 'px-4 py-2 text-sm font-medium select-none',
    xl: 'px-4 py-3 text-base font-semibold select-none',
};

const variantClassNames: Record<Variant, string> = {
    empty: 'text-gray-150 hover:text-white disabled:text-gray-450 border-none active:top-px',
    gray: 'text-gray-150 hover:text-white disabled:text-gray-450 bg-gray-750 hover:bg-gray-740 border border-gray-650 active:top-px',
    green: 'border-transparent text-white bg-green-550 hover:bg-green-540 disabled:opacity-80 active:top-px',
    red: 'border-transparent text-white bg-red-550 hover:bg-red-540 active:top-px',
    redblack: 'border-transparent text-black font-bold bg-red-550 hover:bg-red-540 active:top-px',
    yellow: 'border-transparent text-black bg-yellow-550 disabled:text-gray-450 disabled:bg-gray-750 hover:bg-yellow-540',
};

export const getButtonClassNames = ({
    size = 'medium',
    variant = 'gray',
    loading,
    rounded = '',
    className,
    disabled,
}:
    | (Pick<ButtonProps, 'size' | 'variant' | 'rounded'> &
          Pick<ButtonProps, 'loading' | 'disabled'> &
          Pick<ButtonProps, 'className'>)
    | undefined = {}) =>
    classNames(
        'inline-flex items-center border font-medium justify-center shadow-sm transition-colors relative',
        className,
        {
            'cursor-wait': loading,
            'disabled:cursor-not-allowed': disabled,
        },
        rounded,
        sizeClassNames[size],
        variantClassNames[variant],
        !disabled && 'active:top-px'
    );

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            children,
            size,
            className,
            variant,
            rounded,
            loading,
            spinner: withSpinner,
            copy,
            iconLeft,
            disabled,
            link,
            ...props
        },
        ref
    ) => {
        const [isCopied, setIsCopied] = useState(false);

        const handleIconClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
            event.stopPropagation(); // Prevent button click event when clicking the icon
        };

        return (
            <button
                ref={ref}
                {...props}
                className={getButtonClassNames({
                    rounded,
                    className,
                    size,
                    variant,
                    loading,
                    disabled,
                })}
                disabled={disabled}
            >
                {copy ? (
                    <CopyToClipboard
                        text={copy}
                        onCopy={() => {
                            setIsCopied(true);
                            setTimeout(() => {
                                setIsCopied(false);
                            }, 1000);
                        }}
                    >
                        <div className="w-full">{isCopied ? '👍' : children}</div>
                    </CopyToClipboard>
                ) : (
                    <>
                        {iconLeft && iconLeft}

                        {children}
                        {link && (
                            <a
                                href={link}
                                onClick={handleIconClick}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="inline-flex items-center ml-2"
                            >
                                <LinkIcon className="h-4 w-4 text-yellow-550 mr-2" />
                            </a>
                        )}
                        {loading && (
                            <span className="inline-block ml-2">
                                <LoadingSpinner className="w-3 h-3" />
                            </span>
                        )}
                    </>
                )}
            </button>
        );
    }
);
