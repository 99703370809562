// EventCloud.ts

import { BehaviorSubject } from 'rxjs';
import { JsonValue } from './useWorkerWebSocket';
import { toast } from 'react-toastify';
import { ReactNode } from 'react';

import { formatNotificationMessage } from './helpers/notificationHelpers';
import { deserializeMsg } from './helpers/deserializeMsg';

import { ObConcise } from './interfaces/obConcise.interface';
import { TradeConcise } from './interfaces/tradeConcise.interface';
import { Trade } from './interfaces/trade.interface';
import { Candle } from './interfaces/candle.interface';
import { Asset } from './interfaces/asset.interface';
import { Wallet } from './interfaces/wallet.interface';
import { MarginCurrency } from './interfaces/marginCurrency.interface';
import { Order } from './interfaces/order.interface';
import { Position } from './interfaces/position.interface';
import { Balance } from './interfaces/balance.interface';
import { Fund } from './interfaces/fund.interface';
import { Params } from './interfaces/params.interface';
import { ParamsUser } from './interfaces/paramsUser.interface';
import { News } from './interfaces/news.interface';

class EventCloud {
    private worker: Worker | null = null;
    private readyState: boolean = false;
    public isConnected: BehaviorSubject<boolean>;
    public uniqueUserId: string;

    public chartStreamingCallback: ((lastTrade: any) => void) | null = null;

    public selectedWallet: BehaviorSubject<Wallet | null>;
    public balance: BehaviorSubject<Balance | null>;
    public paramsStore: BehaviorSubject<Params | null>;
    public paramsUserStore: BehaviorSubject<ParamsUser | null>;
    public selectedMarginCurrency: BehaviorSubject<string>;
    public selectedAsset: BehaviorSubject<Asset | null>;
    public orderBookStore: BehaviorSubject<ObConcise | null>;
    public tradesStore: BehaviorSubject<TradeConcise[]>;
    public tradesUserStore: BehaviorSubject<Trade[]>;
    public candlesStore: BehaviorSubject<Candle[]>;
    public assetsStore: BehaviorSubject<Asset[]>;
    public marginCurrenciesStore: BehaviorSubject<Record<string, MarginCurrency>>;
    public ordersStore: BehaviorSubject<Order[]>;
    public ordersClosedStore: BehaviorSubject<Order[]>;
    public positionsStore: BehaviorSubject<Position[]>;
    public positionsClosedStore: BehaviorSubject<Position[]>;
    public balancesStore: BehaviorSubject<Record<string, Balance>>;
    public fundsStore: BehaviorSubject<Fund[]>;
    public newsStore: BehaviorSubject<News[]>;

    //public marginCurrencies: { [key: string]: any };
    public params: { [key: string]: any };
    public selectedChartResolution: BehaviorSubject<string>;

    constructor() {
        this.isConnected = new BehaviorSubject<boolean>(false);
        this.uniqueUserId = this.generateUniqueUserId();

        this.selectedWallet = new BehaviorSubject<Wallet | null>(null);
        this.balance = new BehaviorSubject<Balance | null>(null);
        this.paramsStore = new BehaviorSubject<Params | null>(null);
        this.paramsUserStore = new BehaviorSubject<ParamsUser | null>(null);
        this.selectedAsset = new BehaviorSubject<Asset | null>(null);
        this.orderBookStore = new BehaviorSubject<ObConcise | null>(null);
        this.tradesStore = new BehaviorSubject<TradeConcise[]>([]);
        this.tradesUserStore = new BehaviorSubject<Trade[]>([]);
        this.candlesStore = new BehaviorSubject<Candle[]>([]);
        this.assetsStore = new BehaviorSubject<Asset[]>([]);
        this.marginCurrenciesStore = new BehaviorSubject<Record<string, MarginCurrency>>({});
        this.ordersStore = new BehaviorSubject<Order[]>([]);
        this.ordersClosedStore = new BehaviorSubject<Order[]>([]);
        this.positionsStore = new BehaviorSubject<Position[]>([]);
        this.positionsClosedStore = new BehaviorSubject<Position[]>([]);
        this.balancesStore = new BehaviorSubject<Record<string, Balance>>({});
        this.fundsStore = new BehaviorSubject<Fund[]>([]);
        this.newsStore = new BehaviorSubject<News[]>([]);
        this.selectedChartResolution = new BehaviorSubject<string>(
            localStorage.getItem('dmex.selectedChartResolution') || '1'
        );
        this.selectedMarginCurrency = new BehaviorSubject<string>(
            localStorage.getItem('selectedCurrency') || '0x0000000000000000000000000000000000000003'
        );

        this.params = {};

        this.initializeWebSocketWorker();
    }

    private generateUniqueUserId(): string {
        const existingId = localStorage.getItem('dmex_firstLoginTime');

        if (!existingId) {
            const uniqueId = Date.now().toString(); // Generate a unique integer based on the current timestamp
            localStorage.setItem('dmex_firstLoginTime', uniqueId);
            return uniqueId;
        }

        return existingId;
    }

    private handleWebSocketMessage(type: string, data: any) {
        switch (type) {
            case 'assets':
                // Assume data is an array of Asset objects
                //console.log("assets: ", data);
                this.assetsStore.next(data);

                this.updateSelectedAsset(data);
                break;

            case 'asset':
                //console.log("Updated asset: ", data);
                const updatedAsset = data;

                // Get the current state of the assets
                const currentAssets = this.assetsStore.getValue();

                // Find the index of the asset to be updated
                const assetIndex = currentAssets.findIndex(
                    asset => asset.symbol === updatedAsset.symbol
                );

                if (assetIndex !== -1) {
                    // Asset found, update it
                    const newAssets = [...currentAssets];
                    newAssets[assetIndex] = updatedAsset;
                    this.assetsStore.next(newAssets);

                    if (updatedAsset.symbol == this.selectedAsset.getValue()?.symbol) {
                        this.selectedAsset.next(updatedAsset);
                    }
                } else {
                    // Asset not found, add it to the array
                    this.assetsStore.next([...currentAssets, updatedAsset]);
                }
                break;

            case 'balances':
                // Assume data is an array of Asset objects
                //console.log("new balances: ", data);
                this.balancesStore.next(data);
                this.updateBalance();
                break;

            case 'candles':
                //console.log("New candles: ", data);
                this.candlesStore.next(JSON.parse(data));
                break;

            case 'funding_rate':
                //console.log("New funding_rate: ", data);
                // Prepend the new trade to the start of the trades array
                this.emit('newFundingRate', data); // Emit new trade event
                break;

            case 'fund':
                //console.log("New position: ", data);
                const updatedItem = data;

                var currentItems = this.fundsStore.getValue();

                if (!Array.isArray(currentItems)) {
                    currentItems = [];
                }

                // Find the index of the asset to be updated
                const itemIndex = currentItems.findIndex(
                    item => item.tx_hash === updatedItem.tx_hash
                );

                this.emit('newFund', updatedItem);

                if (itemIndex !== -1) {
                    var newItems = [...currentItems];
                    newItems[itemIndex] = updatedItem;
                    this.fundsStore.next(newItems);
                } else {
                    // Asset not found and size is not "0", add it to the array
                    this.fundsStore.next([updatedItem, ...currentItems]);
                }
                break;

            case 'funds':
                //console.log("New funds: ", data);
                this.fundsStore.next(data);
                break;

            case 'margin_currencies':
                //console.log("New margin_currencies: ", data);
                this.marginCurrenciesStore.next(data);
                //this.updateMarginCurrencyObject()
                break;

            case 'mark_price':
                //console.log("New mark price: ", data);
                this.emit('newMarkPrice', data); // Emit new trade event
                this.updateMarkPrice(data);
                break;

            case 'news':
                //console.log("News: ", data);
                this.newsStore.next(data);
                break;

            case 'notification':
                //console.log("New notification: ", data);

                this.notify(formatNotificationMessage(data), data.type, data.delay);

                break;

            case 'ob':
                //console.log("New ob: ", data);
                this.orderBookStore.next(data);
                break;

            case 'order':
                //console.log("Updated asset: ", data);
                const updatedData = data;

                // Check if the order is closed
                if (updatedData.closed === true) {
                    // Get the current state of the orders and ordersClosed
                    const currentOrders = this.ordersStore.getValue() || [];
                    const currentOrdersClosed = this.ordersClosedStore.getValue() || [];

                    // Remove the closed order from orders
                    const newOrders = currentOrders.filter(
                        order => order.hash !== updatedData.hash
                    );

                    // Add the closed order at the top of ordersClosed
                    const newOrdersClosed = [updatedData, ...currentOrdersClosed];

                    if (newOrdersClosed.length > 100) {
                        newOrdersClosed.length = 100; // This effectively trims the array to the first 100 elements
                    }

                    // Update stores
                    this.ordersStore.next(newOrders);
                    this.ordersClosedStore.next(newOrdersClosed);
                } else {
                    // Get the current state of the assets
                    const currentData = this.ordersStore.getValue() || [];

                    // Find the index of the asset to be updated
                    const dataIndex = currentData.findIndex(data => data.hash === updatedData.hash);

                    if (dataIndex !== -1) {
                        // order exists
                        const newData = [...currentData];
                        newData[dataIndex] = updatedData;
                        this.ordersStore.next(newData);
                    } else {
                        // new order
                        this.ordersStore.next([...currentData, updatedData]);
                    }
                }
                break;

            case 'orders':
                //console.log("New orders: ", data);
                this.ordersStore.next(data);
                break;

            case 'orders_closed':
                //console.log("New orders_closed: ", data);
                this.ordersClosedStore.next(data);
                break;

            case 'params':
                console.log('New params: ', data);
                this.params = data;
                this.paramsStore.next(data);
                break;

            case 'params_user':
                //console.log("New params_user: ", data)
                this.paramsUserStore.next(data);
                break;

            case 'position':
                //console.log("New position: ", data);
                const updatedPosition = data;

                var currentPositions = this.positionsStore.getValue();

                if (!Array.isArray(currentPositions)) {
                    currentPositions = [];
                }

                // Find the index of the asset to be updated
                const posIndex = currentPositions.findIndex(
                    position => position.hash === updatedPosition.hash
                );

                if (updatedPosition.size == 0) {
                    // If size is "0", prepare the position for positionsClosed
                    updatedPosition.created_at = new Date().toISOString();

                    // If size is "0", remove it from the positions array and add to positionsClosed
                    const newPositions = currentPositions.filter((_, index) => index !== posIndex);
                    const currentPositionsClosed = this.positionsClosedStore.getValue() || [];
                    const newPositionsClosed = [updatedPosition, ...currentPositionsClosed];

                    if (newPositionsClosed.length > 100) {
                        newPositionsClosed.length = 100; // This effectively trims the array to the first 100 elements
                    }

                    this.positionsStore.next(newPositions);
                    this.positionsClosedStore.next(newPositionsClosed);
                } else if (posIndex !== -1) {
                    var newPositions = [...currentPositions];
                    newPositions[posIndex] = updatedPosition;
                    this.positionsStore.next(newPositions);
                } else {
                    // Asset not found and size is not "0", add it to the array
                    this.positionsStore.next([updatedPosition, ...currentPositions]);
                }
                break;

            case 'positions':
                //console.log("New positions: ", data);
                this.positionsStore.next(data);
                break;

            case 'positions_closed':
                //console.log("New positions_closed: ", data);
                this.positionsClosedStore.next(data);
                break;

            case 'trade':
                //console.log("New trade: ", data);
                // Prepend the new trade to the start of the trades array
                this.emit('newTrade', data); // Emit new trade event

                if (this.selectedAsset && typeof this.chartStreamingCallback === 'function') {
                    const selectedAssetValue = this.selectedAsset.getValue();
                    if (selectedAssetValue && data.Asset == selectedAssetValue.symbol) {
                        this.chartStreamingCallback(data);
                    }
                }
                break;

            case 'trades':
                //console.log("New trades: ", data);
                // Replace the current trades in tradesStore with the new data
                this.tradesStore.next(data.slice(0, 250));
                // Optionally, emit an event for the update
                this.emit('tradesUpdated', data);
                break;

            case 'trade_user':
                //console.log("New user trade: ", data);
                var currentTrades = this.tradesUserStore.getValue();

                if (!Array.isArray(currentTrades)) {
                    currentTrades = [];
                }

                // Add the new user trade to the top of the store
                const newTrades = [data, ...currentTrades];

                // Trim the last trades if the length is greater than 100
                if (newTrades.length > 100) {
                    newTrades.length = 100; // This effectively trims the array to the first 100 elements
                }

                this.tradesUserStore.next(newTrades);
                break;

            case 'trades_user':
                //console.log("New user trades: ", data);
                this.tradesUserStore.next(data);
                break;
        }
    }

    public setSelectedResolution(resolution: string) {
        this.selectedChartResolution.next(resolution);
    }

    public setChartStreamingCallback(callback: (lastTrade: any) => void) {
        this.chartStreamingCallback = callback;
    }

    public setSelectedWallet(wallet: Wallet | null) {
        //console.log("Wallet selected: ", wallet);
        this.selectedWallet.next(wallet);
        this.auth();
    }

    public setSelectedMarginCurrency(baseToken: string) {
        //console.log("setSelectedMarginCurrency ", baseToken);

        this.selectedMarginCurrency.next(baseToken);
        localStorage.setItem('selectedCurrency', baseToken);
    }

    public getMarginCurrencyObject() {
        //console.log("[getMarginCurrencyObject] ", this.selectedMarginCurrency.getValue())
        return this.marginCurrenciesStore.getValue()[this.selectedMarginCurrency.getValue()];
    }

    public findMarginCurrency(tokan_address: string) {
        //console.log("[findMarginCurrency] ", tokan_address)
        return this.marginCurrenciesStore.getValue()[tokan_address];
    }

    // public updateMarginCurrencyObject() {
    //     console.log("[updateMarginCurrencyObject] ", this.selectedMarginCurrency);
    //     this.setSelectedMarginCurrencyObject(this.selectedMarginCurrency);
    // }

    public updateBalance() {
        const baseToken = this.getMarginCurrencyObject()?.token_address;

        if (baseToken) {
            const currentBalances = this.balancesStore.getValue();

            // Check if the baseToken exists in the balancesStore
            if (currentBalances[baseToken]) {
                // If found, set this.balance to the found item
                this.balance.next(currentBalances[baseToken]);
            } else {
                // If not found, update balancesStore with default values for baseToken and set this.balance
                const defaultBalance = {
                    total_balance: '0',
                    available_balance: '0',
                    crossmargin_reserve: '0',
                    crossmargin_profit: '0',
                };
                this.balancesStore.next({
                    ...currentBalances,
                    [baseToken]: defaultBalance,
                });
                this.balance.next(defaultBalance);
            }
        }
    }

    public getBalance(baseToken: string): Balance {
        const currentBalances = this.balancesStore.getValue();
        const defaultBalance = {
            total_balance: '0',
            available_balance: '0',
            crossmargin_reserve: '0',
            crossmargin_profit: '0',
        };
        if (currentBalances[baseToken]) {
            return currentBalances[baseToken];
        } else {
            return defaultBalance;
        }
    }

    public updateMarkPrice(data: any) {
        // Get the current state of the store
        const currentCurrencies = this.marginCurrenciesStore.getValue();

        // Check if the currency with the matching ticker exists
        const currencyKeys = Object.keys(currentCurrencies);
        for (const key of currencyKeys) {
            if (currentCurrencies[key].ticker === data.symbol) {
                // Update the mark_price of the matched currency
                currentCurrencies[key].mark_price = data.price;
                break; // Exit the loop once the update is done
            }
        }

        // Emit the updated record of currencies
        this.marginCurrenciesStore.next(currentCurrencies);
    }

    public updateSelectedAsset(newAssets: Asset[]) {
        // Get the current state of the store
        const selectedAsset = this.selectedAsset.getValue();

        if (!selectedAsset) return;

        // Find the index of the asset to be updated
        const assetIndex = newAssets.findIndex(asset => asset.symbol === selectedAsset.symbol);

        if (assetIndex !== -1) {
            this.selectedAsset.next(newAssets[assetIndex]);
        }
    }

    public setSelectedAsset(asset: Asset) {
        if (
            this.selectedAsset.getValue() != null &&
            this.selectedAsset.getValue()?.symbol == asset.symbol
        )
            return;
        console.log('Asset selected: ', asset);
        const oldAsset = this.selectedAsset;
        //this.emit("assetSelected", asset);
        this.selectedAsset.next(asset);
        this.subscribeToAsset(asset.symbol);
        // if (!oldAsset) {
        //     this.subscribeToAsset(asset.symbol);
        // } else if (asset && (oldAsset.symbol != asset.symbol)) {
        //     this.subscribeToAsset(asset.symbol);
        // }
    }

    public findAsset(symbol: string) {
        const assets = this.assetsStore.getValue();
        return assets.find(asset => asset.symbol === symbol);
    }

    public initialize() {
        if (!this.worker) {
            this.initializeWebSocketWorker();
        }
    }

    // Event callbacks
    private eventCallbacks: Record<string, Function[]> = {};

    // Subscribe to an event
    public on(eventType: string, callback: Function): () => void {
        if (!this.eventCallbacks[eventType]) {
            this.eventCallbacks[eventType] = [];
        }
        this.eventCallbacks[eventType].push(callback);

        // Return a function for unsubscribing
        return () => {
            this.eventCallbacks[eventType] = this.eventCallbacks[eventType].filter(
                cb => cb !== callback
            );
        };
    }

    // Emit an event
    public emit(eventType: string, data: any) {
        const callbacks = this.eventCallbacks[eventType];
        if (callbacks) {
            callbacks.forEach(callback => callback(data));
        }
    }

    private reconnectInterval: number | null = null;

    private initializeWebSocketWorker() {
        this.worker = new Worker(new URL('./helpers/cloudWorker.ts', import.meta.url), {
            type: 'module',
        });

        const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
        if (ENVIRONMENT == 'dev') {
            var backendUrl = 'ws://' + process.env.REACT_APP_DEV_BACKEND_ENDPOINT + '/ws';
        } else {
            var backendUrl = 'wss://' + process.env.REACT_APP_LIVE_BACKEND_ENDPOINT + '/ws';
        }

        this.worker.postMessage({ action: 'openWebSocket', url: backendUrl });

        this.worker.addEventListener('message', event => {
            const message = event.data;
            switch (message.type) {
                case 'websocketOpen':
                    this.readyState = true;
                    this.isConnected.next(true);
                    this.auth();

                    if (this.selectedAsset.getValue() != null) {
                        this.subscribeToAsset(this.selectedAsset.getValue()?.symbol);
                    }
                    if (this.reconnectInterval) {
                        clearInterval(this.reconnectInterval);
                        this.reconnectInterval = null;
                    }
                    break;
                case 'websocketClose':
                    this.readyState = false;
                    this.isConnected.next(false);
                    this.tryReconnect();
                    break;
                case 'websocketMessage':
                    const { type, data, isBinary } = message;

                    if (!isBinary) {
                        const decodedData = JSON.parse(data);
                        this.handleWebSocketMessage(decodedData.type, decodedData.data);
                    } else {
                        const deserializedMsg = deserializeMsg(data);
                        if (!deserializedMsg) {
                            return;
                        }
                        //console.log("[websocketMessage] isBinary", type, deserializedMsg);
                        this.handleWebSocketMessage(deserializedMsg.type, deserializedMsg.data);
                    }

                    break;
                case 'websocketError':
                    // Handle error event.
                    break;
                // Other cases
            }
        });
    }

    public notify(msg: ReactNode, type: string, delay: string) {
        //console.log("[notify]", delay);
        switch (type) {
            case 'success':
                toast.success(msg, {
                    position: 'bottom-right',
                    autoClose: typeof delay === 'string' ? parseInt(delay, 10) : delay, // Correct parsing
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    pauseOnFocusLoss: false,
                    theme: 'dark',
                });
                break;
            case 'warn':
                toast.warn(msg, {
                    position: 'bottom-right',
                    autoClose: typeof delay === 'string' ? parseInt(delay, 10) : delay, // Correct parsing
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    pauseOnFocusLoss: false,
                    theme: 'dark',
                });
                break;
            case 'error':
                toast.error(msg, {
                    position: 'bottom-right',
                    autoClose: typeof delay === 'string' ? parseInt(delay, 10) : delay, // Correct parsing
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    pauseOnFocusLoss: false,
                    theme: 'dark',
                });
                break;
            case 'info':
                toast.info(msg, {
                    position: 'bottom-right',
                    autoClose: typeof delay === 'string' ? parseInt(delay, 10) : delay, // Correct parsing
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    pauseOnFocusLoss: false,
                    theme: 'dark',
                });
                break;
        }
    }

    private tryReconnect() {
        if (!this.reconnectInterval) {
            this.reconnectInterval = window.setInterval(() => {
                console.log('[tryReconnect] trying to reconnect to websocket');
                this.initializeWebSocketWorker();
            }, 3000);
        }
    }

    public sendEvent(event: any, memo: any) {
        console.log(`[sendEvent] event=${event} memo=${event} uniqueUserId=${this.uniqueUserId}`);
        if (this.readyState && this.worker) {
            this.sendJsonMessage({
                type: 'event',
                data: {
                    unique_user_id: this.uniqueUserId,
                    event: event,
                    memot: memo,
                },
            });
        }
    }

    public auth() {
        //console.log("[AUTH] selectedMarginCurrency", this.getMarginCurrencyObject().symbol)
        if (this.readyState && this.worker) {
            this.sendJsonMessage({
                type: 'auth',
                data: {
                    user_address: this.selectedWallet.getValue()?.address,
                    unique_user_id: this.uniqueUserId,
                },
            });
        }
    }

    public subscribeToAsset(asset: any) {
        //console.log("[subscribeToAsset]");
        if (this.readyState && this.worker) {
            this.sendJsonMessage({
                type: 'subscribeAsset',
                data: {
                    asset: asset,
                },
            });
        }
    }

    public requestBtcAddress() {
        console.log('[requestBtcAddress] ');
        if (this.readyState && this.worker) {
            this.sendJsonMessage({
                type: 'requestBtcAddress',
                data: {},
            });
        }
    }

    public requestTronAddress() {
        console.log('[requestTronAddress] ');
        if (this.readyState && this.worker) {
            this.sendJsonMessage({
                type: 'requestTronAddress',
                data: {},
            });
        }
    }

    public requestSolanaAddress() {
        console.log('[requestSolanaAddress] ');
        if (this.readyState && this.worker) {
            this.sendJsonMessage({
                type: 'requestSolanaAddress',
                data: {},
            });
        }
    }

    public requestEthAddress() {
        console.log('[requestEthAddress] ');
        if (this.readyState && this.worker) {
            this.sendJsonMessage({
                type: 'requestEthAddress',
                data: {},
            });
        }
    }

    public sendWithdrawal(withdrawData: any) {
        if (this.readyState && this.worker) {
            var data = {
                type: 'withdraw',
                data: withdrawData,
            };

            console.log('[sendWithdrawal] data ', data);
            this.sendJsonMessage(data);
        }
    }

    public sendOrder(orderData: any) {
        if (this.readyState && this.worker) {
            var data = {
                type: 'order',
                data: orderData,
            };

            console.log('[sendOrder] data ', data);
            this.sendJsonMessage(data);
        }
    }

    public cancelOrder(cancelOrderData: any) {
        if (this.readyState && this.worker) {
            var data = {
                type: 'cancelOrder',
                data: cancelOrderData,
            };

            console.log('[cancelOrder] data ', data);
            this.sendJsonMessage(data);
        }
    }

    public updateMargin(reqData: any) {
        if (this.readyState && this.worker) {
            var data = {
                type: 'updateMargin',
                data: reqData,
            };

            console.log('[updateMargin] data ', data);
            this.sendJsonMessage(data);
        }
    }

    public changeRiskLimit(data: any) {
        console.log('[changeRiskLimit] ', data);
        if (this.readyState && this.worker) {
            this.sendJsonMessage({
                type: 'changeRiskLimit',
                data: data,
            });
        }
    }

    public sendJsonMessage(value: JsonValue) {
        if (this.worker && this.readyState) {
            this.worker.postMessage({ action: 'sendJsonMessage', msg: value });
        }
    }

    public formatPrice(price: number, decimals: number) {
        return (Number(price) / 1e8).toFixed(decimals);
    }

    public formatAmount(amount: number, amount_dec: number) {
        return (Number(amount) / 1e8).toFixed(amount_dec);
    }
}

export const eventCloud = new EventCloud();
