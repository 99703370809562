// ChartResolutionSelector.tsx

import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { eventCloud } from '../../../EventCloud';
import { Tooltip } from '../../../components/Tooltip';
import { AssetIcon } from '../../../components/AssetIcon/AssetIcon';
import { Asset } from '../../../interfaces/asset.interface';
import { formatPriceLocale, formatPriceNum, formatFundingRate } from '../../../utils/price';
import { ReactComponent as IconChevron } from '../../../assets/icons/svg/chevron.svg';
import { ReactComponent as IconMinus } from '../../../assets/icons/svg/minus.svg';
import { ReactComponent as IconChartLine } from '../../../assets/icons/svg/chartline.svg';
import { ReactComponent as IconChartCandle } from '../../../assets/icons/svg/chartcandle.svg';

type Resolution = '1S' | '1' | '5' | '15' | '60' | 'D';

const ChartResolutionSelector: React.FC<{
    setOpenAssetModal: (open: boolean) => void;
    setOpenChart: (open: boolean) => void;
    chartType: number;
    setChartType: (type: number) => void;
    openChart: boolean;
}> = ({ setOpenAssetModal, openChart, setOpenChart, setChartType, chartType }) => {
    const [selectedResolution, setSelectedResolution] = useState<Resolution>(
        (localStorage.getItem('dmex.selectedChartResolution') as Resolution) || '1'
    );
    const [assetLastPrice, setAssetLastPrice] = useState('');
    const [isPriceUp, setIsPriceUp] = useState(true);
    const [priceChange, setPriceChange] = useState(0);
    const [volume, setVolume] = useState(0);
    const [userFRMultiplier, setUserFRMultiplier] = useState(1);

    const resolutions: Resolution[] = ['1S', '1', '5', '15', '60', 'D'];
    const resolutionsName = ['1S', '1M', '5M', '15M', '1H', '1D'];

    const [selectedAsset, setSelectedAsset] = useState<Asset | null>(
        eventCloud.selectedAsset.getValue()
    );

    useEffect(() => {
        localStorage.setItem('dmex.selectedChartResolution', selectedResolution);
    }, [selectedResolution]);

    useEffect(() => {
        if (eventCloud.selectedAsset) {
            const assetSubscription = eventCloud.selectedAsset.subscribe(asset => {
                if (asset) {
                    setSelectedAsset(asset);
                    setAssetLastPrice(Number(asset.last_price).toString());
                    setPriceChange(asset.change);
                    setVolume(asset.volume);
                }
            });

            const parmasSub = eventCloud.paramsUserStore.subscribe(params => {
                if (params) {
                    setUserFRMultiplier(Number(params.fr_multiplier));
                }
            });

            return () => {
                assetSubscription.unsubscribe();
                parmasSub.unsubscribe();
            };
        }
    }, []);

    useEffect(() => {
        // const handleNewTrade = (trade: any) => {
        //   if (!selectedAsset) return;
        //   if (trade.Asset == selectedAsset.symbol) {
        //     console.log("[OrderBox: handleNewTrade] ", trade);
        //     setAssetLastPrice(Number(trade.Price).toString());
        //     setIsPriceUp(trade.PriceUp);

        //     var change =
        //       ((trade.Price / 1e8 - selectedAsset.open) / selectedAsset.open) * 100;

        //     setPriceChange(change);
        //   }
        // };

        const handleNewTrade = (trade: any) => {
            if (!selectedAsset) return;

            if (selectedAsset.symbol.toUpperCase() === trade.Asset.toUpperCase()) {
                const newVolume = selectedAsset.volume + (trade.Price * trade.Amount) / 1e16;
                let change = 0;
                if (selectedAsset.open !== 0) {
                    change = ((trade.Price / 1e8 - selectedAsset.open) / selectedAsset.open) * 100;
                }

                const updatedAsset = {
                    ...selectedAsset,
                    last_price: trade.Price,
                    volume: newVolume,
                    change: change,
                };

                setSelectedAsset(updatedAsset);
            }
        };

        const unsubscribeNewTrade = eventCloud.on('newTrade', handleNewTrade);

        return () => {
            unsubscribeNewTrade();
        };
    }, [selectedAsset]);

    const handleResolutionChange = (resolution: Resolution) => {
        setSelectedResolution(resolution);
        eventCloud.setSelectedResolution(resolution);
        // You can also pass this selection to a parent component or perform other actions here
    };

    if (!selectedAsset) return null;

    return (
        <div className="bg-black bg-opacity-30 select-none">
            <div className="flex items-center justify-between px-1">
                <button
                    type="button"
                    onClick={() => {
                        setOpenAssetModal(true);
                    }}
                    className="text-sm flex items-center text-gray-150 font-medium mb-0.5"
                >
                    <AssetIcon symbol={selectedAsset?.symbol} />
                    {selectedAsset?.symbol.toUpperCase().replace(/USD/g, '')}
                    <IconChevron className="w-3 text-gray-150 ml-1" />
                </button>

                {
                    // @ts-ignore
                    window.tvWidget && (
                        <div className="flex items-center ml-auto">
                            <button
                                type="button"
                                className={classNames(
                                    'flex justify-center items-center w-7 h-7 text-sm md:w-9 md:h-9 cursor-pointer md:text-base mx-1 last:mr-0',
                                    {
                                        'border border-gray-730 text-white bg-gray-750':
                                            chartType === 2,
                                        'text-gray-450': chartType !== 2,
                                    }
                                )}
                                onClick={() => {
                                    setChartType(2);

                                    try {
                                        // @ts-ignore
                                        window.tvWidget.chart().setChartType(2);
                                    } catch (e) {}
                                }}
                            >
                                <IconChartLine className="w-5 mt-0.5" />
                            </button>
                            <button
                                type="button"
                                className={classNames(
                                    'flex justify-center items-center w-7 h-7 text-sm md:w-9 md:h-9 cursor-pointer md:text-base mx-1 last:mr-0',
                                    {
                                        'border border-gray-730 text-white bg-gray-750':
                                            chartType === 1,
                                        'text-gray-450': chartType !== 1,
                                    }
                                )}
                                onClick={() => {
                                    setChartType(1);

                                    try {
                                        // @ts-ignore
                                        window.tvWidget.chart().setChartType(1);
                                    } catch (e) {}
                                }}
                            >
                                <IconChartCandle className="w-5" />
                            </button>
                        </div>
                    )
                }
                <div className="open-chart-block ml-4">
                    <button
                        type="button"
                        onClick={() => {
                            setOpenChart(!openChart);
                        }}
                        className="text-sm flex items-center text-gray-150 font-medium mb-0.5"
                    >
                        {openChart ? 'HIDE CHART' : 'SHOW CHART'}
                        {openChart ? (
                            <IconMinus className="w-3 text-gray-150 ml-1" />
                        ) : (
                            <IconChevron className="w-3 text-gray-150 ml-1" />
                        )}
                    </button>
                </div>
            </div>

            <div className="flex justify-between items-end flex-wrap px-1 pb-1">
                <div className="mr-4 text-xs my-1">
                    <div className="flex">
                        <div className="flex items-end border-r border-gray-730 pr-3 mr-3">
                            <div className="text-base leading-5 sm:text-xl md:text-2xl font-semibold sm:leading-6">
                                {formatPriceLocale(
                                    Number(selectedAsset.last_price),
                                    selectedAsset.decimals
                                )}
                            </div>
                            <div
                                className={classNames('text-xxs md:text-sm leading-4 ml-2', {
                                    'text-green-550': selectedAsset.change >= 0,
                                    'text-red-550': selectedAsset.change < 0,
                                })}
                            >
                                {selectedAsset.change >= 0 ? '+' : '-'}
                                {Math.abs(selectedAsset.change).toFixed(2)}%
                            </div>
                        </div>

                        <div className="mr-3">
                            <div className="text-gray-450 text-xxs">
                                <Tooltip label="24h VOLUME" text="24h VOLUME" position="right" />
                            </div>
                            <div className="font-medium text-gray-150 text-xs">
                                {formatPriceNum(selectedAsset.volume, 0)}
                            </div>
                        </div>

                        <div className="mr-5">
                            <div className="text-gray-450 text-xxs">
                                <Tooltip
                                    label="OPEN INTEREST"
                                    text="OPEN INTEREST"
                                    position="right"
                                />
                            </div>
                            <div className="font-medium text-gray-150 text-xs">
                                ${formatPriceNum(selectedAsset.open_interest, 0)}
                            </div>
                        </div>

                        <div className="hidden sm:block">
                            <div className="text-gray-450 text-xxs">
                                <Tooltip label="1H FUNDING" text="1H FUNDING" position="right" />
                            </div>
                            <div
                                className={classNames('font-medium text-gray-150 text-xs', {
                                    'text-green-550': Number(selectedAsset?.funding_rate) >= 0,
                                    'text-red-550': Number(selectedAsset?.funding_rate) < 0,
                                })}
                            >
                                {Number(selectedAsset?.funding_rate) >= 0 ? '+' : '-'}
                                {formatFundingRate(
                                    Number(selectedAsset?.funding_rate),
                                    userFRMultiplier
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={classNames(
                        'flex text-base chart-resolution w-full mt-2 lg:mt-0.5 lg:w-auto',
                        {
                            openChart: openChart,
                        }
                    )}
                >
                    {resolutions.map((resolution, i) => (
                        <label
                            key={resolution}
                            className={classNames(
                                'flex justify-center items-center w-7 h-7 text-sm md:w-9 md:h-9 cursor-pointer md:text-base mx-1 last:mr-0',
                                {
                                    'border border-gray-730 text-white bg-gray-750':
                                        selectedResolution === resolution,
                                    'text-gray-450': selectedResolution !== resolution,
                                }
                            )}
                        >
                            <input
                                type="radio"
                                value={resolution}
                                checked={selectedResolution === resolution}
                                onChange={() => handleResolutionChange(resolution)}
                                className="invisible absolute"
                            />
                            {resolutionsName[i]}
                        </label>
                    ))}
                </div>

                {/*<span className="text-xs ml-2">
          O:{" "}
          {formatPrice(
            selectedAsset.open * 1e8,
            Number(selectedAsset?.decimals)
          )}{" "}
          H:{" "}
          {formatPrice(
            selectedAsset.high * 1e8,
            Number(selectedAsset?.decimals)
          )}{" "}
          L:{" "}
          {formatPrice(
            selectedAsset.low * 1e8,
            Number(selectedAsset?.decimals)
          )}{" "}
          C:{" "}
          <span
            className={classNames([
              isPriceUp
                ? "text-green-550"
                : "text-red-550",
            ])}
          >
          {formatPrice(
            Number(assetLastPrice),
            Number(selectedAsset?.decimals)
          )}
          </span>{" "}
          %:{" "}
          <span
            className={classNames([
              priceChange >= 0
                ? "text-green-550"
                : "text-red-550",
            ])}
          >
            {priceChange >= 0 ? "+" : "-"}
            {Math.abs(priceChange).toFixed(2)}%
          </span>
        </span>*/}
            </div>
        </div>
    );
};

const formatVolume = (volume: number): string => {
    return Intl.NumberFormat('en-US').format(+volume.toFixed(0));
};

export default ChartResolutionSelector;
