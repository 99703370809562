// Funds.tsx

import React, { useState, useEffect } from 'react';
import { BigNumber } from 'bignumber.js';
import { eventCloud } from './EventCloud';
import { Fund } from './interfaces/fund.interface';
import classNames from 'classnames';
import { getExplorer } from './helpers/getExplorer';

import './css/Funds.css';

const Funds = ({ isLite }: { isLite?: boolean }) => {
    const [funds, setFunds] = useState<Fund[]>([]);

    useEffect(() => {
        // Subscribe
        const subscription = eventCloud.fundsStore.subscribe(data => {
            if (!data || data.length === 0) {
                setFunds([]);
                return;
            }
            setFunds(data);
        });

        return () => subscription.unsubscribe();
    }, []);

    return (
        <div className="text-xs positions-block-wrapper">
            <h2
                className={classNames(
                    'px-2 py-2 bg-black bg-opacity-30 border-gray-650 font-medium text-xs table-headline',
                    { '!hidden': isLite }
                )}
            >
                Funds
            </h2>

            <div
                className={classNames('order-table-wrapper container-scroll text-gray-150', {
                    isLite: isLite,
                })}
            >
                <table
                    style={{ width: '100%', borderCollapse: 'collapse', minWidth: '500px' }}
                    className={classNames('order-table', { isLite: isLite })}
                >
                    <thead>
                        <tr>
                            <th className="text-left">Date</th>
                            <th className="text-left">Type</th>
                            <th className="text-left">Asset</th>
                            <th className="text-right">Amount</th>
                            <th className="text-right">Origin Hash</th>
                        </tr>
                    </thead>
                    <tbody>
                        {funds.map((fund, index) => {
                            var is_link = false;
                            var origin_link = '';

                            if (
                                fund.type == 'withdraw' &&
                                fund.origin_hash !=
                                    '0x0000000000000000000000000000000000000000000000000000000000000000'
                            ) {
                                is_link = true;
                                origin_link =
                                    getExplorer(fund.margin_network) + 'tx/' + fund.origin_hash;
                                switch (fund.margin_network) {
                                    case 'Tron':
                                        origin_link =
                                            getExplorer(fund.margin_network) +
                                            'transaction/' +
                                            fund.origin_hash;
                                        break;

                                    case 'Ton':
                                        origin_link =
                                            getExplorer(fund.margin_network) + fund.origin_hash;
                                        break;
                                }
                            }
                            return (
                                <tr key={index}>
                                    <td className="text-left">
                                        {formatTimestamp(fund.created_at)}
                                    </td>
                                    <td
                                        className={classNames([
                                            fund.type == 'deposit'
                                                ? 'text-green-550 text-left'
                                                : 'text-red-550 text-left',
                                        ])}
                                    >
                                        {fund.type.toUpperCase()}
                                    </td>
                                    <td className="text-left">
                                        {fund.margin_symbol.toUpperCase()} [{fund.margin_network}]
                                    </td>
                                    <td
                                        className={classNames([
                                            fund.type == 'deposit'
                                                ? 'text-green-550 text-right'
                                                : 'text-red-550 text-right',
                                        ])}
                                    >
                                        {formatDirection(fund.type)}
                                        {formatAmount(fund.amount, fund.margin_dec)}{' '}
                                        {fund.margin_symbol} [
                                        {eventCloud.findMarginCurrency(fund.token)?.network_slug}]
                                    </td>
                                    <td className="text-right">
                                        {is_link ? (
                                            <a
                                                className="underline"
                                                target="_blank"
                                                href={origin_link}
                                            >
                                                {fund.origin_hash.slice(0, 10)}
                                            </a>
                                        ) : (
                                            fund.origin_hash.slice(0, 10)
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                {funds.length === 0 && (
                    <div
                        className="py-3 text-center text-xs text-gray-450 h-full pb-8 flex justify-center items-center"
                        style={{ height: '80%' }}
                    >
                        No data
                    </div>
                )}
            </div>
        </div>
    );
};

const formatDirection = (type: string): string => {
    if (type == 'withdraw' || type == 'liquidation' || type == 'funding') {
        return '-';
    } else {
        return '+';
    }
};

const formatAmount = (amount: BigNumber, amount_dec: number): string => {
    return (Number(amount) / 1e18).toFixed(amount_dec);
};

const formatTimestamp = (timestamp: any) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
};

export default Funds;
