// Trades.tsx

import React, { useEffect, useReducer, memo } from 'react';
import classNames from 'classnames';
import { eventCloud } from './EventCloud';
import { TradeConcise } from './interfaces/tradeConcise.interface';
import './css/Trades.css';

const MAX_TRADES = 50;

type TradeItemType = TradeConcise;
type TradeItemProps = {
    trade: TradeItemType;
};

type TradeState = TradeItemType[];
type TradeAction =
    | { type: 'ADD_TRADE'; payload: TradeItemType }
    | { type: 'SET_TRADES'; payload: TradeItemType[] };

const tradeReducer = (state: TradeState, action: TradeAction) => {
    switch (action.type) {
        case 'ADD_TRADE':
            return [action.payload, ...state].slice(0, MAX_TRADES);
        case 'SET_TRADES':
            return action.payload;
        default:
            return state;
    }
};

const TradeItem = memo(({ trade }: TradeItemProps) => {
    return (
        <div className="flex text-xxs border-b border-gray-250">
            <div className="p-1 border-r border-gray-250" style={{ width: '35%' }}>
                <div
                    className={classNames([
                        trade.PriceUp
                            ? 'text-green-550 pl-0.5 text-left'
                            : 'text-red-550 pl-0.5 text-left',
                    ])}
                >
                    {formatPriceLocale(Number(trade.Price))}
                </div>
            </div>
            <div className="p-1 text-right border-r border-gray-250" style={{ width: '35%' }}>
                <div className="pr-0.5 text-right">
                    {calculateValue(Number(trade.Price), Number(trade.Amount))}
                </div>
            </div>
            <div className="p-1 text-right" style={{ width: '40%' }}>
                {formatTimestamp(trade.Timestamp)}
            </div>
        </div>
    );
});

const Trades: React.FC = () => {
    const [trades, dispatch] = useReducer(tradeReducer, []);

    useEffect(() => {
        if (eventCloud) {
            const subscription = eventCloud.tradesStore.subscribe(newTrades => {
                dispatch({ type: 'SET_TRADES', payload: newTrades });
            });

            const handleNewTrade = (trade: any) => {
                if (eventCloud.selectedAsset == null) {
                    return;
                }

                const asset = eventCloud.selectedAsset.getValue();

                if (asset == null) {
                    return;
                }

                if (trade.Asset != asset.symbol.toLowerCase()) {
                    return;
                }

                dispatch({ type: 'ADD_TRADE', payload: trade });
            };

            const unsubscribeNewTrade = eventCloud.on('newTrade', handleNewTrade);

            return () => {
                subscription.unsubscribe();
                unsubscribeNewTrade();
            };
        }
    }, [eventCloud]);

    return (
        <div className="trades-container w-60 text-xs border-r border-gray-650 self-stretch ">
            <h2 className="px-4 py-2 bg-black bg-opacity-30">Latest Trades</h2>
            <div className="flex text-gray-450 text-xxs border-t border-b border-gray-650 bg-gray-750">
                <div className="p-1 border-r border-gray-650" style={{ width: '35%' }}>
                    <div className="pl-0.5 text-left">PRICE</div>
                </div>
                <div
                    className="p-1 pr-2 text-right border-r border-gray-650"
                    style={{ width: '35%' }}
                >
                    VALUE/USD
                </div>
                <div className="p-1 text-right" style={{ width: '40%' }}>
                    TIME
                </div>
            </div>
            <div className="border-b border-gray-650 text-xxs">
                {trades.map((trade, index) => (
                    <TradeItem key={index} trade={trade} />
                ))}
            </div>
        </div>
    );
};

const formatPriceLocale = (price: number): string => {
    if (!eventCloud.selectedAsset) {
        return 'N/A'; // Or some other placeholder value
    }

    const asset = eventCloud.selectedAsset.getValue();
    if (asset == null) {
        return 'N/A'; // Or some other placeholder value
    }
    const userLocale = navigator.language;
    return new Intl.NumberFormat(userLocale, {
        style: 'decimal',
        minimumFractionDigits: asset.decimals,
        maximumFractionDigits: asset.decimals,
    }).format(price / 1e8);
};

const formatTimestamp = (timestamp: any) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
};

const calculateValue = (price: number, amount: number): string => {
    const value = (price / 1e8) * (amount / 1e8);

    const userLocale = navigator.language;
    return new Intl.NumberFormat(userLocale, {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(value);
    //return value.toFixed(0);
};

export default Trades;
