// OrdersClosed.tsx

import { useState, useEffect } from 'react';
import { eventCloud } from './EventCloud';
import { Order } from './interfaces/order.interface';

import './css/Orders.css';
import classNames from 'classnames';

const OrdersClosed = ({ isLite }: { isLite?: boolean }) => {
    const [orders, setOrders] = useState<Order[]>([]);

    useEffect(() => {
        // Subscribe to assetsStore
        const subscription = eventCloud.ordersClosedStore.subscribe(orders => {
            if (!orders || orders.length === 0) {
                setOrders([]); // Return early if newAssets is empty
                return;
            }
            //console.log("Subscribe new orders ", orders);
            setOrders(orders);
        });

        return () => subscription.unsubscribe();
    }, []);

    return (
        <div className="text-xs positions-block-wrapper">
            <h2
                className={classNames(
                    'px-2 py-2 bg-black bg-opacity-30 border-gray-650 font-medium text-xs table-headline',
                    { '!hidden': isLite }
                )}
            >
                Closed Orders
            </h2>

            <div
                className={classNames('order-table-wrapper container-scroll text-gray-150', {
                    isLite: isLite,
                })}
            >
                <table
                    style={{ width: '100%', borderCollapse: 'collapse', minWidth: '900px' }}
                    className={classNames('order-table', { isLite: isLite })}
                >
                    <thead>
                        <tr>
                            <th className="text-left">Time</th>
                            <th className="text-left">Asset</th>
                            <th className="text-right">Amount</th>
                            <th className="text-right">Price</th>
                            <th className="text-left">Side</th>
                            <th className="text-right">Filled</th>
                            <th className="text-right">Remaining</th>
                            <th className="text-right">Leverage</th>
                            <th className="text-right">Reduce Only</th>
                            <th className="text-right">Reason</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((order, index) => (
                            <tr key={index}>
                                <td className="text-left">{formatTimestamp(order.created_at)}</td>
                                <td className="text-left">
                                    {order.asset.replace(/usd/g, '').toUpperCase()}
                                </td>

                                <td className="text-right">
                                    {formatNumLocale(
                                        parseInt(order.amount) / 1e8,
                                        order.amount_dec
                                    )}
                                </td>
                                <td className="text-right">
                                    {Number(order.leverage) == 1 ? (
                                        <span>{'MARKET'}</span>
                                    ) : (
                                        formatPrice(parseInt(order.price), order.decimals) + ' USD'
                                    )}
                                </td>
                                <td
                                    className={classNames('text-left', [
                                        order.side ? 'text-green-550' : 'text-red-550',
                                    ])}
                                >
                                    <div
                                        className={classNames(
                                            'inline-block text-xs px-1 bg-opacity-20 ml-1',
                                            [
                                                order.side
                                                    ? 'text-green-550 bg-green-550'
                                                    : 'text-red-550 bg-red-550',
                                            ]
                                        )}
                                    >
                                        {order.side ? 'BUY' : 'SELL'}
                                    </div>
                                </td>
                                <td className="text-right">
                                    {formatNumLocale(
                                        (Number(order.amount) - Number(order.remaining_amount)) /
                                            1e8,
                                        order.amount_dec
                                    )}
                                </td>
                                <td className="text-right">
                                    {formatNumLocale(
                                        Number(order.remaining_amount) / 1e8,
                                        order.amount_dec
                                    )}
                                </td>
                                <td className="text-right">
                                    {Number(order.leverage) == 0 ? (
                                        <span className="text-yellow-550">{'CROSS'}</span>
                                    ) : Number(order.leverage) == 1 ? (
                                        <span className="text-yellow-550">{'LIQUIDATION'}</span>
                                    ) : (
                                        formatPrice(Number(order.leverage), 2) + 'x'
                                    )}
                                </td>
                                <td className="text-right">{order.closing_order ? 'Yes' : 'No'}</td>
                                <td className="text-right">{order.close_reason}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {orders.length === 0 && (
                    <div
                        className="py-3 text-center text-xs text-gray-450 h-full pb-8 flex justify-center items-center"
                        style={{ height: '80%' }}
                    >
                        No data
                    </div>
                )}
            </div>
        </div>
    );
};

const formatPrice = (price: number, decimals: number): string => {
    return (Number(price) / 1e8).toFixed(decimals);
};

const formatAmount = (amount: number, amount_dec: number): string => {
    return (Number(amount) / 1e8).toFixed(amount_dec);
};

const formatTimestamp = (timestamp: any) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
};

const formatNumLocale = (price: number, decimals: number): string => {
    return new Intl.NumberFormat(navigator.language, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    }).format(price);
};

export default OrdersClosed;
