// Withdraw.ts

import React, { useState, useEffect } from 'react';
import { Wallet } from '../interfaces/wallet.interface';
import { ethers } from 'ethers';
import { eventCloud } from './../EventCloud';
import Web3 from 'web3';
import { getEthProvider } from '../helpers/web3Provider';
import { Sign } from './Sign';
import { formatNotificationMessage } from '../helpers/notificationHelpers';

interface WithdrawParams {
    token: string;
    amount: string;
    destinationAddress: string;
    nonce: number;
    feeWithdrawal: string;
    contractAddress: string;
}

export const handleWithdraw = async ({
    token,
    amount,
    destinationAddress,
    nonce,
    feeWithdrawal,
    contractAddress,
}: WithdrawParams) => {
    let web3 = new Web3(getEthProvider('Ethereum'));

    const selectedWallet = eventCloud.selectedWallet.getValue();

    if (!selectedWallet) return;

    // if (!selectedWallet.isMetaMask) {
    //     web3 = new Web3(getEthProvider());
    // } else {
    //     web3 = new Web3(window.ethereum);
    // }

    //amount = (Number(amount) * 1e18).toFixed(0);//
    amount = web3.utils.toWei(amount, 'ether');

    const userAddress = selectedWallet.address;

    if (Number(amount) < Number(feeWithdrawal)) {
        eventCloud.notify(
            formatNotificationMessage({
                title: 'WITHDRAWAL AMOUNT LOWER THAN WITHDRAWAL FEE',
                message: [
                    // {
                    //   text: "AMOUNT AND PRICE MUST BE GREATER THAN ZERO",
                    //   bold: false,
                    // },
                ],
            }),
            'error',
            '2500'
        );

        return;
    }

    console.log(`[handleWithdraw] 
        contractAddress=${contractAddress} 
        token=${token} 
        amount=${amount}
        user=${userAddress}
        nonce=${nonce}
        destinationAddress=${destinationAddress}
        feeWithdrawal=${feeWithdrawal}
    `);

    const hash = web3.utils.soliditySha3(
        { type: 'address', value: contractAddress },
        { type: 'address', value: token },
        { type: 'uint256', value: amount },
        { type: 'address', value: userAddress },
        { type: 'uint256', value: nonce },
        { type: 'string', value: destinationAddress },
        { type: 'uint256', value: feeWithdrawal }
    );

    // const hash = ethers.utils.keccak256(ethers.utils.defaultAbiCoder.encode(
    //     [
    //         'address',
    //         'address',
    //         'uint256',
    //         'address',
    //         'uint256',
    //         'string',
    //         'uint256'
    //     ],
    //     [
    //         contractAddress,
    //         token,
    //         amount,
    //         userAddress,
    //         nonce,
    //         destinationAddress,
    //         feeWithdrawal
    //     ]
    // ));

    if (!hash) {
        throw new Error('Failed to generate hash');
    }

    console.log('Withdraw hash', hash);

    const signatureResult = await Sign(hash, selectedWallet);

    // Check if the signatureResult is null
    if (!signatureResult) {
        console.error('Signature process was not completed.');
        return false;
    }

    const { v, r, s } = signatureResult;

    eventCloud.sendWithdrawal({
        hash,
        contractAddress,
        token,
        amount,
        userAddress,
        nonce,
        destinationAddress,
        feeWithdrawal,
        v,
        r,
        s,
    });
};

export default handleWithdraw;
