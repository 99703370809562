import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import ChartResolutionSelector from './components/ChartResolutionSelector/ChartResolutionSelector';
import { TVChartContainerLite } from './components/ChartLite/ChartLite';
import Assets from './components/Assets/Assets';
import { PositionsContainer } from './components/PositionsContainer/PositionsContainer';

import { Header } from '../Header';
import OrderBoxLite from './components/OrderBox/OrderBoxLite';
import useWindowSize from '../hooks/useWindowSize';
import { ModalHeader } from '../components/Modal/ModalHeader';
import { Modal } from '../components/Modal/Modal';

const LiteVersion = ({
    isLiteVersion,
    handleSetVersion,
}: {
    isLiteVersion: boolean;
    handleSetVersion: (isLite: boolean) => void;
}) => {
    const [windowWidth] = useWindowSize();
    const [openAssetModal, setOpenAssetModal] = useState(false);
    const [openChart, setOpenChart] = useState(false);
    const [chartType, setChartType] = useState(2);

    return (
        <>
            <Header isLiteVersion={isLiteVersion} setLiteVersion={handleSetVersion} />
            <div
                className="w-full components-container text-white items-stretch bg-black  p-2 md:py-4 md:px-4 lg:pb-4 lg:py-8 lg:px-6 lg:pb-4 mx-auto"
                style={{ maxWidth: '1440px' }}
            >
                <div
                    className={classNames(
                        'flex-1 h-full tw-chart-container tw-chart-container-lite pr-8',
                        {
                            openChart,
                        }
                    )}
                >
                    <ChartResolutionSelector
                        setOpenAssetModal={setOpenAssetModal}
                        openChart={openChart}
                        setOpenChart={setOpenChart}
                        setChartType={setChartType}
                        chartType={chartType}
                    />
                    <TVChartContainerLite openChart={openChart} chartType={chartType} />
                </div>

                <div className="w-full orderbox-lite-wrapper">
                    <OrderBoxLite setOpenAssetModal={setOpenAssetModal} />
                </div>

                <div className="break-column-1050" />
            </div>

            <PositionsContainer />

            <Modal
                visible={openAssetModal}
                onClose={() => {
                    setOpenAssetModal(false);
                }}
            >
                <div className="sm:-mt-5 -mt-1">
                    <ModalHeader
                        onClose={() => {
                            setOpenAssetModal(false);
                        }}
                    >
                        Assets
                    </ModalHeader>

                    <div className="flex flex-col items-center justify-center text-center text-xxs text-gray-550">
                        <Assets
                            onChangeAsset={() => {
                                setOpenAssetModal(false);
                            }}
                        />
                    </div>
                </div>
            </Modal>

            <div className="hidden">
                <Assets
                    onChangeAsset={() => {
                        setOpenAssetModal(false);
                    }}
                />
            </div>
        </>
    );
};

export default LiteVersion;
