// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { ConciseOrder } from '../exchange/concise-order';

export class OrderBook {
    bb: flatbuffers.ByteBuffer | null = null;
    bb_pos = 0;
    __init(i: number, bb: flatbuffers.ByteBuffer): OrderBook {
        this.bb_pos = i;
        this.bb = bb;
        return this;
    }

    static getRootAsOrderBook(bb: flatbuffers.ByteBuffer, obj?: OrderBook): OrderBook {
        return (obj || new OrderBook()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    static getSizePrefixedRootAsOrderBook(bb: flatbuffers.ByteBuffer, obj?: OrderBook): OrderBook {
        bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
        return (obj || new OrderBook()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    bids(index: number, obj?: ConciseOrder): ConciseOrder | null {
        const offset = this.bb!.__offset(this.bb_pos, 4);
        return offset
            ? (obj || new ConciseOrder()).__init(
                  this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4),
                  this.bb!
              )
            : null;
    }

    bidsLength(): number {
        const offset = this.bb!.__offset(this.bb_pos, 4);
        return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    asks(index: number, obj?: ConciseOrder): ConciseOrder | null {
        const offset = this.bb!.__offset(this.bb_pos, 6);
        return offset
            ? (obj || new ConciseOrder()).__init(
                  this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4),
                  this.bb!
              )
            : null;
    }

    asksLength(): number {
        const offset = this.bb!.__offset(this.bb_pos, 6);
        return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    static startOrderBook(builder: flatbuffers.Builder) {
        builder.startObject(2);
    }

    static addBids(builder: flatbuffers.Builder, bidsOffset: flatbuffers.Offset) {
        builder.addFieldOffset(0, bidsOffset, 0);
    }

    static createBidsVector(
        builder: flatbuffers.Builder,
        data: flatbuffers.Offset[]
    ): flatbuffers.Offset {
        builder.startVector(4, data.length, 4);
        for (let i = data.length - 1; i >= 0; i--) {
            builder.addOffset(data[i]!);
        }
        return builder.endVector();
    }

    static startBidsVector(builder: flatbuffers.Builder, numElems: number) {
        builder.startVector(4, numElems, 4);
    }

    static addAsks(builder: flatbuffers.Builder, asksOffset: flatbuffers.Offset) {
        builder.addFieldOffset(1, asksOffset, 0);
    }

    static createAsksVector(
        builder: flatbuffers.Builder,
        data: flatbuffers.Offset[]
    ): flatbuffers.Offset {
        builder.startVector(4, data.length, 4);
        for (let i = data.length - 1; i >= 0; i--) {
            builder.addOffset(data[i]!);
        }
        return builder.endVector();
    }

    static startAsksVector(builder: flatbuffers.Builder, numElems: number) {
        builder.startVector(4, numElems, 4);
    }

    static endOrderBook(builder: flatbuffers.Builder): flatbuffers.Offset {
        const offset = builder.endObject();
        return offset;
    }

    static createOrderBook(
        builder: flatbuffers.Builder,
        bidsOffset: flatbuffers.Offset,
        asksOffset: flatbuffers.Offset
    ): flatbuffers.Offset {
        OrderBook.startOrderBook(builder);
        OrderBook.addBids(builder, bidsOffset);
        OrderBook.addAsks(builder, asksOffset);
        return OrderBook.endOrderBook(builder);
    }
}
