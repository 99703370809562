// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

export class Ticker {
    bb: flatbuffers.ByteBuffer | null = null;
    bb_pos = 0;
    __init(i: number, bb: flatbuffers.ByteBuffer): Ticker {
        this.bb_pos = i;
        this.bb = bb;
        return this;
    }

    static getRootAsTicker(bb: flatbuffers.ByteBuffer, obj?: Ticker): Ticker {
        return (obj || new Ticker()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    static getSizePrefixedRootAsTicker(bb: flatbuffers.ByteBuffer, obj?: Ticker): Ticker {
        bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
        return (obj || new Ticker()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    symbol(): string | null;
    symbol(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
    symbol(optionalEncoding?: any): string | Uint8Array | null {
        const offset = this.bb!.__offset(this.bb_pos, 4);
        return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
    }

    price(): number {
        const offset = this.bb!.__offset(this.bb_pos, 6);
        return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
    }

    static startTicker(builder: flatbuffers.Builder) {
        builder.startObject(2);
    }

    static addSymbol(builder: flatbuffers.Builder, symbolOffset: flatbuffers.Offset) {
        builder.addFieldOffset(0, symbolOffset, 0);
    }

    static addPrice(builder: flatbuffers.Builder, price: number) {
        builder.addFieldFloat64(1, price, 0.0);
    }

    static endTicker(builder: flatbuffers.Builder): flatbuffers.Offset {
        const offset = builder.endObject();
        return offset;
    }

    static createTicker(
        builder: flatbuffers.Builder,
        symbolOffset: flatbuffers.Offset,
        price: number
    ): flatbuffers.Offset {
        Ticker.startTicker(builder);
        Ticker.addSymbol(builder, symbolOffset);
        Ticker.addPrice(builder, price);
        return Ticker.endTicker(builder);
    }
}
