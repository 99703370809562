// ImportDialog.tsx

import React from 'react';
import { Button } from '../components/Button/Button';
import { ModalHeader } from '../components/Modal/ModalHeader';
import { TextField } from '../components/TextField/TextField';
import { ethers } from 'ethers';

import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

interface ImportDialogProps {
    wallets: any;
    onClose: () => void;
    setWallets: any;
    selectWallet: any;
}

const useFormSchema = () =>
    z.object({
        privateKey: z
            .string()
            .min(1, 'Required')
            .refine(data => /^(0x)?[0-9a-fA-F]{64}$/.test(data), {
                message:
                    "Invalid private key format. Must be a 64-character hexadecimal string, with or without the '0x' prefix.",
            }),
    });

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

const ImportDialog: React.FC<ImportDialogProps> = ({
    wallets,
    onClose,
    setWallets,
    selectWallet,
}) => {
    const formSchema = useFormSchema();
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm<FormSchema>({
        resolver: zodResolver(formSchema), // Pass the schema to useForm using zodResolver
    });

    const add0xPrefix = (key: string) => {
        if (!key.startsWith('0x')) {
            return `0x${key}`; // Remove the '0x' prefix
        }
        return key; // Return the key as is if there's no '0x' prefix
    };

    const handleImportPrivateKey = (privateKey: string) => {
        try {
            // Ensure the private key has the '0x' prefix
            const prefixedPrivateKey = add0xPrefix(privateKey);

            // Create a wallet instance from the private key
            const wallet = new ethers.Wallet(prefixedPrivateKey);

            // Get the wallet address
            const walletAddress = wallet.address;

            const walletData = {
                address: walletAddress,
                privateKey: prefixedPrivateKey,
                isMetaMask: false,
            };
            const updatedWallets = [...wallets, walletData];
            setWallets(updatedWallets);
            localStorage.setItem('wallets', JSON.stringify(updatedWallets));
            selectWallet(walletData);

            console.log('Wallet Address:', walletAddress, walletData);

            onClose();

            // Further processing with walletAddress...
        } catch (error) {
            console.error('Error extracting wallet address:', error);
            // Handle any errors (e.g., invalid private key format)
        }
    };

    return (
        <div>
            <form
                onSubmit={handleSubmit(data => {
                    console.log('Handle submit', data);
                    handleImportPrivateKey(data.privateKey);
                })}
                className="relative"
            >
                <div className="-mt-4">
                    <ModalHeader onClose={onClose}>Import Wallet</ModalHeader>
                </div>
                <div className="text-sm">
                    <TextField
                        {...register('privateKey')}
                        error={errors?.privateKey}
                        label={'Private Key'}
                        required
                        withPaste
                        setValue={text => {
                            setValue('privateKey', text);
                        }}
                    />

                    <div className="flex text-gray-450 text-xs pb-1 mt-5">
                        <Button variant="gray" size="large" type="submit" className="w-full">
                            Import Private Key
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ImportDialog;
