export const AssetIcon = ({ symbol }: { symbol?: string }) => {
    return symbol ? (
        <img
            src={
                symbol === 'wifusd'
                    ? 'https://s2.coinmarketcap.com/static/img/coins/64x64/28752.png'
                    : `https://assets.coincap.io/assets/icons/${symbol
                          .replace(/tonusd/g, 'toncoin')
                          .replace(/usd/g, '')}@2x.png`
            }
            alt={symbol}
            className="w-6 mr-2"
        />
    ) : null;
};
