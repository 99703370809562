// KeyDialog.tsx

import React, { useState } from 'react';
import { Button } from '../components/Button/Button';
import { Wallet } from '../interfaces/wallet.interface';
import { ModalHeader } from '../components/Modal/ModalHeader';
import { DocumentDuplicateIcon } from '@heroicons/react/24/solid';

interface KeyDialogProps {
    wallet: Wallet | null;
    onClose: () => void;
}

const KeyDialog: React.FC<KeyDialogProps> = ({ wallet, onClose }) => {
    const [showPrivateKey, setShowPrivateKey] = useState(false);

    if (!wallet) return null;

    // Remove the first occurrence of '0x' from the private key
    const privateKey = wallet.privateKey?.replace(/^0x/, '');

    return (
        <div className="text-xxs">
            <div className="-mt-4">
                <ModalHeader onClose={onClose}>Your Wallet</ModalHeader>
            </div>
            <div className="flex text-gray-450 text-xs pb-1">
                Wallet Address
                <div className="h-4 -mt-0.5">
                    <Button
                        variant="empty"
                        size="small"
                        copy={wallet.address}
                        className="px-2 mr-2"
                    >
                        <DocumentDuplicateIcon className="w-4" />
                    </Button>
                </div>
            </div>
            <span className="text-sm" style={{ fontFamily: 'monospace' }}>
                {wallet.address}
            </span>
            <div className="flex text-gray-450 text-xs pb-1 mt-5">
                Private Key
                <div className="h-4 -mt-0.5">
                    <Button variant="empty" size="small" copy={privateKey} className="px-2 mr-2">
                        <DocumentDuplicateIcon className="w-4" />
                    </Button>
                </div>
                <Button
                    variant="empty"
                    size="small"
                    onClick={() => setShowPrivateKey(!showPrivateKey)}
                    className="ml-2"
                >
                    {showPrivateKey ? 'Hide Key' : 'Show Key'}
                </Button>
            </div>
            <span style={{ fontFamily: 'monospace' }}>
                {showPrivateKey ? privateKey : '••••••••••••••••••••••••••••••••••••••••••••'}
            </span>

            <div className="mt-5 p-1 text-xs text-red-550 border border-gray-450">
                Please ensure you have backed up your private key in a safe and secure manner.
                Losing access to your private key means losing access to your funds permanently.
            </div>
        </div>
    );
};

export default KeyDialog;
