import { forwardRef, PropsWithoutRef, useState } from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { FieldProps } from '../../types/field';
import { FieldGroup } from '../FieldGroup';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { Button } from '../Button/Button';
import { Transition } from '@headlessui/react';

export type TextFieldProps = Omit<
    PropsWithoutRef<JSX.IntrinsicElements['input']>,
    'className' | 'children'
> &
    FieldProps & {
        loading?: boolean;
        setValue?: (text: string) => void;
        disabled?: boolean;
        self?: string;
        courier?: boolean; // Add courier prop here
    };

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
    (
        {
            type = 'text',
            inputlength,
            required,
            optional,
            loading = false,
            withPaste = false,
            self,
            setValue,
            disabled = false,
            courier = false, // Default courier to false if not provided
            ...props
        },
        ref
    ) => {
        const [typeSelected, setTypeSelected] = useState(type);

        // Determine the appropriate font family based on the courier prop
        const inputStyle = courier ? { fontFamily: 'monospace' } : {};

        return (
            <FieldGroup
                required={required}
                optional={optional}
                inputlength={inputlength}
                {...props}
            >
                {computedProps => (
                    <>
                        <input
                            ref={ref}
                            type={typeSelected}
                            {...computedProps}
                            {...props}
                            autoComplete="off"
                            disabled={disabled}
                            style={{ ...inputStyle, borderStyle: 'solid' }} // Merge courier style with existing styles
                        />

                        {withPaste && (
                            <div className="absolute right-2 top-1 pt-px">
                                <Button
                                    type="button"
                                    size="small"
                                    className=""
                                    disabled={disabled} // Optionally disable the Paste button as well
                                    onClick={() => {
                                        if (!disabled) {
                                            navigator.clipboard
                                                .readText()
                                                .then(text => {
                                                    setValue && setValue(text);
                                                })
                                                .catch(err => {
                                                    console.error(
                                                        'Failed to read clipboard contents: ',
                                                        err
                                                    );
                                                });
                                        }
                                    }}
                                >
                                    Paste
                                </Button>
                            </div>
                        )}

                        {self && (
                            <div className="absolute right-2 top-1 pt-px">
                                <Button
                                    type="button"
                                    size="small"
                                    className=""
                                    disabled={disabled} // Optionally disable the Paste button as well
                                    onClick={() => {
                                        if (!disabled) {
                                            setValue && setValue(self);
                                        }
                                    }}
                                >
                                    Self
                                </Button>
                            </div>
                        )}

                        {type === 'password' && (
                            <button
                                type="button"
                                className="absolute right-2 top-2 pt-0.5"
                                disabled={disabled} // Optionally disable the show/hide password button as well
                                onClick={() => {
                                    if (!disabled) {
                                        setTypeSelected(
                                            typeSelected === 'password' ? 'text' : 'password'
                                        );
                                    }
                                }}
                            >
                                {typeSelected === 'password' ? (
                                    <EyeIcon className="w-5 text-gray-600" />
                                ) : (
                                    <EyeSlashIcon className="w-5 text-gray-600" />
                                )}
                            </button>
                        )}

                        <Transition
                            show={loading}
                            enter="ease-out duration-300 transition"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="absolute top-0 right-0 flex items-center h-full px-2 pointer-events-none">
                                <div className="px-2 py-1 bg-white">
                                    <LoadingSpinner className="w-3 h-3 text-violet-900" />
                                </div>
                            </div>
                        </Transition>
                    </>
                )}
            </FieldGroup>
        );
    }
);
