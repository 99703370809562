import classNames from 'classnames';
import { AriaAttributes, ReactNode } from 'react';
import { FieldProps } from '../types/field';
import { getFieldIds } from '../utils/field';
import { FieldError } from './FieldError';
import { FieldLabel } from './FieldLabel';

const getFieldOptions = ({ id, name, error }: FieldProps) => {
    const { computedId, errorId } = getFieldIds({ id, name });

    return {
        errorId,
        computedProps: {
            id: computedId,
            name,
            className: classNames(
                'block py-2 px-4 w-full border border-gray-650 focus:outline-none text-sm bg-gray-750 disabled:bg-gray-650 disabled:cursor-not-allowed opacity-100 box-border',
                error
                    ? 'pr-10 border-red-550 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 disabled:bg-red-50'
                    : 'focus:border-yellow-550  border-gray-650'
            ),
            ...(error
                ? ({
                      'aria-invalid': 'true',
                      'aria-describedby': errorId,
                  } as Pick<AriaAttributes, 'aria-invalid' | 'aria-describedby'>)
                : {}),
        },
    };
};

export interface FieldGroupProps extends FieldProps {
    children: (props: ReturnType<typeof getFieldOptions>['computedProps']) => ReactNode;
}

export const FieldGroup = ({
    label,
    error,
    optional,
    children,
    hasShadow = true,
    required,
    max,
    inputlength,
    ...props
}: FieldGroupProps) => {
    const { computedProps, errorId } = getFieldOptions({
        error,
        optional,
        ...props,
    });

    return (
        <div>
            {label && (
                <FieldLabel htmlFor={computedProps.id} optional={optional} required={required}>
                    {label}
                </FieldLabel>
            )}
            <div
                className={classNames('relative rounded-md', {
                    'shadow-sm': hasShadow,
                })}
            >
                {children(computedProps)}
            </div>
            <div className="flex justify-between">
                {error && <FieldError id={errorId}>{error.message}</FieldError>}
                {max && (
                    <div className="ml-auto my-1 text-xs text-gray-500">
                        {inputlength} chars of {max}
                    </div>
                )}
            </div>
        </div>
    );
};
