// DepositETH.ts

import React, { useState, useEffect } from 'react';
import { Wallet } from '../interfaces/wallet.interface';
import Web3 from 'web3';
import { eventCloud } from './../EventCloud';
import abiData from './abi/ConsensusBridgeDChain.json';
import { getEthProvider } from '../helpers/web3Provider';
import { switchNetwork } from '../helpers/switchNetwork';
import { requestAccountAccess } from '../helpers/requestAccountAccess';
import { MarginCurrency } from '../interfaces/marginCurrency.interface';
import { Fund } from '../interfaces/fund.interface';
import { formatNotificationMessage } from '../helpers/notificationHelpers';
import { toast } from 'react-toastify';
const bridgeAbi = abiData.abi;

interface DepositsParams {
    wallet: Wallet;
    marginCurrency: MarginCurrency;
    amount: string;
    recommendedGasPrice: number;
    setDepositPending: (val: boolean) => void;
    setWaitingConfirmation: (val: boolean) => void;
    depositGasLimit: number;
    onClose: () => void;
    setPendingTxHash: (hash: string) => void;
}

export const handleDepositETH = async ({
    wallet,
    marginCurrency,
    amount,
    recommendedGasPrice,
    setDepositPending,
    depositGasLimit,
    onClose,
    setWaitingConfirmation,
    setPendingTxHash,
}: DepositsParams) => {
    console.log(
        `[handleDepositETH] amount=${amount} recommendedGasPrice=${recommendedGasPrice} marginCurrency=`,
        marginCurrency
    );
    if (!wallet) throw new Error('Wallet is not provided');

    let web3;

    if (!wallet.isMetaMask) {
        web3 = new Web3(getEthProvider(marginCurrency.network));
    } else {
        web3 = new Web3(window.ethereum);

        var success = await switchNetwork(marginCurrency.network);
        if (!success) {
            return;
        }

        success = await requestAccountAccess();
        if (!success) {
            return;
        }
    }

    amount = web3.utils.toWei(amount, 'ether');
    const userAddress = wallet?.address;
    const privateKey = wallet?.privateKey;
    const contractAddress = marginCurrency.bridge_address;

    const recommendedMaxFeePerGas = web3.utils.toWei(recommendedGasPrice.toString(), 'gwei');

    if (!wallet.isMetaMask) {
        if (!privateKey) throw new Error('Private key missing');

        const account = web3.eth.accounts.privateKeyToAccount(privateKey);
        web3.eth.accounts.wallet.add(account);
        web3.eth.defaultAccount = account.address;
    }

    try {
        let contract = new web3.eth.Contract(bridgeAbi, marginCurrency.bridge_address);

        if (wallet.isMetaMask) {
            setWaitingConfirmation(true);
            eventCloud.emit('waitingMetaMaskConfirmation', {});
        } else {
            setDepositPending(true);
        }

        // Deposit the tokens
        const depositMethod: any = contract.methods.deposit;
        let toastId = toast.loading('Initiating deposit...');
        const prom = new Promise((resolve, reject) => {
            const unsubscribe = eventCloud.on('newFund', (data: Fund) => {
                if (data.type == 'deposit') {
                    console.log('[handleDepositETH] depositReceived=', data);
                    //toast.update(toastId, { render: "Deposit received.", type: "success", isLoading: false, autoClose: 5000 });
                    toast.dismiss(toastId);
                    resolve(data);
                    unsubscribe();
                }
            });

            depositMethod()
                .send({
                    from: userAddress,
                    value: amount,
                    gas: depositGasLimit.toString(),
                    gasPrice: recommendedMaxFeePerGas,
                })
                .on('transactionHash', (hash: any) => {
                    console.log('[handleDepositETH] transactionHash=', hash);
                    // User has confirmed the transaction in MetaMask
                    setPendingTxHash(hash);
                    setWaitingConfirmation(false);
                    eventCloud.emit('unWaitMetaMask', {});
                    setDepositPending(true);
                    toast.update(toastId, {
                        render: 'DEPOSIT SENT, Awaiting transaction confirmation',
                        isLoading: true,
                    });
                })
                .on('receipt', (receipt: any) => {
                    console.log('[handleDepositETH] receipt=', receipt);
                    // Transaction was confirmed on the blockchain
                    setPendingTxHash('');
                    onClose();
                    setDepositPending(false);
                    toast.update(toastId, {
                        render: 'TRANSACTION CONFIRMED, Awaiting deposit arrival',
                        isLoading: true,
                    });
                })
                .on('error', (error: any, receipt: any) => {
                    console.log('[handleDepositETH] error=', error);
                    // An error occurred, handle it
                    setWaitingConfirmation(false);
                    setDepositPending(false);
                    // Optionally, handle the error or the transaction receipt if available
                    if (error.code === 4001) {
                        // User rejected the transaction
                        console.log('Transaction rejected by the user.');
                    } else {
                        // Other errors
                        console.error('An error occurred', error);
                        // eventCloud.notify(
                        //     formatNotificationMessage({
                        //     title: "METAMASK ERROR",
                        //         message: [
                        //             {
                        //                 text: error.message,
                        //                 bold: false,
                        //             },
                        //         ],
                        //     }),
                        //     "error",
                        //     "10000"
                        // );
                    }

                    toast.update(toastId, {
                        render: `Error: ${error.message}`,
                        type: 'error',
                        isLoading: false,
                        autoClose: 5000,
                    });

                    // Reject the promise on error and clean up
                    reject(error);
                    unsubscribe();
                })
                .catch((error: any) => {
                    // This will catch any errors not caught by the .on('error') handler
                    console.error('Transaction rejected by user: ', error.message);
                    // Perform any additional error handling here
                    eventCloud.emit('unWaitMetaMask', {});
                    reject(error);
                    unsubscribe();
                    setWaitingConfirmation(false);
                    setDepositPending(false);

                    toast.update(toastId, {
                        render: `Error: ${error.message}`,
                        type: 'error',
                        isLoading: false,
                        autoClose: 5000,
                    });
                });
        });

        // toast.promise(
        //     prom,
        //     {
        //         pending: 'Awaiting Deposit',
        //         error: 'Deposit Failed'
        //     }
        // );
    } catch (error: any) {
        console.error('An error occurred', error);
        eventCloud.notify(
            formatNotificationMessage({
                title: 'METAMASK ERROR',
                message: [
                    {
                        text: error.message,
                        bold: false,
                    },
                ],
            }),
            'error',
            '10000'
        );
        onClose();
        setWaitingConfirmation(false);
        setDepositPending(false);
    }

    // try {
    //     const contract = new web3.eth.Contract(contractABI, contractAddress);

    //     if (wallet.isMetaMask) {
    //         const recommendedMaxPriorityFeePerGas = web3.utils.toWei('2', 'gwei'); // Example value, adjust based on network conditions
    //         const recommendedMaxFeePerGas = web3.utils.toWei(recommendedGasPrice.toString(), 'gwei'); // Example value, adjust based on network conditions

    //         try {
    //             // Execute using MetaMask
    //             var result = await contract.methods.deposit().send({
    //                 from: userAddress,
    //                 value: amount,
    //                 gas: "35000",
    //                 gasPrice: recommendedMaxFeePerGas,
    //                 // maxPriorityFeePerGas: recommendedMaxPriorityFeePerGas,
    //                 // maxFeePerGas: recommendedMaxFeePerGas
    //             });

    //             // If the execution reaches here, the transaction was successful.
    //             console.log("Transaction successful:", result);
    //         } catch (error) {
    //             // The transaction failed or was rejected by the user.
    //             console.error("Transaction failed:", error);
    //         }
    //     } else {
    //         if (!privateKey) throw new Error("Private key is undefined");

    //         const account = web3.eth.accounts.privateKeyToAccount(privateKey);
    //         web3.eth.accounts.wallet.add(account);
    //         web3.eth.defaultAccount = account.address;

    //         const recommendedMaxFeePerGas = web3.utils.toWei(recommendedGasPrice.toString(), 'gwei');

    //         // Execute using private key
    //         const receipt = await contract.methods.deposit().send({
    //             from: userAddress,
    //             gas: "35000", // Fixed gas limit
    //             value: amount, // Value in Wei
    //             gasPrice: recommendedMaxFeePerGas
    //         });

    //         console.log("Tx Receipt: ", receipt);
    //     }
    // } catch (error) {
    //     console.error('Error in deposit:', error);
    //     throw error;
    // }
};

export default handleDepositETH;
