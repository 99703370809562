// OrderBook.tsx

import React, { useEffect, useState, useReducer, useMemo } from 'react';
import classNames from 'classnames';
import { eventCloud } from './EventCloud';
import { ObConcise } from './interfaces/obConcise.interface';
import { Asset } from './interfaces/asset.interface';
import './css/OrderBook.css';

const initialOrderBookState = {
    orderBook: null,
};

function orderBookReducer(state: any, action: any) {
    switch (action.type) {
        case 'SET_ORDER_BOOK':
            return { ...state, orderBook: action.payload };
        default:
            return state;
    }
}

const OrderBook: React.FC = () => {
    const [{ orderBook }, dispatch] = useReducer(orderBookReducer, initialOrderBookState);
    const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);
    const [assetLastPrice, setAssetLastPrice] = useState('');
    const [isPriceUp, setIsPriceUp] = useState(true);
    const [priceChange, setPriceChange] = useState(0);

    useEffect(() => {
        if (eventCloud) {
            const subscription = eventCloud.orderBookStore.subscribe(
                (newOrderBook: ObConcise | null) => {
                    dispatch({ type: 'SET_ORDER_BOOK', payload: newOrderBook });
                }
            );
            return () => subscription.unsubscribe();
        }
    }, [eventCloud]);

    useEffect(() => {
        if (eventCloud.selectedAsset) {
            const assetSubscription = eventCloud.selectedAsset.subscribe(asset => {
                if (asset) {
                    setSelectedAsset(asset);
                    setAssetLastPrice(asset.last_price.toString());
                    setPriceChange(asset.change);
                }
            });
            return () => assetSubscription.unsubscribe();
        }
    }, []);

    useEffect(() => {
        const handleNewTrade = (trade: any) => {
            if (!selectedAsset || trade.Asset !== selectedAsset.symbol) return;
            setAssetLastPrice(trade.Price.toString());
            setIsPriceUp(trade.PriceUp);
            const change = ((trade.Price / 1e8 - selectedAsset.open) / selectedAsset.open) * 100;
            setPriceChange(change);
            document.title = `$${formatPriceLocale(
                trade.Price,
                selectedAsset.decimals
            )} ${selectedAsset.symbol.replace(/usd/g, '').toUpperCase()}/USD`;
        };
        const unsubscribeNewTrade = eventCloud.on('newTrade', handleNewTrade);
        return () => unsubscribeNewTrade();
    }, [selectedAsset]);

    const handleLastPriceClick = () => {
        eventCloud.emit('priceClicked', assetLastPrice);
    };

    const calculateTotal = (orders: any[]) => orders.reduce((acc, curr) => acc + +curr.total, 0);

    const totalAsks = useMemo(() => calculateTotal(orderBook?.asks || []), [orderBook?.asks]);
    const totalBids = useMemo(() => calculateTotal(orderBook?.bids || []), [orderBook?.bids]);

    const renderOrders = (orders: any[], isBid: boolean) =>
        orders.map((order: any, index: any) => {
            const { price, amount, total, is_yours } = order;
            const formattedPrice = formatPriceLocale(
                Number(price),
                Number(selectedAsset?.decimals)
            );
            const formattedAmount = formatAmount(Number(amount));
            const percentageWidth = (parseFloat(total) / (totalAsks + totalBids)) * 1000;
            return (
                <li
                    key={index}
                    className={`order-item relative flex text-xxs text-gray-150 border-b border-gray-250 ${
                        is_yours ? 'my-order' : ''
                    }`}
                >
                    <div
                        className={`p-1 border-r border-gray-250 ${
                            isBid ? 'text-green-550' : 'text-red-550'
                        } w-1/3 text-left`}
                    >
                        {formattedPrice}
                        {is_yours && <span>*</span>}
                    </div>
                    <div className="p-1 border-r border-gray-250 w-1/3 text-right">
                        {formattedAmount}
                    </div>
                    <div className="p-1 w-1/3 text-right">{formatTotal(total)}</div>
                    <div
                        className={classNames('absolute right-0 h-full', {
                            'bg-green-550': isBid,
                            'bg-red-550': !isBid,
                        })}
                        style={{ opacity: '0.25', width: `${percentageWidth}%` }}
                    />
                </li>
            );
        });

    return (
        <div className="order-book-container w-full text-xs border-t border-r border-gray-650  text-gray-150">
            <h2 className="px-4 py-2 bg-black bg-opacity-30 w-full border-b border-gray-650 ">
                Order Book
            </h2>
            <div className="flex w-full text-gray-450 text-xxs border-b border-gray-650 bg-gray-750">
                <div className="p-1 border-r border-gray-650 w-1/3 text-left">PRICE/USD</div>
                <div className="p-1 text-right border-r border-gray-650 w-1/3">
                    SIZE/{selectedAsset?.symbol.replace(/usd/g, '')}
                </div>
                <div className="p-1 text-right w-1/3">TOTAL</div>
            </div>
            <div className="w-full relative overflow-hidden">
                <div className="relative orderbook-body-centred w-full">
                    <ul className="asks list-none overflow-y-auto">
                        {renderOrders(orderBook?.asks || [], false)}
                    </ul>
                    <div className="flex justify-center py-2 border-b border-gray-650 text-sm font-medium">
                        <div
                            onClick={handleLastPriceClick}
                            className={classNames('last-price', 'cursor-pointer', 'select-none', {
                                'text-green-550': isPriceUp,
                                'text-red-550': !isPriceUp,
                            })}
                        >
                            {formatPriceLocale(
                                Number(assetLastPrice),
                                Number(selectedAsset?.decimals)
                            )}
                        </div>
                        <div
                            className={classNames([
                                priceChange >= 0
                                    ? 'text-green-550 text-xs text-right ml-4'
                                    : 'text-red-550 text-xs text-right ml-4',
                            ])}
                        >
                            {priceChange >= 0 ? '+' : '-'}
                            {Math.abs(priceChange).toFixed(2)}%
                        </div>
                    </div>
                    <ul className="bids list-none overflow-y-auto">
                        {renderOrders(orderBook?.bids || [], true)}
                    </ul>
                </div>
            </div>
        </div>
    );
};

// function formatPrice(price: number, decimals: number = 2) {
//     return price.toFixed(decimals);
// }

// function formatPriceLocale(price: string, decimals: number = 2) {
//     return parseFloat(price).toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
// }

// function formatAmount(amount: number) {
//     return amount.toLocaleString();
// }

const formatPrice = (price: number, decimals: number): string => {
    return (price / 1e8).toFixed(decimals);
};

const formatPriceLocale = (price: number, decimals: number): string => {
    if (!decimals) return '0';
    //console.log("[formatPriceLocale] price=", price, decimals)
    const userLocale = navigator.language;
    return new Intl.NumberFormat(userLocale, {
        style: 'decimal',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    }).format(price / 1e8);
};

const formatTotal = (total: number): string => {
    //console.log("[formatPriceLocale] price=", price, decimals)
    const userLocale = navigator.language;
    return new Intl.NumberFormat(userLocale, {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(total);
};

const formatAmount = (amount: number): string => {
    if (!eventCloud.selectedAsset) {
        return 'N/A'; // Or some other placeholder value
    }

    const asset = eventCloud.selectedAsset.getValue();
    if (asset == null) {
        return 'N/A'; // Or some other placeholder value
    }

    const userLocale = navigator.language;
    return new Intl.NumberFormat(userLocale, {
        style: 'decimal',
        minimumFractionDigits: asset.amount_dec,
        maximumFractionDigits: asset.amount_dec,
    }).format(amount / 1e8);
    //return (amount / 1e8).toFixed(asset.amount_dec);
};

export default OrderBook;
