// TradesUser.tsx

import { useState, useEffect } from 'react';
import { eventCloud } from './EventCloud';
import { Trade } from './interfaces/trade.interface';
import classNames from 'classnames';

import './css/TradesUser.css';

const TradesUser = ({ isLite }: { isLite?: boolean }) => {
    const [trades, setTrades] = useState<Trade[]>([]);

    useEffect(() => {
        // Subscribe to assetsStore
        const subscription = eventCloud.tradesUserStore.subscribe(tradesUser => {
            if (!tradesUser || tradesUser.length === 0) {
                setTrades([]); // Return early if newAssets is empty
                return;
            }
            //console.log("Subscribe new orders ", orders);
            setTrades(tradesUser);
        });

        return () => subscription.unsubscribe();
    }, []);

    return (
        <div className="text-xs positions-block-wrapper">
            <h2
                className={classNames(
                    'px-2 py-2 bg-black bg-opacity-30 border-gray-650 font-medium text-xs table-headline',
                    { '!hidden': isLite }
                )}
            >
                Trades
            </h2>

            <div
                className={classNames('order-table-wrapper container-scroll text-gray-150', {
                    isLite: isLite,
                })}
            >
                <table
                    style={{ width: '100%', borderCollapse: 'collapse' }}
                    className={classNames('order-table', { isLite: isLite })}
                >
                    <thead>
                        <tr>
                            <th className="text-left">Time</th>
                            <th className="text-left">Operation</th>
                            <th className="text-left">Asset</th>
                            <th className="text-right">Amount</th>
                            <th className="text-right">Price</th>
                            <th className="text-right">Fee</th>
                            <th className="text-right">Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {trades.map((trade, index) => (
                            <tr key={index}>
                                <td>{formatTimestamp(trade.created_at)}</td>
                                <td
                                    className={classNames([
                                        trade.operation == 'BUY'
                                            ? 'text-green-550 text-left'
                                            : 'text-red-550 text-left',
                                    ])}
                                >
                                    {trade.operation}
                                </td>
                                <td>{trade.asset.replace(/usd/g, '').toUpperCase()}</td>
                                <td className="text-right">
                                    {formatNumLocale(
                                        parseInt(trade.amount) / 1e8,
                                        trade.amount_dec
                                    )}
                                </td>
                                <td className="text-right">
                                    {formatPrice(parseInt(trade.price), trade.decimals)} USD
                                </td>
                                <td className="text-right text-red-550">
                                    -{formatAmount(parseInt(trade.fee), trade.margin_dec + 2)}{' '}
                                    {trade.margin_symbol} [
                                    {eventCloud.findMarginCurrency(trade.base_token)?.network_slug}]
                                </td>
                                <td className="text-right">
                                    {formatPrice(parseInt(trade.value), 3)} USD
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {trades.length === 0 && (
                    <div
                        className="py-3 text-center text-xs text-gray-450 h-full pb-8 flex justify-center items-center"
                        style={{ height: '80%' }}
                    >
                        No data
                    </div>
                )}
            </div>
        </div>
    );
};

const formatPrice = (price: number, decimals: number): string => {
    return (Number(price) / 1e8).toFixed(decimals);
};

const formatAmount = (amount: number, amount_dec: number): string => {
    return (Number(amount) / 1e8).toFixed(amount_dec);
};

const formatTimestamp = (timestamp: any) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
};

const formatNumLocale = (price: number, decimals: number): string => {
    return new Intl.NumberFormat(navigator.language, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    }).format(price);
};

export default TradesUser;
