// automatically generated by the FlatBuffers compiler, do not modify

import { OrderBook } from '../exchange/order-book';
import { Position } from '../exchange/position';
import { Positions } from '../exchange/positions';
import { Ticker } from '../exchange/ticker';
import { Trade } from '../exchange/trade';

export enum Message {
    NONE = 0,
    OrderBook = 1,
    Trade = 2,
    Ticker = 3,
    Position = 4,
    Positions = 5,
}

export function unionToMessage(
    type: Message,
    accessor: (
        obj: OrderBook | Position | Positions | Ticker | Trade
    ) => OrderBook | Position | Positions | Ticker | Trade | null
): OrderBook | Position | Positions | Ticker | Trade | null {
    switch (Message[type]) {
        case 'NONE':
            return null;
        case 'OrderBook':
            return accessor(new OrderBook())! as OrderBook;
        case 'Trade':
            return accessor(new Trade())! as Trade;
        case 'Ticker':
            return accessor(new Ticker())! as Ticker;
        case 'Position':
            return accessor(new Position())! as Position;
        case 'Positions':
            return accessor(new Positions())! as Positions;
        default:
            return null;
    }
}

export function unionListToMessage(
    type: Message,
    accessor: (
        index: number,
        obj: OrderBook | Position | Positions | Ticker | Trade
    ) => OrderBook | Position | Positions | Ticker | Trade | null,
    index: number
): OrderBook | Position | Positions | Ticker | Trade | null {
    switch (Message[type]) {
        case 'NONE':
            return null;
        case 'OrderBook':
            return accessor(index, new OrderBook())! as OrderBook;
        case 'Trade':
            return accessor(index, new Trade())! as Trade;
        case 'Ticker':
            return accessor(index, new Ticker())! as Ticker;
        case 'Position':
            return accessor(index, new Position())! as Position;
        case 'Positions':
            return accessor(index, new Positions())! as Positions;
        default:
            return null;
    }
}
