const min = (a: number, b: number): number => {
    if (a > b) {
        return b;
    } else {
        return a;
    }
};

const max = (a: number, b: number): number => {
    if (a < b) {
        return b;
    } else {
        return a;
    }
};

export const formatNumLocale = (price: number, decimals: number): string => {
    return new Intl.NumberFormat(navigator.language, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    }).format(price);
};

export const formatTimestamp = (timestamp: any) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
};

export const formatAmount = (amount: number, amount_dec: number): string => {
    return (Number(amount) / 1e8).toFixed(amount_dec);
};

export const formatAmountRaw = (amount: number, amount_dec: number): string => {
    return Number(amount).toFixed(amount_dec);
};

export const formatPrice = (price: number, decimals: number): string => {
    return formatNumLocale(Number(price) / 1e8, decimals);
};

export const formatPriceRaw = (price: number, decimals: number): string => {
    return (Number(price) / 1e8).toFixed(decimals);
};

export const formatFundingRate = (fr: any): string => {
    return Number(fr / 1e16).toFixed(6);
};

export const formatFundingRateMultiplier = (fr: number, usr_mult: number): string => {
    return ((fr / 1e16) * usr_mult).toFixed(6);
};

export const formatFee = (fee: any): string => {
    return Number(fee / 1e16).toFixed(3);
};

export const formatMultiplier = (multiplier: any): string => {
    if (isNaN(multiplier) || multiplier === undefined) {
        return '0'; // or any default value you wish to return
    }
    return multiplier.toFixed(2);
};

export const formatLiqPrice = (
    price: number,
    amount: number,
    leverage: number,
    asset_maintenance_margin: number,
    decimals: number,
    side: boolean,
    user_mm_multiplier: number,
    global_mm_multiplier: number,
    balance: number
): string => {
    let collateral = (price * amount) / leverage;
    const maintenance_margin = asset_maintenance_margin * global_mm_multiplier * user_mm_multiplier;
    let coef = maintenance_margin / leverage;
    let movement = collateral / amount;

    let liquidationPrice;
    let netLiqPrice;

    if (leverage == 0) {
        collateral = balance / 1e18;
        movement = collateral / amount;
        if (!side) {
            liquidationPrice = price + movement * (1 - maintenance_margin);
        } else {
            liquidationPrice = max(0, price - movement * (1 - maintenance_margin));
        }
    } else {
        if (!side) {
            netLiqPrice = price + movement;
            liquidationPrice = netLiqPrice - min(netLiqPrice, price * coef);
        } else {
            netLiqPrice = price - movement;
            liquidationPrice = netLiqPrice + price * coef;
        }
    }

    return Number(liquidationPrice).toFixed(decimals);
};

export const formatOrderValue = (price: number, amount: number): string => {
    return (price * amount).toFixed(2);
};