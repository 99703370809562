export const Blink2 = () => {
    return (
        <svg
            width="367"
            height="456"
            viewBox="0 0 367 456"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="blinkImage2"
        >
            <g clipPath="url(#clip0_103_489)">
                <path
                    className="blinking"
                    d="M64.9864 22.5267L60.5264 18.0667L56.0663 22.5267L60.5264 26.9867L64.9864 22.5267Z"
                    fill="#FFAC5A"
                />
                <path
                    className="blinking"
                    d="M121.053 22.5262L116.593 18.0662L112.133 22.5262L116.593 26.9863L121.053 22.5262Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M177.119 22.5267L172.659 18.0667L168.199 22.5267L172.659 26.9867L177.119 22.5267Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M233.186 22.5267L228.726 18.0667L224.266 22.5267L228.726 26.9867L233.186 22.5267Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M289.25 22.5267L284.79 18.0667L280.33 22.5267L284.79 26.9867L289.25 22.5267Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M345.318 22.5262L340.858 18.0662L336.398 22.5262L340.858 26.9863L345.318 22.5262Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M8.92001 78.5926L4.45996 74.1326L-8.86279e-05 78.5926L4.45996 83.0527L8.92001 78.5926Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M64.9864 78.5926L60.5264 74.1326L56.0663 78.5926L60.5264 83.0527L64.9864 78.5926Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M121.053 78.5926L116.593 74.1326L112.133 78.5926L116.593 83.0527L121.053 78.5926Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M177.119 78.5926L172.659 74.1326L168.199 78.5926L172.659 83.0527L177.119 78.5926Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M233.186 78.5926L228.726 74.1326L224.266 78.5926L228.726 83.0527L233.186 78.5926Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M289.25 78.5926L284.79 74.1326L280.33 78.5926L284.79 83.0527L289.25 78.5926Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M345.318 78.5926L340.858 74.1326L336.398 78.5926L340.858 83.0527L345.318 78.5926Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M8.92001 134.659L4.45996 130.199L-8.86279e-05 134.659L4.45996 139.119L8.92001 134.659Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M64.9864 134.659L60.5264 130.199L56.0663 134.659L60.5264 139.119L64.9864 134.659Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M121.053 134.659L116.593 130.199L112.133 134.659L116.593 139.119L121.053 134.659Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M177.119 134.659L172.659 130.199L168.199 134.659L172.659 139.119L177.119 134.659Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M233.186 134.659L228.726 130.199L224.266 134.659L228.726 139.119L233.186 134.659Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M289.25 134.659L284.79 130.199L280.33 134.659L284.79 139.119L289.25 134.659Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M345.318 134.659L340.858 130.199L336.398 134.659L340.858 139.119L345.318 134.659Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M8.92001 190.725L4.45996 186.265L-8.86279e-05 190.725L4.45996 195.185L8.92001 190.725Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M64.9864 190.725L60.5264 186.265L56.0663 190.725L60.5264 195.185L64.9864 190.725Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M121.053 190.725L116.593 186.265L112.133 190.725L116.593 195.185L121.053 190.725Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M177.119 190.725L172.659 186.265L168.199 190.725L172.659 195.185L177.119 190.725Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M233.186 190.725L228.726 186.265L224.266 190.725L228.726 195.185L233.186 190.725Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M289.25 190.725L284.79 186.265L280.33 190.725L284.79 195.185L289.25 190.725Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M345.318 190.725L340.858 186.265L336.398 190.725L340.858 195.185L345.318 190.725Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M8.92001 246.792L4.45996 242.332L-8.86279e-05 246.792L4.45996 251.252L8.92001 246.792Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M64.9864 246.792L60.5264 242.332L56.0663 246.792L60.5264 251.252L64.9864 246.792Z"
                    fill="#FFAC5A"
                />
                <path
                    className="blinking"
                    d="M121.053 246.792L116.593 242.332L112.133 246.792L116.593 251.252L121.053 246.792Z"
                    fill="#FFAC5A"
                />
                <path
                    className="blinking"
                    d="M177.119 246.792L172.659 242.332L168.199 246.792L172.659 251.252L177.119 246.792Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M233.186 246.792L228.726 242.332L224.266 246.792L228.726 251.252L233.186 246.792Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M289.25 246.792L284.79 242.332L280.33 246.792L284.79 251.252L289.25 246.792Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M345.318 246.792L340.858 242.332L336.398 246.792L340.858 251.252L345.318 246.792Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M8.92001 302.858L4.45996 298.398L-8.86279e-05 302.858L4.45996 307.318L8.92001 302.858Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M64.9864 302.858L60.5264 298.398L56.0663 302.858L60.5264 307.318L64.9864 302.858Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M121.053 302.858L116.593 298.398L112.133 302.858L116.593 307.318L121.053 302.858Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M177.119 302.858L172.659 298.398L168.199 302.858L172.659 307.318L177.119 302.858Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M233.186 302.858L228.726 298.398L224.266 302.858L228.726 307.318L233.186 302.858Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M289.25 302.858L284.79 298.398L280.33 302.858L284.79 307.318L289.25 302.858Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M345.318 302.858L340.858 298.398L336.398 302.858L340.858 307.318L345.318 302.858Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M8.92001 358.924L4.45996 354.464L-8.86279e-05 358.924L4.45996 363.384L8.92001 358.924Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M64.9864 358.924L60.5264 354.464L56.0663 358.924L60.5264 363.384L64.9864 358.924Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M121.053 358.924L116.593 354.464L112.133 358.924L116.593 363.384L121.053 358.924Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M177.119 358.924L172.659 354.464L168.199 358.924L172.659 363.384L177.119 358.924Z"
                    fill="#FFAC5A"
                />
                <path
                    className="blinking"
                    d="M233.186 358.924L228.726 354.464L224.266 358.924L228.726 363.384L233.186 358.924Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M289.25 358.924L284.79 354.464L280.33 358.924L284.79 363.384L289.25 358.924Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M345.318 358.924L340.858 354.464L336.398 358.924L340.858 363.384L345.318 358.924Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M8.92001 414.991L4.45996 410.531L-8.86279e-05 414.991L4.45996 419.451L8.92001 414.991Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M64.9864 414.991L60.5264 410.531L56.0663 414.991L60.5264 419.451L64.9864 414.991Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M121.053 414.991L116.593 410.531L112.133 414.991L116.593 419.451L121.053 414.991Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M177.119 414.991L172.659 410.531L168.199 414.991L172.659 419.451L177.119 414.991Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
                <path
                    className="blinking"
                    d="M233.186 414.991L228.726 410.531L224.266 414.991L228.726 419.451L233.186 414.991Z"
                    fill="#FFAC5A"
                />
                <path
                    className="blinking"
                    d="M289.25 414.991L284.79 410.531L280.33 414.991L284.79 419.451L289.25 414.991Z"
                    stroke="#FFAC5A"
                    strokeOpacity="0.27"
                    strokeWidth="0.630746"
                />
            </g>
            <defs>
                <clipPath id="clip0_103_489">
                    <rect width="367" height="456" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
