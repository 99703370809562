import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Position } from '../interfaces/position.interface';
import {
    formatNumLocale,
    formatAmount,
    calcRPnL,
    calcUPnL,
    calcLeverage,
    formatPrice,
    formatFundingRate,
} from '../utils/price';
import { MarginCurrency } from '../interfaces/marginCurrency.interface';
import { ReactComponent as IconLogo } from '../assets/icons/svg/logo.svg';
import { Button } from '../components/Button/Button';
import { Tooltip } from '../components/Tooltip';
import { AssetIcon } from '../components/AssetIcon/AssetIcon';
import { Balance } from '../interfaces/balance.interface';
import useWindowSize from '../hooks/useWindowSize';
import { eventCloud } from '../EventCloud';

export const PositionActions = ({
    positions,
    balances,
    selectedPositionActionHash,
    marginCurrencies,
    handleTakeProfitStopLoss,
    handleMarketClose,
    handleUpdateMargin,
    onClose,
    userFRMultiplier,
}: {
    positions: Position[];
    balances: Record<string, Balance>;
    selectedPositionActionHash: string;
    marginCurrencies: Record<string, MarginCurrency>;
    handleTakeProfitStopLoss: (position: Position, isTakeProfit: boolean) => void;
    handleMarketClose: (position: Position) => void;
    handleUpdateMargin: (position: Position) => void;
    onClose: () => void;
    userFRMultiplier: number;
}) => {
    const [position, setPosition] = useState<undefined | Position>();
    const isThrottled = useRef(false);

    const [windowWidth] = useWindowSize();

    useEffect(() => {
        const updatePositions = () => {
            if (!isThrottled.current) {
                isThrottled.current = true;

                const p = positions.find(p => p.hash === selectedPositionActionHash);

                if (p) {
                    const upnl = calcUPnL(p, marginCurrencies);
                    const rpnl = calcRPnL(p);

                    p.unrealized_pnl = upnl;
                    p.realized_pnl = rpnl;

                    setPosition(p);
                }
            }
        };

        setTimeout(() => {
            isThrottled.current = false;
        }, 1000);

        updatePositions();
    }, [positions, selectedPositionActionHash]);

    if (!position) {
        return null;
    }
    const network_slug = eventCloud.findMarginCurrency(position.base_token)?.network_slug;

    return (
        <div>
            {position && (
                <div className="flex flex-col items-center justify-center text-xs text-gray-550">
                    <div className="w-full">
                        <div className="flex justify-between w-full pt-1">
                            <div className="flex items-center">
                                <div className="w-6">
                                    <AssetIcon symbol={position?.asset} />
                                </div>
                                <div className="ml-2">
                                    <div className="flex items-center text-base text-gray-150 font-medium leading-tight">
                                        {position?.asset.toUpperCase().replace(/USD/g, '')}

                                        <div
                                            className={classNames(
                                                'text-xxs px-1 bg-opacity-20 ml-1',
                                                [
                                                    position.side
                                                        ? 'text-green-550 bg-green-550'
                                                        : 'text-red-550 bg-red-550',
                                                ]
                                            )}
                                        >
                                            {position.side ? 'LONG' : 'SHORT'}
                                        </div>
                                    </div>

                                    <div className="text-xxs text-gray-450 leading-tight">
                                        {calcLeverage(
                                            position,
                                            marginCurrencies,
                                            !position.is_cross
                                                ? 0
                                                : Number(
                                                      balances[position.base_token]
                                                          ?.crossmargin_profit
                                                  ) / 1e10
                                        )}
                                        x
                                    </div>
                                </div>
                            </div>

                            <div className="text-right text-gray-450 flex">
                                <div>
                                    <Tooltip label="Unrealized PNL" text="Unrealized Profit/Loss" />
                                    <div
                                        className={classNames([
                                            Number(position.unrealized_pnl) >= 0
                                                ? 'text-green-550'
                                                : 'text-red-550',
                                        ])}
                                    >
                                        <div>
                                            {formatNumLocale(
                                                Number(
                                                    formatAmount(
                                                        position.unrealized_pnl,
                                                        position.margin_dec
                                                    )
                                                ),
                                                position.margin_dec
                                            )}{' '}
                                            {position.margin_symbol} [{network_slug}]
                                        </div>
                                    </div>
                                </div>

                                <div className="ml-4">
                                    <Tooltip
                                        label="Realized PNL"
                                        position="right"
                                        text="Realized Profit/Loss (Includes Funding Cost)"
                                    />
                                    <div
                                        className={classNames([
                                            Number(position.realized_pnl) >= 0
                                                ? 'text-green-550'
                                                : 'text-red-550',
                                        ])}
                                    >
                                        <div>
                                            {formatNumLocale(
                                                Number(
                                                    formatAmount(
                                                        position.realized_pnl,
                                                        position.margin_dec
                                                    )
                                                ),
                                                position.margin_dec
                                            )}{' '}
                                            {position.margin_symbol} [{network_slug}]
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-4">
                            <div className="flex justify-between text-gray-450">
                                <div>
                                    <Tooltip
                                        label="Size"
                                        text="Size of the position in asset terms"
                                    />

                                    <div
                                        className={classNames('text-xs', [
                                            position.side ? 'text-green-550' : 'text-red-550',
                                        ])}
                                    >
                                        <div>
                                            {formatNumLocale(
                                                Number(position.size) / 1e8,
                                                position.amount_dec
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="flex items-center text-right">
                                    <div>
                                        <Tooltip label="Entry Price" text="Average entry price" />
                                        <div className="text-yellow-550">
                                            {formatPrice(
                                                Number(position.entry_price),
                                                position.decimals
                                            )}{' '}
                                            USD
                                        </div>
                                    </div>
                                    <div className="ml-10">
                                        <Tooltip
                                            label="Mark Price"
                                            text="Last Price-Source traded price for asset"
                                        />
                                        <div>
                                            {formatPrice(
                                                Number(position.mark_price) * 1e8,
                                                position.decimals
                                            )}{' '}
                                            USD
                                        </div>
                                    </div>
                                    <div className="ml-10">
                                        <Tooltip
                                            label="Margin Call"
                                            text="At this price your position will be automatically market closed"
                                        />
                                        <div className="text-blue-550">
                                            {formatPrice(
                                                Number(position.liquidation_price),
                                                position.decimals
                                            )}{' '}
                                            USD
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-5 py-5 border-t border-gray-650 w-full">
                        <div className="flex justify-between">
                            <div className="text-gray-450">Hash</div>
                            <div className="text-gray-150">{position.hash.slice(0, 10)}</div>
                        </div>
                        <div className="flex justify-between">
                            <div className="text-gray-450">
                                <Tooltip label="Value" text="Size x Price" />
                            </div>
                            <div className="text-gray-150">
                                {formatPrice(Number(position.value), 2)} USD
                            </div>
                        </div>
                        <div className="flex justify-between">
                            <div className="text-gray-450">
                                <Tooltip
                                    label="Last Price"
                                    text="Last DMEX traded price for asset"
                                />
                            </div>
                            <div className="text-gray-150">
                                {formatPrice(Number(position.last_price), position.decimals)} USD
                            </div>
                        </div>
                        <div className="flex justify-between">
                            <div className="text-gray-450">
                                <Tooltip
                                    label="Margin"
                                    text="Position Margin - Funding Cost + Unrealized Profit/Loss"
                                />
                            </div>
                            <div className="text-gray-150">
                                <div>
                                    {formatNumLocale(
                                        !position.is_cross
                                            ? (Number(position.margin) +
                                                  Number(position?.unrealized_pnl)) /
                                                  1e8
                                            : Number(position.margin) / 1e8,
                                        position.margin_dec
                                    )}{' '}
                                    {position.margin_symbol}
                                    {position.is_cross && (
                                        <span className="text-yellow-550"> [CROSS] </span>
                                    )}{' '}
                                    <Button
                                        type="button"
                                        variant="gray"
                                        size="tiny"
                                        onClick={() => {
                                            handleUpdateMargin(position);
                                            onClose();
                                        }}
                                    >
                                        +
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-between">
                            <div className="text-gray-450">
                                <Tooltip
                                    label="FUNDING %"
                                    text="Current Funding Rate for Asset / 1H"
                                />
                            </div>
                            <div className="text-gray-150">
                                {formatFundingRate(Number(position.funding_rate), userFRMultiplier)}
                                %
                            </div>
                        </div>
                    </div>

                    <div className="border-t border-gray-650 w-full">
                        <div className="mt-5">
                            <a href="/">
                                <IconLogo className="w-16" />
                            </a>
                        </div>

                        <div className="flex mt-5 w-full">
                            <Button
                                className="flex-1"
                                type="button"
                                variant="gray"
                                size={windowWidth > 768 ? 'large' : 'medium'}
                                onClick={() => {
                                    handleTakeProfitStopLoss(position, true);
                                    onClose();
                                }}
                            >
                                TAKE PROFIT
                            </Button>
                            <Button
                                className="flex-1 ml-3"
                                type="button"
                                variant="gray"
                                size={windowWidth > 768 ? 'large' : 'medium'}
                                onClick={() => {
                                    handleTakeProfitStopLoss(position, false);
                                    onClose();
                                }}
                            >
                                STOP LOSS
                            </Button>
                            <Button
                                className="flex-1 ml-3"
                                type="button"
                                variant="gray"
                                size={windowWidth > 768 ? 'large' : 'medium'}
                                onClick={() => {
                                    handleMarketClose(position);
                                    onClose();
                                }}
                            >
                                MARKET CLOSE
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
