import { forwardRef, PropsWithoutRef } from 'react';
import { FieldProps } from '../types/field';
import { getFieldIds } from '../utils/field';

export type FieldLabelProps = Omit<PropsWithoutRef<JSX.IntrinsicElements['label']>, 'className'> &
    Partial<Pick<FieldProps, 'optional' | 'required'>> &
    Required<Pick<PropsWithoutRef<JSX.IntrinsicElements['label']>, 'htmlFor'>>;

export const FieldLabel = forwardRef<HTMLLabelElement, FieldLabelProps>(
    ({ children, optional, required, ...props }, ref) => {
        const { optionalId } = getFieldIds({ name: props.htmlFor });

        return (
            <div className="flex justify-between w-full">
                <label
                    ref={ref}
                    {...props}
                    className="block mb-1 text-xs font-medium text-gray-450 w-full"
                >
                    {children}{' '}
                    {required && <sup className="text-xs text-red-400 -left-1 -top-1">*</sup>}
                </label>
                {optional && (
                    <span className="text-sm text-gray-500 " id={optionalId}>
                        Optional
                    </span>
                )}
            </div>
        );
    }
);
